import React, { Component } from 'react';
import Aside from './../aside';
import axios from 'axios';
import * as constants from './../../../constants';
import { throws } from 'assert';
import organisation from '../organisations/organisation';

class planreview extends Component {

    state = {
        years : [],
        quarters : [
            "FQ1","FQ2","FQ3","FQ4"
        ],
        freezeDate : "",
        reviews : [],
        input : {
            year : '',
            quarter : '',
            freeze : ''
        },
        editDate:false,
        newDate:'',
        showBack:false,
    }

    handleInput = (e, field) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.input[field] = e.target.value; 
        this.setState(tmpState);
    }


    getYears = () => {
        let years = [];
        Array(3).join().split(',').map((e,i) => {
            let value = new Date().getFullYear();
            let end = value+i+1;
            let start = value+i;
            let year = `${start}-${end}`;
            years.push(year);
        });
        this.setState({
            ...this.state,
            years
        })
    }

    addReview = () => {
        if(this.state.input.year!=='' && this.state.input.quarter!=='' && this.state.input.freeze!=='' ){
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.put(constants.endpoint+constants.review, {
            year : this.state.input.year,
            quarter : this.state.input.quarter,
            freeze : this.state.input.freeze,
            organisation : organisation
        }).then((response) => {
            if(response.status === 201){
                this.showError("success", "Review created successfully !");
                this.getReviews();
            }
        }).catch((error) => {
            if(error && error.response.status === 409){
                this.showError("danger", "Either same year/quarter review exists or all existing reviews are not completed.");
            }else if(error.response.status === 400){
                this.showError("warning", "Please provide proper inputs !");
            }else if(error.response.status === 404){
                this.showError("warning", "Active review already exists !");
            }else{
                this.showError("danger", "Something unexpected happened !");
            }
        });
       }
       else{
            this.showError("danger", "Please enter all fields !");
       }
    }

    updateReview = (e, reviewId) => {
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.post(constants.endpoint+constants.review, {
            organisation : organisation,
            reviewId : reviewId,
        
        }).then((response) => {
            if(response.status === 202){
                this.showError("success", "Review marked complete successfully !");
                this.getReviews();
            }
        }).catch((error) => {
             if(error.response.status === 400){
                this.showError("warning", "Please provide proper inputs !");
            }else{
                this.showError("danger", "Something unexpected happened !");
            }
        });
    }

    getReviews = () => {
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.get(constants.endpoint+constants.review + "?organisation="+organisation)
        .then((response) => {
            this.setState({
                ...this.state,
                reviews : response.data.reviews
            })
        })
        .catch((error) => {
            this.showError("danger","Unable to connect with backend !");
        });
    }


    editFreezeDate=()=>{
        this.setState({editDate:true, showBack:true})
    }

    hideEditFreeze=()=>{
        this.setState({editDate:false, showBack:false})
    }

    changeDate=(e,rev)=>{
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.put(constants.endpoint+constants.editReviewDate, {
            organisation : organisation,
            reviewId : rev.reviewId,
            freezeDate : e.target.value
        
        }).then((response) => {
            if(response.status === 202){
                this.setState({editDate:false,showBack:false})
                this.showError("success", "Freeze date updated successfully !");
                this.getReviews();
            }
        }).catch((error) => {
             this.setState({editDate:false,showBack:false})
             if(error.response.status === 400){
                this.showError("warning", "Please provide proper inputs !");
            }else{
                this.showError("danger", "Something unexpected happened !");
            }
        });
    }

    componentDidMount(){
        this.getYears();
        this.getReviews();
    }

    showError = (type, message) => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML  = (`
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `);
    };

    render() {
        return (
            <React.Fragment>
            <div className="content content-fixed">
                <div className="container col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-10 mg-lg-b-10 mg-xl-b-10">
                    <div>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item">Dashboard</li>
                            <li className="breadcrumb-item active" aria-current="page">Plan Review</li>
                        </ol>
                        </nav>
                        <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Review Management</h4>
                    </div>
                    {/* <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="file" className="wd-10 mg-r-5"></i> Previous Reviews</button>
                    </div> */}
                    </div>

                    {/* Error Handler */}
                    <div className="row">
                        <div id="errorPlaceholder" className="col-12">
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-xl-12 mg-t-10">
                            <div className="card card-body shadow-none">
                                <form>
                                    <div className="marker marker-ribbon pos-absolute t-10 l-0">Plan Growth Review</div>
                                    <div className="row" style={{marginTop:'32px'}}>
                                        <div className="col-3">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Select Year</h6>
                                            <select value={this.state.input.year} onChange={(e) => this.handleInput(e,"year")} className="custom-select" required>
                                                <option>Select Year</option>
                                                {
                                                    this.state.years.map((year, index) => (
                                                        <option key={index} value={year}>{year}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Select Quarter</h6>
                                            <select value={this.state.input.quarter} onChange={(e) => this.handleInput(e,"quarter")} className="custom-select" required>
                                                <option>Select Quarter</option>
                                                {
                                                    this.state.quarters.map((quar,index) => (
                                                        <option value={quar} key={index}>{quar}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Freeze date</h6>
                                            <input value={this.state.input.freeze} onChange={(e) => this.handleInput(e,"freeze")} type="date" className="form-control" placeholder="Select End Date" required></input>
                                        </div>
                                        <div className="col-3" style={{margin:"Auto 0px", position :"relative", top:"10px"}}>
                                            <button onClick={this.addReview} type="button" className="btn btn-sm btn-primary">Plan Review</button>
                                         </div>
                                    </div>
                                    </form>
                             </div>
                             
                        </div>
                    </div>

                    <div className="row" style={{marginTop:"20px"}}>
                            {
                                this.state.reviews.map((rev,index) => (
                                    <div key={index} className="col-3" style={{marginTop:'10px'}}>
                                        <div className="card card-body" style={{minHeight:'178px'}}>
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Growth Review</h6>
                                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                                    <div className="row">
                                                    <div className="col-12">
                                                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{rev.year} | {rev.quarter}</h3>
                                                    </div>
                                                    <div className="col-12" style={{marginTop:'10px'}}>
                                                        <h6 style={{display:'block'}} className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">Freezes : {this.state.editDate===true && rev.status==="Active"?<input value={this.state.newDate} onChange={(e) => this.changeDate(e,rev)} type="date" className="form-control"/>:<code>{rev.freeze}</code>}</h6>
                                                    </div>
                                                   
                                                    <div className="col-12" style={{marginTop:'10px'}}>
                                                        <h6 style={{display:'block'}} className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">Status : <code>{rev.status}</code></h6>
                                                        <button onClick={(e) => this.updateReview(e,rev.reviewId)} style={{marginTop:'10px', display: rev.status === "Active" ? "" : "none"}} type="button" className="btn btn-xs btn-primary">Mark Complete</button>&nbsp;
                                                        <button onClick={()=>this.editFreezeDate()} style={{marginTop:'10px', display: rev.status === "Active" ? "" : "none"}} className="btn btn-xs btn-primary">Edit</button>&nbsp;
                                                        {this.state.showBack===true &&(<button onClick={()=>this.hideEditFreeze()} style={{marginTop:'10px', display: rev.status === "Active" ? "" : "none"}} className="btn btn-xs btn-primary">Back</button>)}
                                                    </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                ))
                            }
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default planreview;