import React, { Component } from 'react';

class footer extends Component {
    render() {
        return (
            <footer className="footer" style={{position:'relative', bottom:'0%', width : '100%'}}>
            <div>
              <span></span>
            </div>
            <div>
              <nav className="nav">
                
              </nav>
            </div>
          </footer>
        );
    }
}

export default footer;