import React, { useState, Component } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import axios from 'axios';
import * as constants from '../../constants';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';


class dashboardTeamRatingChart extends Component {
    
    constructor(props) {
        super(props);
      }
    
    state = {
        backgroundColor: ['#2196F3', '#AB47BC', '#EC407A', '#EF5350', '#0288D1', '#43A047', '#F57C00', '#E64A19', '#8D6E63', '#607D8B'],
        hoverBackgroundColor: ['#0D47A1', '#7B1FA2', '#C2185B', '#E53935', '#01579B', '#1B5E20', '#E65100', '#BF360C', '#6D4C41', '#37474F'],
        finalRating: 0,
        finalComment: '',
        reviewId: '',
        teamRatingChartData: {
            labels: [],
            datasets: [
                {
                    label: '',
                    backgroundColor: [
                        '#B21F00',
                        '#C9DE00',
                    ],
                    hoverBackgroundColor: [
                        '#501800',
                        '#4B5000',
                    ],
                    data: []
                }
            ]
        },
    }


    componentWillMount() {
        this.getTeamReatingDistributionOtherPage("","");
    }

    getTeamReatingDistributionOtherPage = (year, quarter) => {
       
        let url = constants.teamRatingDistribution;
        if (year != "" && quarter != "") {
            url = url + "?year=" + year + "&quarter=" + quarter
        }
        axios.get(constants.endpoint + url)
            .then((response) => {

              
                const maxRating = 5;
                let labelsList = [];
                let dataList = [];
                let bgColor = [];
                let howerColor = [];
                if (response.data.teamRatingDistribution.ratings.length>0) {
                    let totalCount = response.data.teamRatingDistribution.totalcount;
                    let rating = response.data.teamRatingDistribution.ratings;
                    for (var i = 0; i < rating.length; i++) {
                        labelsList[i] = rating[i]._id;
                        dataList[i] = ((rating[i].count * 100) / totalCount).toFixed(2);
                        bgColor[i] = this.state.backgroundColor[i];
                        howerColor[i] = this.state.hoverBackgroundColor[i];
                    }
                    this.setState({
                        ...this.state, 
                        teamRatingChartData: {
                            labels: labelsList,
                            datasets: [{
                                label: 'rating distribution',
                                data: dataList,
                                backgroundColor: bgColor,
                                hoverBackgroundColor: howerColor,
                            }]
                        }
                    })
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (

            <React.Fragment>

                <div id="divDonut" className="col-6" style={{ height: '230px' }}>
                    <Doughnut
                        data={this.state.teamRatingChartData}
                        options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Average Raiting distribution percentage',
                                    fontSize: 30,
                                    position: 'top'
                                },
                                legend: {
                                    display: true,
                                    position: 'right',
                                },
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            cutout: 30,
                        }}
                    />



                </div>



            </React.Fragment>

        );
    }
}

export default dashboardTeamRatingChart;