import React, { Component, useRef } from 'react';
import Header from './../header/header';
import Footer from './../footer/footer';
import axios from 'axios';
import * as constants from './../../constants';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';
import DashboardTeamRatingChart from './../managerdashFirstChart/dashboardTeamRatingChart'


class teamRatingByAreaChart extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        user: {
            role: '',
            manager: {
                name: ''
            }
        },
        backgroundColor: ['#2196F3', '#AB47BC', '#EC407A', '#EF5350', '#0288D1', '#43A047', '#F57C00', '#E64A19', '#8D6E63', '#607D8B'],
        hoverBackgroundColor: ['#0D47A1', '#7B1FA2', '#C2185B', '#E53935', '#01579B', '#1B5E20', '#E65100', '#BF360C', '#6D4C41', '#37474F'],
        roles: [],
        levels: [],

        current: {
            active: 0
        },
        areaList: [],
        ratingByAreaArray: [],
        teamRatingByArea: {
            labels: [],
            datasets: [
                {
                    label: '',
                    backgroundColor: [
                        '#B21F00',
                        '#C9DE00',
                    ],
                    hoverBackgroundColor: [
                        '#501800',
                        '#4B5000',
                    ],
                    data: []
                }
            ]
        },
        lastClicableButtonId: '',
        temaRatingNotFound: "false",
        teamRatingByAreaNotFound: false,
        teamRatingByRoleNotFound: false,

    }


    componentWillMount() {
        if (typeof localStorage.token === "undefined") {
            window.location.href = "/?message=Session+Expired";
        }

        if (localStorage.activityRole === "User") {
            window.location.href = "/?message=Unauthorized+Access+Or+Session+Expired";
        }
        this.getTeamReatingDistributionByArea("", "");
    }

    getCompleteRatings = () => {


        let year = this.state.selectedYear;
        let quarter = this.state.selectedQuarter;

        this.getTeamReatingDistributionByArea(year, quarter);
    }



    areaRating = (area, byClick) => {
        
        console.log(area);
        let id = "btnId" + area.index;
        document.getElementById(id).style.backgroundColor = "#0168fa";
        if (byClick != "true") {
            if (this.state.lastClicableButtonId != "")
                document.getElementById(this.state.lastClicableButtonId).style.backgroundColor = "#7987a1";
            else
                document.getElementById("btnId" + area.index).style.backgroundColor = "#0168fa";
        }
        let rating = this.state.ratingByAreaArray[area.index];
        let labelsList = [];
        let dataList = [];
        let bgColor = [];
        let howerColor = [];
        for (var j = 0; j < rating.length; j++) {
            labelsList[j] = rating[j].name;
            dataList[j] = rating[j].value;
            bgColor[j] = this.state.backgroundColor[j];
            howerColor[j] = this.state.hoverBackgroundColor[j];
        }
        this.setState({
            ...this.state,
            lastClicableButtonId: id,
            teamRatingByArea: {
                labels: labelsList,
                datasets: [{
                    label: 'Rating',
                    data: dataList,
                    backgroundColor: bgColor,
                    hoverBackgroundColor: howerColor,
                }]
            }
        })
    }

    getTeamReatingDistributionByArea = (year, quarter, byClick) => {
        let url = constants.teamRatingDistributionByArea;
        if (year != "" && quarter != "") {
            url = url + "?year=" + year + "&quarter=" + quarter
        }
        axios.get(constants.endpoint + url)
            .then((response) => {


                let areaLst = [];
                let ratingList = [];
                if (response.data != null || response.data != '') {
                    for (var i = 0; i < response.data.length; i++) {
                        areaLst[i] = response.data[i].area;
                        ratingList[i] = response.data[i].ratings;
                    }
                    this.setState({
                        ...this.state,
                        areaList: areaLst,
                        ratingByAreaArray: ratingList
                    })
                    let area = { index: 0 };
                    this.areaRating(area, byClick);
                }
                else {
                    this.setState({
                        ...this.state,
                        teamRatingByAreaNotFound: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (

            <React.Fragment>



                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0" style={{ marginTop: '-56px' }}>

                    <div className="row" >
                        <div className="col-sm-6 col-lg-12">
                            <div className="card card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>Team Rating Distribution By Area</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            this.state.areaList.map((area, index) => (
                                                <button id={"btnId" + index} onClick={() => this.areaRating({ index })} type="button" class="btn btn-secondary btn-xs" style={{ margin: '3px' }}>{area}</button>
                                            ))
                                        }


                                    </div>
                                </div>

                                <div className="row">


                                    <div id="divTeamRating" className="col-12">

                                        <Bar
                                            data={this.state.teamRatingByArea}

                                            options={{
                                                plugins: {


                                                },
                                                indexAxis: 'y',

                                                elements: {
                                                    bar: {
                                                        borderWidth: 2,
                                                    },
                                                },
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'right',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Rating distribution by Area',
                                                    },
                                                },
                                                barThickness: 30,
                                                maxBarThickness: 40,
                                            }}
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>


                    <div>

                    </div>
                </div>








            </React.Fragment>

        );
    }
}




export default teamRatingByAreaChart;

