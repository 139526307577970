import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Login from  './componenets/login/login';
import Redirect from './componenets/login/redirect'
import Forgot from './componenets/forgot/forgot';
import Selfreview from './componenets/selfreview/selfreview';
import ManagerReview from './componenets/managerReview/managerReview';
import Forbidden from './componenets/forbidden/forbidden';
import axios from 'axios';
import Aside from './componenets/admin/aside';
import Promotions from './componenets/admin/promotions/promotions';
import PreviousReviews from './componenets/previousReviews/previousReviews';
import AllEmployeeReview from './componenets/allEmployeeReview/AllEmployeeReview'
import PromotionList from './componenets/PromotionList/promotionList'
import PrivacyPolicy from './componenets/privacyPolicy/privacypolicy'
import TermsAndConditions from './componenets/termsAndConditions/termsAndConditions'
import ManagerDashboard from './componenets/managerDashboard/managerDashboard'
import Notification from './componenets/notification/viewNotifications'
axios.defaults.headers.common['authorization'] = localStorage.token;


const routing = (
        <BrowserRouter>
            <Switch>
                <Route exact path="/temp" component={Promotions} />
                <Route exact path="/change" component={Forgot} />
                <Route exact path="/forbidden" component={Forbidden} />
                <Route exact path="/selfreview" component={Selfreview} />
                <Route exact path="/previousReviews" component={PreviousReviews} />
                <Route exact path="/admin" component={Aside} />
                <Route exact path="/manager/review" component={ManagerReview} />
                <Route exact path="/manager/promotions" component={PromotionList} />
                <Route exact path="/employee/review" component={AllEmployeeReview} />
                <Route exact path="/redirect" component={Redirect} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms-conditions" component={TermsAndConditions} />
                <Route exact path="/manager/dashboard" component={ManagerDashboard} />
                <Route exact path="/notifications" component={Notification} />
                <Route exact path="/*" component={Login} />
            </Switch>
        </BrowserRouter>
);


ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.unregister();
