import React, { Component } from "react";
import Aside from "./../aside";
import axios from "axios";
import * as constants from "./../../../constants";

import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import { handleAlert } from "./../../alertHandler/alertHandler";

class addusers extends Component {
  state = {
    data: {
      organisations: [],
      roles: [],
      activeProjects: [],
      activeLevels: [],
      users: [],
      allUsers: [],
    },
    userInfo: {
      activeOrganisation: "",
      activeRole: "",
      activeProject: {},
      activeLevel: "",
      cvtid: "",
      name: "",
      doj: "",
      email: "",
      activityRole:"", 

    },
    updateProject :[],
    showEmployees: true,
    isEditEmployees: false,
    isMigrate:false,
    selectCvtId: "",
    selectedUser: {},
    selectedProject:{},
  };
  

  addEmployee = () => {
    
    var managerId=[];
    var activeProject= this.state.data.activeProjects;
    for(var i=0;i<activeProject.length;i++){
      for(var j=0;j<this.state.userInfo.activeProject.length;j++){
        if(activeProject[i].name === this.state.userInfo.activeProject[j] && !managerId.includes({"name":activeProject[i].manager[0].name,"cvtid":activeProject[i].manager[0].cvtid})){
          managerId.push({"name":activeProject[i].manager[0].name,"cvtid":activeProject[i].manager[0].cvtid});
        }
      }
      
    }

    var man=[]
    man.push(managerId[0]);
    for(var i=1;i<managerId.length;i++){
      var count=0;
      for(var j=0;j<man.length;j++){
        if(managerId[i].name === man[j].name){
          count++;
        }
      }
      if(count===0){
      man.push(managerId[i])}
    }

    

    var notUserMan=[]
    if(this.state.userInfo.activityRole==="Owner" || this.state.userInfo.activityRole==="Admin"){
      let manDetails={cvtid:"",name:""};
      notUserMan.push(manDetails);
    }
   
    axios
      .post(constants.endpoint + constants.user, {
        cvtid: this.state.userInfo.cvtid,
        name: this.state.userInfo.name,
        email: this.state.userInfo.email,
        organisation: this.state.userInfo.activeOrganisation,
        project: (this.state.userInfo.activityRole === "Owner" || this.state.userInfo.activityRole === "Admin")?[] : this.state.userInfo.activeProject,
        role: (this.state.userInfo.activityRole === "Owner" || this.state.userInfo.activityRole === "Admin")?"NA" :this.state.userInfo.activeRole,
        level: (this.state.userInfo.activityRole === "Owner" || this.state.userInfo.activityRole === "Admin")?"NA" :this.state.userInfo.activeLevel,
        doj: this.state.userInfo.doj,
        activityRole: this.state.userInfo.activityRole,
        manager: (this.state.userInfo.activityRole === "Owner" || this.state.userInfo.activityRole === "Admin")?[] :man ,
      })
      .then((response) => {
        if (response.status == 201) {
          this.setState(
            {
              ...this.state,
              userInfo: {
                cvtid: "",
                name: "",
                email: "",
                doj: "",
                activeProject: "",
                activeOrganisation: "",
                activeRole: "",
                activeLevel: "",
                activityRole: "",
              },
            },
            () => {
              handleAlert(response, {
                201: "Employee added successfully !",
              });
            }
          );
        }
      })
      .catch((error) => {
        
        handleAlert(error.response, {
          409: "User with same Employee Id / Email address already exists.",
          400: error.data,
          500: "Something went wrong, Please try again.",
        });
      });
  };

  updateEmployee = ()=>{
    var managerId=[];
    var activeProject= this.state.updateProject;
    for(var i=0;i<activeProject.length;i++){
      for(var j=0;j<this.state.selectedProject.length;j++){
        if(activeProject[i].name === this.state.selectedProject[j]){
          managerId.push({"name":activeProject[i].manager[0].name,"cvtid":activeProject[i].manager[0].cvtid});
        }
      }
      
    }
    var man=[]
    man.push(managerId[0]);
    for(var i=1;i<managerId.length;i++){
      var count=0;
      for(var j=0;j<man.length;j++){
        if(managerId[i].name === man[j].name){
          count++;
        }
      }
      if(count===0){
      man.push(managerId[i])}
    }

    axios
      .post(constants.endpoint + constants.updateUser, {
        oldCvtId: this.state.selectedUser.cvtid,
        cvtid: this.state.userInfo.cvtid.length===0 ? this.state.selectedUser.cvtid : this.state.userInfo.cvtid,
        name: this.state.userInfo.name.length===0 ?this.state.selectedUser.name : this.state.userInfo.name,
        email: this.state.userInfo.email.length===0 ? this.state.selectedUser.email : this.state.userInfo.email,
        project: this.state.selectedProject.length===0 ? this.state.selectedUser.project : this.state.selectedProject,
        manager: this.state.selectedProject.length===0 ? ""  : man
      }).then((response) => {
        if (response.status == 201) {
          this.setState(
            {
              ...this.state,
              userInfo: {
                cvtid: "",
                name: "",
                email: "",
                doj: "",
                activeProject: "",
                activeOrganisation: "",
              },
              selectedProject:"",
              selectCvtId : "",
            },
            () => {
              handleAlert(response, {
                201: "Employee updated successfully !",
              });
            }
          );
        }
      })
      .catch((error) => {
        handleAlert(error.response, {
          409: "User with same Employee Id / Email address already exists.",
          400: "No field should be empty.",
          500: "Something went wrong, Please try again.",
        });
      });
  }
  
  handleInput = (e, field) => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState.userInfo[field] = e.target.value;

    if (field == "activeOrganisation") {
      this.state.data.organisations.map((org) => {
        if (org.name == e.target.value) {
          tmpState.data.activeProjects = org.projects;
        }
      });
    } else if (field == "activeRole") {
      this.state.data.roles.map((role) => {
        if (role.name == e.target.value) {
          tmpState.data.activeLevels = role.levels;
        }
      });
    } else if (field == "activeProject") {
      let value = Array.from(e.target.selectedOptions, option => option.value);
      tmpState.userInfo[field] = value;
      this.getRoles();
    } 

    this.setState(tmpState);
  };

  getProjects =()=>{
    let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
    axios
    .get(constants.endpoint + constants.projects + "?name=" +organisation)
    .then((response) => {
      this.setState({
        ...this.state,
          updateProject: response.data.projects,      
      });
    })
  }

  updateProjects = (e) =>{
    this.setState({
      ...this.state,
      selectedProject : Array.from(e.target.selectedOptions, option => option.value)
    })
  }

  onEmployeeSelect = (e) => {
    this.setState(
      {
        ...this.state,
        selectCvtId: e.target.value,
      },
      () => {
        this.state.data.users.map((user) => {
          if (user.cvtid === this.state.selectCvtId) {
            this.setState({
              ...this.state,
              selectedUser: user,
            });
          }
        });
      }

    );
  };


  getRoles = () => {
    let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
    let final = !this.state.isMigrate? organisation : this.state.userInfo.activeOrganisation
    axios
      .get(
        constants.endpoint +
          constants.role +
          "?organisation=" +
          final
      )
      .then((response) => {
        if (response.data.roles.length != 0) {
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              roles: response.data.roles,
            },
          });
        }
      })
      .catch((error) => {
        handleAlert(error.response, {
          409: "User with same email address already exists.",
          400: "Please provide proper inputs.",
          500: "Something went wrong, Please try again.",
        });
      });
  };

  resetPassword = (cvtid) => {
    let passwordDialog = window.prompt(
      "Enter New password or keep it blank for default password",
      ""
    );
    if (passwordDialog != null) {
      if (
        passwordDialog.trim().length >= 6 ||
        passwordDialog.trim().length == 0
      ) {
        axios
          .post(constants.endpoint + constants.resetPass, {
            cvtid: cvtid,
            password: passwordDialog.trim(),
          })
          .then((response) => {
            handleAlert(response, {
              202: "Password resetted successfully.",
            });
          })
          .catch((error) => {
            handleAlert(error.response, {});
          });
      } else {
        alert(
          "Password length should be atleast 6 characters or keep field blank for default password"
        );
      }
    }
  };

  getOrganisations = () => {
    axios
      .get(constants.endpoint + constants.organisation)
      .then((response) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            organisations: response.data.organisations,
          },
        });
      })
      .catch((error) => {
        handleAlert(error.response, {});
      });
  };

  getUsers = () => {
    axios
      .get(
        constants.endpoint +
          constants.user +
          "?organisation=" +
          localStorage.selectedOrganisation
      )
      .then((response) => {
        var usrs = [];
        for( var i =0;i< response.data.length;i++){
          if(response.data[i].cvtid !== localStorage.cvtid && response.data[i].activityRole !== "Owner"){
            usrs.push(response.data[i]);
          }
        }
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            users: usrs,
            allUsers : response.data,
          },
        });
      })
      .catch((error) => {
        handleAlert(error.response, {});
      });
  };

  componentDidMount() {
    this.getOrganisations();
    this.getUsers();
    this.getProjects();
  }

  showEmployeeBar = () => {
    this.setState({
      ...this.state,
      showEmployees: false,
    });
  };
  showEditBar = () => {
    this.setState({
      ...this.state,
      isEditEmployees: true,
      isMigrate:false,
      showEmployees: false,
      selectCvtId: "",
    });
  };

  showMigrateBar=()=>{
    this.setState({
      ...this.state,
      isMigrate:true,
      isEditEmployees: false,
      showEmployees: false,
      selectCvtId: "",
    });
  }

  hideEmployeeBar = () => {
    this.setState({
      ...this.state,
      showEmployees: true,
    });
    this.getUsers();
  };
  hideEditBar = () => {
    this.setState({
      ...this.state,
      showEmployees: true,
      isEditEmployees: false,
    });
    this.getUsers();
  };
  hideMigrateBar=()=>{
    this.setState({
      ...this.state,
      showEmployees: true,
      isMigrate: false,
    });
    this.getUsers();
  }

  updatePrivilage = (e, cvtid) => {
    
    axios
      .post(constants.endpoint + constants.updatePrivilage, {
        cvtid: cvtid,
        activityRole: e.target.value,
      })
      .then((response) => {
        if (response.status == 202) {
          handleAlert(response, {
            202: "User privilages updated successfully !",
          });
          this.getUsers();
        }
      })
      .catch((error) => {
        handleAlert(error.response, {
          400: "Can't give this privilage to the user."
        });
      });
  };

  

  activateUser = (e, cvtid) => {
    axios
      .post(constants.endpoint + constants.activateUser, {
        userid: cvtid,
      })
      .then((response) => {
        if (response.status == 202) {
          handleAlert(response, {
            202: "user activated successfully !",
          });
          this.getUsers();
        }
      })
      .catch((error) => {
        handleAlert(error.response, {});
      });
  };

  deActivateUser = (e, cvtid) => {
    axios
      .post(constants.endpoint + constants.deactivateUser, {
        userid: cvtid,
      })
      .then((response) => {
        if (response.status == 202) {
          handleAlert(response, {
            202: "user de-activated successfully !",
          });
          this.getUsers();
        }
      })
      .catch((error) => {
        handleAlert(error.response, {});
      });
  };

  deleteUser = (cvtid)=>{
    
    axios
      .post(constants.endpoint + constants.deleteUser, {
        userid: cvtid,
      })
      .then((response) => {
        if (response.status == 202) {
          handleAlert(response, {
            202: "User deleted successfully !",
          });
          this.getUsers();
        }
      })
      .catch((error) => {
        handleAlert(error.response, {});
      });
  };

  editUser = (cvtid) => {
    this.state.data.users.map((elem) => {
      if (elem.cvtid === cvtid) {
        this.setState(
          {
            ...this.state,
            showEmployees: false,
            userInfo: elem,
          },
          () => {

          }
        );
      }
    });
  };

  restrictSpecialCharacters=(e)=>{
    
    //const re = /[0-9A-F:]+/g;
    const re = /[0-9a-zA-Z ]/i;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  validateName=(e)=>{
    
    //const re = /[0-9A-F:]+/g;
    const re = /[a-zA-Z ]/i;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  render() {
    const data=this.state.data.users
    const columns = [
      {
        name: "Employee Id",
        selector: "cvtid",
        sortable: true,
        right: false,
        
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        right: false,
      },
      {
        name: "Organisation",
        selector: "organisation",
        sortable: true,
        right: true,
        right: false,
      },
      {
        name: "Project",
        cell: (row) => (
          
          <p>{row.project.map((proj, index) => (
              proj +","
          ))}</p>
          ),
      },
      {
        name: "Designation",
        selector: "role",
        sortable: true,
        right: false,
      },
      {
        name: "Level",
        selector: "level",
        sortable: true,
        right: false,
      },
      {
        name: "Manager",
        cell: (row) => (
          
        <p>{row.manager.map((usr, index) => (
            usr.name +","
        ))}</p>
        ),
      },

      {
        name: "Privilege",
        cell: (row) => (
          <div>
            <select
              value={row.activityRole}
              onChange={(e) => this.updatePrivilage(e, row.cvtid)}
            >
              <option value="User">User</option>
              <option value="Manager">Manager</option>
              {localStorage.activityRole == "Owner" && (
                <option value="Owner">Owner</option>
              )}
              <option value="Admin">Admin</option>
            </select>
          </div>
        ),
      },
      {
        name: "Reset Password",
        sortable: true,
        right: false,
        cell: (row) => (
          <button
            onClick={(e) => this.resetPassword(row.cvtid)}
            type="button"
            className="btn btn-xs btn-danger"
          >
            Reset
          </button>
        ),
      },
      {
        name: "Delete User",
        sortable: true,
        right: false,
        cell: (row) => (
          <button
            onClick={(e) => this.deleteUser(row.cvtid)}
            type="button"
            className="btn btn-xs btn-danger"
          >
            Delete
          </button>
        ),
      },
    ];
    const tableData = {
    columns,
    data,
  };
   

    return (
      <React.Fragment>
        <div className="content content-fixed">
          <div className="container col-12">
            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
              <div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Manage Employee
                    </li>
                  </ol>
                </nav>
                <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Users Management</h4>
              </div>

              <div className="row">

                <button
                  onClick={this.showEditBar}
                  style={{
                    display: this.state.showEmployees ? "block" : "none",
                  }}
                  className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                >
                  <i data-feather="file" className="wd-10 mg-r-5"></i> Edit
                  Employee
                </button>

                <button
                  onClick={this.showEmployeeBar}
                  style={{
                    display: this.state.showEmployees ? "block" : "none",
                  }}
                  className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                >
                  <i data-feather="file" className="wd-10 mg-r-5"></i> Add
                  Employee
                </button>
              </div>
            </div>

            <div
              className="row row-xs"
              style={{
                display:
                  !this.state.showEmployees && !this.state.isEditEmployees && !this.state.isMigrate
                    ? "block"
                    : "none",
              }}
            >
              <div className="col-12">
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Add Employee
                    </h6>
                  </div>
                  <div className="card-body pd-20">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputcvtid">Employee ID</label>
                        <input onKeyPress={(e) => this.restrictSpecialCharacters(e)}
                          value={this.state.userInfo.cvtid}
                          onChange={(e) => this.handleInput(e, "cvtid")}
                          type="text"
                          className="form-control"
                          id="inputcvtid"
                          placeholder="Employee ID"
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputcvtid">Date of joining</label>
                        <input
                          value={this.state.userInfo.doj}
                          onChange={(e) => this.handleInput(e, "doj")}
                          type="date"
                          className="form-control"
                          id="inputcvtid"
                          placeholder="Date of joining"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputname">Name</label>
                        <input onKeyPress={(e) => this.validateName(e)}
                          value={this.state.userInfo.name}
                          onChange={(e) => this.handleInput(e, "name")}
                          type="text"
                          className="form-control"
                          id="inputname"
                          placeholder="Name"
                          autocomplete="off"
                          required
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="inputemail">Email</label>
                        <input
                          pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$"
                          value={this.state.userInfo.email}
                          onChange={(e) => this.handleInput(e, "email")}
                          type="text"
                          className="form-control"
                          id="inputemail"
                          placeholder="Email"
                          autocomplete="off"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="inputname">Privilage</label>
                        <select
                          value={this.state.userInfo.activityRole}
                          onChange={(e) => this.handleInput(e, "activityRole")}
                          className="custom-select"
                        >
                          <option value="">Select Privilage</option>
                          <option value="User">User</option>
                          {localStorage.activityRole == "Owner" && (
                            <option value="Owner">Owner</option>
                          )}
                          <option value="Admin">Admin</option>
                        </select>
                      </div>
                    
                      <div className="form-group col-md-4">
                        <label>Organisation</label>
                        <select
                          value={this.state.userInfo.activeOrganisation}
                          onChange={(e) =>
                            this.handleInput(e, "activeOrganisation") 
                          }
                          className="custom-select"
                        >
                          <option>Select Organisation</option>
                          {this.state.data.organisations.map((org, oIndex) => (
                            <option key={oIndex} value={org.name}>
                              {org.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                    </div>
                {
                  (this.state.userInfo.activityRole === "User" || this.state.userInfo.activityRole === "Manager") && (
                    <div>

                    <div className="form-row">

                    <div className="form-group col-md-8">
                        <label>Project</label>
                        <select
                          value={this.state.userInfo.activeProject}
                          onChange={(e) => this.handleInput(e, "activeProject")}
                          className="custom-select"
                          multiple
                        >
                          <option value="" disabled selected>Select Project</option>
                          {this.state.data.activeProjects.map(
                            (project, oIndex) => (
                              <option key={oIndex} value={project.name}>
                                {project.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                    </div>
                    <div className="form-row">
                    
                    
                      <div className="form-group col-md-4">
                        <label>Designation</label>
                        <select
                          value={this.state.userInfo.activeRole}
                          onChange={(e) => this.handleInput(e, "activeRole")}
                          className="custom-select"
                        >
                          <option>Select Designation</option>
                          {this.state.data.roles.map((role, oIndex) => (
                            <option key={oIndex} value={role.name}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label>Level</label>
                        <select
                          value={this.state.userInfo.activeLevel}
                          onChange={(e) => this.handleInput(e, "activeLevel")}
                          className="custom-select"
                        >
                          <option>Select Role</option>
                          {this.state.data.activeLevels.map((level, oIndex) => (
                            <option key={oIndex} value={level.name}>
                              {level.name}
                            </option>
                          ))}
                        </select>
                      </div>

                    </div>

                  
                    </div>
                  )}
                  
                    <button
                      onClick={this.addEmployee}
                      type="button"
                      className="btn btn-primary"
                    >
                      Add Employee
                    </button>
                    <button
                      onClick={this.hideEmployeeBar}
                      type="button"
                      className="btn btn-primary"
                      style={{ marginLeft: "20px" }}
                    >
                      View Employees
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row row-xs"
              style={{
                display:
                  !this.state.showEmployees && this.state.isEditEmployees && !this.state.isMigrate
                    ? "block"
                    : "none",
              }}
            >
              <div className="col-12">
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Edit Employee
                    </h6>
                  </div>
                  <div className="card-body pd-20">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label>Select Employee</label>
                        <select
                          value={this.state.selectCvtId}
                          onChange={this.onEmployeeSelect}
                          className="custom-select"
                        >
                          <option>Select Employee</option>
                          {this.state.data.users.map((org, oIndex) => (
                            <option key={oIndex} value={org.cvtid}>
                              {org.name} : {org.cvtid}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          this.state.selectCvtId.length === 0
                            ? "none"
                            : "block",
                      }}
                    >
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputcvtid">Employee ID</label>
                          <input
                            value={this.state.userInfo.cvtid}
                            onChange={(e) => this.handleInput(e, "cvtid")}
                            type="text"
                            className="form-control"
                            id="inputcvtid"
                            autocomplete="off"
                            placeholder={this.state.selectedUser.cvtid}
                          />
                        </div>

                        <div className="form-group col-md-4">
                        <label htmlFor="inputname">Name</label>
                        <input
                          value={this.state.userInfo.name}
                          onChange={(e) => this.handleInput(e, "name")}
                          type="text"
                          className="form-control"
                          id="inputname"
                          autocomplete="off"
                          placeholder={this.state.selectedUser.name}
                          required
                        />
                      </div>

                      </div>

                      <div className="form-row">
                      <div className="form-group col-md-4">
                          <label htmlFor="inputemail">Email</label>
                          <input
                            value={this.state.userInfo.email}
                            onChange={(e) => this.handleInput(e, "email")}
                            type="text"
                            className="form-control"
                            id="inputemail"
                            autocomplete="off"
                            placeholder={this.state.selectedUser.email}
                          />
                        </div>

                        <div className="form-group col-md-4">
                          
                          <label>Project</label>
                          
                          <select
                            value={this.state.selectedProject}
                            onChange={(e) =>
                              this.updateProjects(e)
                            }
                            className="custom-select"
                            multiple
                          >
                            <option value="" disabled selected>Select Project</option>
                            {this.state.updateProject.map(
                              (project, oIndex) => (
                                <option key={oIndex} value={project.name}>
                                  {project.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                     
                    </div>
                    <div className="card-body pd-20">
                      <div className="form-row">
                        <button
                          onClick={this.updateEmployee}
                          type="button"
                          className="btn btn-primary"
                        >
                          Update Employee 
                        </button>
                        <button
                          onClick={this.hideEditBar}
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "20px" }}
                        >
                          View Employees
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{
                display:
                  this.state.showEmployees && !this.state.isEditEmployees && !this.state.isMigrate
                    ? "block"
                    : "none",
              }}
            >
              <div className="col-lg-12 col-xl-12 mg-t-10">
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Employee
                    </h6>
                  </div>



                  <div className="card-body p-0 ml-3 mt-2 mr-3">
                  <DataTableExtensions
                    {...tableData}
                    print={false}
                    export={false}
                    filterPlaceholder="Employee ID/Name"
                  >
                    <DataTable
                       noHeader
                       defaultSortField="id"
                       defaultSortAsc={false}
                       pagination
                       highlightOnHover
                       
                    />
                  </DataTableExtensions>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default addusers;
