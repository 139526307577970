import React, { Component } from "react";
import axios from "axios";
import * as constants from "./../../constants";
import Header from "./../header/header";
class AllEmployeeReview extends Component {
  state = {
    reviews: [],
    selectedReview: {
      quarter: [],
    },
    reviewUsers: [],
    showUsers: false,
  };

  onSelectYear = (e) => {
    let selectedReview = {};
    this.state.reviews.map((review) => {
      if (review.year === e.target.value) {
        selectedReview = review;
        selectedReview.selectedQuarter = "";
      }
    });

    this.setState({
      ...this.state,
      selectedReview,
    });
  };

  resetReview = (e, cvtid) => {
    let currReview =
      this.state.selectedReview.year +
      "-" +
      this.state.selectedReview.selectedQuarter;
    axios
      .post(constants.endpoint + constants.resetReview, {
        reviewId: currReview,
        cvtid: cvtid,
      })
      .then((response) => {
        if (response.status === 202) {
          this.showError("success", "Review reset succesfull !");
          this.fetchResults();
        }
      })
      .catch((error) => {
        this.showError("danger", "Unable to connect with backend !");
      });
  };

  getUsersforReview = () => {
    axios
      .get(constants.endpoint + constants.reviewUser + "?organisation=" +localStorage.organisation)
      .then((response) => {
        this.setState({
          ...this.state,
          reviewUsers: response.data,
        });
      })

      .catch((error) => {
        this.showError("danger", "Unable to connect with backend !");
      });
  };

  onSelectQuarter = (e) => {
    this.setState({
      ...this.state,
      selectedReview: {
        ...this.state.selectedReview,
        selectedQuarter: e.target.value,
      },
    });
  };

  fetchResults = () => {

    axios
      .get(
        constants.endpoint +
          constants.accResult +
          "?reviewId=" +
          this.state.selectedReview.year +
          "-" +
          this.state.selectedReview.selectedQuarter
      )
      .then((response) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.showUsers = true;

        tmpState.reviewUsers.map((user) => {
          let isSet = false;
          response.data.reviews.map((review) => {
            if (user.cvtid === review.cvtid) {
              isSet = true;
              console.log(review.pendingWith);
              if (review.isPublished === "true") {
                user.status = "Completed";
                user.rating = review.finalRating;
                user.comments = review.finalComments;
              }else if(review.pendingWith == "User"){
                user.status = "User Submission";
                user.rating = 0;
                user.comments = "";
              }else if(review.pendingWith == "Manager"){
                user.status = "Manager Review";
                user.rating = 0;
                user.comments = "";
              } else {
                user.status = "Pending with Manager";
                user.rating = 0;
                user.comments = "";
              }
            }
          });

          if (!isSet) {
            user.status = "Not Initaited";
            user.rating = 0;
            user.comments = "";
          }
        });

        this.setState(tmpState);
      })
      .catch((error) => {
        this.showError("danger", "Unable to connect with backend !");
      });
  };

  getReviews = () => {
    axios
      .get(
        constants.endpoint +
          constants.review +
          "?organisation=" +
          localStorage.organisation
      )
      .then((response) => {

        var reviews = [];
        response.data.reviews.map((e) => {
          let isExists = false;
          let ref = {};
          reviews.map((i) => {
            if (i.year === e.year) {
              isExists = true;
              ref = i;
            }
          });
          if (isExists) {
            ref.quarter.push(e.quarter);
          } else {
            let y = {
              year: "",
              quarter: [],
            };
            y.year = e.year;
            y.quarter.push(e.quarter);
            reviews.push(y);
          }
        });
        this.setState({
          ...this.state,
          reviews: reviews,
        });
      })
      .catch((error) => {
        this.showError("danger", "Unable to connect with backend !");
      });
  };

  showError = (type, message) => {
    document.querySelectorAll("#errorPlaceholder")[0].innerHTML = `
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `;
  };

  componentDidMount() {
    this.getReviews();
    this.getUsersforReview();
  }

  render() {
    return (
      <React.Fragment>
        
        <Header />

                <div className="content content-fixed">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0" style={{minHeight:'80vh'}}>
                      <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                              <li className="breadcrumb-item">Dashboard</li>
                              <li className="breadcrumb-item active" aria-current="page">Existing Review</li>
                            </ol>
                          </nav>
                          <h4 className="mg-b-0 tx-spacing--1">Welcome {localStorage.name}</h4>
                        </div>
                        <div className="d-none d-md-block">
                          <a href="/selfreview" style={{display :'inline-block'}} className="nav-link" target="_self"><i class="fas fa-arrow-left"></i> &nbsp; Back to Self Review</a>
                        </div>
                      </div>
        
            {/* Error Handler */}
            <div className="row">
              <div id="errorPlaceholder" className="col-12"></div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-xl-12 mg-t-10">
                <div className="card card-body shadow-none">
                  <div className="marker marker-ribbon pos-absolute t-10 l-0">
                    Existing Reviews
                  </div>
                  <div className="row" style={{ marginTop: "32px" }}>
                    <div className="col-3">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                        Select Review Year
                      </h6>

                      <div className="input-group mg-b-10">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1"><i class="far fa-calendar-alt"></i></span>
                        </div>
                      <select
                        onChange={this.onSelectYear}
                        className="custom-select"
                      >
                        <option>Select Year</option>
                        {this.state.reviews.map((rev, index) => (
                          <option key={index} value={rev.year}>
                            {rev.year}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>

                    <div className="col-3">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                        Select Review Quarter
                      </h6>
                      <div className="input-group mg-b-10">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1"><i class="fas fa-calendar-day"></i></span>
                        </div>
                      <select
                        value={this.state.selectedReview.selectedQuarter}
                        onChange={this.onSelectQuarter}
                        className="custom-select"
                      >
                        <option>Select Quarter</option>
                        {this.state.selectedReview.quarter.map(
                          (quarter, index) => (
                            <option key={index} value={quarter}>
                              {quarter}
                            </option>
                          )
                        )}
                      </select>
                      </div>
                    </div>

                    <div
                      className="col-3"
                      style={{
                        margin: "Auto 0px",
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      <button
                        onClick={this.fetchResults}
                        type="button"
                        className="btn btn-sm btn-primary"
                      >
                        <i class="fas fa-redo-alt ga-lg"></i>&nbsp;&nbsp;Get Reviews
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{ display: this.state.showUsers ? "" : "none" }}
            >
              <div className="col-lg-12 col-xl-12 mg-t-10">
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                    Team Members
                    </h6>
                  </div>

                  <div className="card-body pd-20">
                    <table id="manager-emp-table" className="table table-bordered table-light table-hover">
                      <thead>
                        <tr>
                          <th scope="col" className="font-weight-bold">ID</th>
                          <th scope="col" className="font-weight-bold">Emp Code</th>
                          <th scope="col" className="font-weight-bold">Name</th>
                          <th scope="col" className="font-weight-bold">Role</th>
                          <th scope="col" className="font-weight-bold">Level</th>
                          <th scope="col" className="font-weight-bold">Organisation</th>
                          <th scope="col" className="font-weight-bold">Project</th>
                          <th scope="col" className="font-weight-bold">Status</th>
                          <th scope="col" className="font-weight-bold">Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.reviewUsers.map((user, index) => (
                         
                          <tr key={index}>
                            <th scope="row">{(index+1)}</th>
                            <td scope="row">{user.cvtid}</td>
                            <td scope="row">{user.name}</td>
                            <td scope="row">{user.role}</td>
                            <td scope="row">{user.level}</td>
                            <td scope="row">{user.organisation}</td>
                            <td scope="row">{user.project.join(", ")}</td>
                            <td scope="row">{user.status}</td>
                            <td scope="row">
                              <span> {user.rating} {user.comments} </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AllEmployeeReview;
