import React, { Component } from 'react';
import axios from "axios";
import * as constants from "./../../constants";
import Header from "./../header/header";
import DataTable from "react-data-table-component";
import Modal from 'react-bootstrap4-modal';

class PromotionList extends Component {
    state = { 
        user : [],
        promotionList:[],
        selectedUser : {},
        roles : [],
        levels : [],
        selectedRole : '',
        selectedLevel : '',
        showModal:false,
        description:"",
        promoteDecision:"",
        rowData:{},
        modalMessage:"",
        showMessage:false,
        alertType:"",
        showTeam:false,
        teamMembers:[],
        showPromotions:false,
        selectEmployee:""
     }

     

     submitResponse = (row) => {

        var managerView={"managerId":localStorage.cvtid,"managerName":localStorage.name,"reason":this.state.description,"approved":this.state.promoteDecision}

        axios.post(constants.endpoint+constants.submitPromoteReason, {
            cvtid : row.cvtid,
            promoteId : row.promoteId,
            organisation : row.organisation,
            promoteReason : managerView,

        }).then((response) => {
            this.setState({showMessage:true,modalMessage : "Your decision has been submitted successfully!",alertType:"alert-success",description:"",promoteDecision:""})
            this.getPromotionList();
        }).catch((error) => {
            if(error.response.status ===409){
                this.setState({showMessage:true,modalMessage : "Manager has already submitted his response!",alertType:"alert-danger"})
            }
            else{
                this.setState({showMessage:true,modalMessage : "Something went wrong!",alertType:"alert-danger"})
            }
        });
    }
    

    getPromotionList =()=>{
        let organisation = localStorage.organisation;
        var promotionList=[];
        var promotionRequests=[];
        var teamMembers=[];

        axios.get(constants.endpoint+constants.promote + "?organisation=" + organisation)
        .then((response)=>{
          response.data.map((promotion, index) => {
            if(promotion.status === "Pending"){
                promotionRequests.push(promotion);
              }
              else{
                if(localStorage.cvtid === promotion.manager){
                    teamMembers.push(promotion)
                }
                promotionList.push(promotion);
              }
              
          }
        )
        
        this.setState({
          promotionList : promotionList,
          promotionRequests : promotionRequests,
          teamMembers : teamMembers,
        })
          
        }).catch((error)=>{
            this.showError("danger","Something unexpected happened !");
        })
    }

    downloadDocument=(docName)=>{

      window.open(constants.endpoint+constants.downloadDocument + "/" + docName, '_blank')
    }

    

    componentDidMount(){
        this.getPromotionList();
    }

    openModal = (row)=>{
      this.setState({
        showModal:true,
        rowData: row,
        showMessage: false,
      })
    }
    closeModal=()=>{
      this.setState({
        showModal:false,
        showMessage:false,
      })
    }

    onDescInput = (e) => {
      this.setState({
          description: e.target.value
      });
  }
  selectReviewEmployee=(e) => {
    this.setState({
        selectEmployee: e.target.value
    });
}
  promoteDecision=(e)=>{
    this.setState({
        promoteDecision: e.target.value
    });
  }

    showError = (type, message) => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML  = (`
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `);
    };

    teamPromotionStatus=()=>{
        this.setState({showTeam:true})
    }

    showPromotions=()=>{
        this.setState({showPromotions:true})
        var selected = [];
        for(var i=0 ; i<this.state.teamMembers.length;i++){
            if(this.state.teamMembers[i].cvtid === this.state.selectEmployee){
                
                selected.push(this.state.teamMembers[i])
            }
        }
        this.setState({userPromotionStatus:selected})
    }

    resetUser=()=>{
        this.setState({
            selectEmployee:"",
            showPromotions:false,
            userPromotionStatus:[]
        })
    }

    render() {

        const requestColumns=[
            {
                name: "Employee Id",
                selector: "cvtid",
                sortable: true,
                right: false,
              },
              {
                name: "Name",
                selector: "name",
                sortable: true,
                right: false,
              },
              {
                name: "Organisation",
                selector: "organisation",
                sortable: true,
                right: true,
                right: false,
              },
              {
                name: "New Role",
                selector: "newRole",
                sortable: true,
                right: true,
                right: false,
              },
              {
                name: "New Level",
                selector: "newLevel",
                sortable: true,
                right: false,
              },
              
              {
                name: "Previous Role",
                selector: "currentRole",
                sortable: true,
                right: true,
                right: false,
              },
              {
                name: "Previous Level",
                selector: "currentLevel",
                sortable: true,
                right: false,
              },
              
              {
                name: "Decision",
                sortable: true,
                right: false,
                cell: (row) => (
                  <button
                        onClick={()=>this.openModal(row)}
                        type="button"
                        className="btn btn-xs btn-success"
                      >
                        Respond
                  </button>
    
                ),
              },
              
              {
                name: "Promotion Document",
                sortable: true,
                right: false,
                cell: (row) => (
                  <button
                    onClick={(e) => this.downloadDocument(row.sheet)}
                    type="button"
                    className="btn btn-xs btn-secondary"
                  >
                    Download
                  </button>
                ),
              },
              
        ];

        const reasonColumns=[
            {
                name: "Manager Id",
                selector: "managerId",
                sortable: true,
                right: false,
              },
              {
                name: "Manager",
                selector: "managerName",
                sortable: true,
                right: false,
              },
              {
                name: "Response",
                cell: (row) => (
                    <a>
                      {row.reason}
                    </a>
         
                   ),
                right: true,
                right: false,
                grow : 10
                
              },
              {
                name: "Approved",
                selector: "approved",
                sortable: true,
                
              },
        ];

        const promotionColumns=[{
            name: "Employee Id",
            selector: "cvtid",
            sortable: true,
            right: false,
          },
          {
            name: "Name",
            selector: "name",
            sortable: true,
            right: false,
          },
          {
            name: "Organisation",
            selector: "organisation",
            sortable: true,
            right: true,
            right: false,
          },
          {
            name: "Previous Role",
            selector: "currentRole",
            sortable: true,
            right: true,
            right: false,
          },
          {
            name: "Previous Level",
            selector: "currentLevel",
            sortable: true,
            right: false,
          },
          {
            name: "New Role",
            selector: "newRole",
            sortable: true,
            right: false,
          },
          
          {
            name: "New Level",
            selector: "newLevel",
            sortable: true,
            right: false,
          },
          {
            name: "Promotion Date",
            sortable: true,
            right: false,
            cell: (row) => (
            <h6>{row.resultDate!=null?row.resultDate.slice(0,10):''}</h6>
            ),
          },
          {
            name: "Admin Reason",
            selector: "managerReason",
            sortable: true,
            right: false,
          }
       
      ];



        return ( 
            <React.Fragment>
                <Header/>
                
                <div className="content content-fixed">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0" style={{minHeight:'80vh'}}>
                      <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                              <li className="breadcrumb-item">Dashboard</li>
                              <li className="breadcrumb-item active" aria-current="page">Existing Review</li>
                            </ol>
                          </nav>
                          <h4 className="mg-b-0 tx-spacing--1">Welcome {localStorage.name}</h4>
                        </div>
                        
                        <div className="d-none d-md-block">
                          <a href="/selfreview" style={{display :'inline-block'}} className="nav-link" target="_self">Back to Self Review</a>
                        </div>
                      </div>

                      {this.state.showTeam && (
                          <div className="row" >
                          <div className="col-lg-12 col-xl-12 mg-t-10">
                              <div className="card" style={{padding:'25px'}}>
                                  <div className="row">
                                      <div className="col-4">
                                          <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Select Employee</h6>
                                          <select value={this.state.selectEmployee} onChange={this.selectReviewEmployee} className="custom-select">
                                              <option disabled selected value="">Select Employee</option>
                                              {
                                              this.state.teamMembers.map((user,uIndex) => (
                                                  <option key={uIndex} value={user.cvtid}>{user.name}</option>
                                              ))
                                                }
                                          </select>
                                      </div>
                                      <div className="col-4">
                                          <button  onClick={this.showPromotions} style={{position:'relative', top:'38%'}} type="button" className="btn btn-sm btn-primary">Show Promotions</button>
                                          <button onClick={this.resetUser} style={{position:'relative', top:'38%', marginLeft:'20px'}} type="button" className="btn btn-sm btn-primary">Reset</button>
                                          <a href="/manager/promotions" target="_self" style={{position:'relative', top:'38%', marginLeft:'20px'}}  className="btn btn-sm btn-primary">Back</a>
                                      </div>
                                  </div>
                                 {this.state.showPromotions &&( 
                                 <DataTable
                                    columns={promotionColumns}
                                    data={this.state.userPromotionStatus}
                                    fixedHeader
                                    pagination
                                    />
                                 )}
                              </div>
                              </div>
                        </div>
                      )}
                    { !this.state.showTeam && (
                      <div className="col-lg-12 col-xl-12 mg-t-10">
                        <div className="card">
                        <div className="card-header pd-t-20 pd-b-0 bd-b-0 row">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8 col-10">
                            Promote Request
                            </h6>
                            <div className="col-2">
                            <button onClick={this.teamPromotionStatus} style={{marginTop:-9}} className=" tx-spacing-1 btn btn-primary btn-xs tx-semibold  " target="_self">Team Status</button>
                            </div>
                        </div>
                        
                        <Modal className="" visible={this.state.showModal} onClickBackdrop={this.closeModal}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Manager Response</h5>
                                </div>
                                <div className="modal-body">
                                {
                                    this.state.showMessage && (
                                        <div className="col-12">
                                            <div className={"alert alert-dismissible fade show " + this.state.alertType} role="alert">
                                                {this.state.modalMessage}
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }

                                    <div className="col-12">
                                        <form>
                                            <DataTable
                                            columns={reasonColumns}
                                            data={this.state.rowData.promoteReason}
                                            fixedHeader
                                            pagination={false}
                                            />
                                            <div className="mt-2 col-lg-12 col-xl-12">
                                                <label htmlFor="description" className="font-weight-bold">Enter Reason</label>
                                                <textarea value={this.state.description} onChange={this.onDescInput} className="form-control" rows="3" placeholder="Describe your reason"></textarea>
                                            </div>
                                            <div className="mt-2 col-lg-12 col-xl-12">
                                                <label htmlFor="decision" className="font-weight-bold">Approval</label>
                                                <select value={this.state.promoteDecision} id="decision"  onChange={this.promoteDecision} className="custom-select" required>
                                                    <option disabled value="">Your Decision</option>
                                                    <option>Approve</option>
                                                    <option>Reject</option>
                                                </select>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={()=> this.submitResponse(this.state.rowData)}>
                                        Submit
                                </button>
                                
                            </div>
                        </Modal>

                        <div className="card-body p-0">
                                
                            <DataTable
                            columns={requestColumns}
                            data={this.state.promotionRequests}
                            fixedHeader
                            pagination
                            />
                        </div>
                        </div>
                    </div>
                    )}
                      </div>
                      </div>
            </React.Fragment>
         );
    }
}
 
export default PromotionList;