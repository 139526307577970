import React, { Component } from 'react';
import Aside from './../aside';
import axios from 'axios';
import * as constants from './../../../constants';

import OrganisationLadder from './organisationLadder';

import { handleAlert } from './../../alertHandler/alertHandler';
import { errorMonitor } from 'form-data';

class organisation extends Component {

    state = {
        organisation: {
            buttonText: "Add Organisation",
            text: ""
        },
        project: {
            buttonText: "",
            text: ""
        },
        organisations: [],
        input: {
            orgName: "",
            logo: "",
            projName: "",
            orgSelected: "",
            oldName: '',
            selectedManager:"",
            oldManager:"",
        },
        operation: 'Add',
        allUsers :[]
    }

    handleInput = (e, field) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.input[field] = e.target.value;
        this.setState(tmpState);
    }
    
    clearInput = (fieldName) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.input[fieldName] = "";
        this.setState(tmpState);
    }

    addProject = () => {
        if (this.state.operation === 'Add') {
            var arr = this.state.input.selectedManager.split(":");
            var manager={"name":arr[0],"cvtid":arr[1]}
            axios.post(constants.endpoint + constants.project,
                {
                    orgName: this.state.input.orgSelected,
                    projName: this.state.input.projName,
                    manager: manager
                }).then((response) => {
                    if (response.status == 201) {
                        handleAlert(response, {
                            201: "Project added successfully !"
                        });
                        this.clearInput("projName");
                        this.clearInput("orgName");
                        this.clearInput("manager");
                        this.getOrganisations();
                    }
                })
                .catch((error) => {
                    handleAlert(error.response, {
                        409: "Project already exists in organisation."
                    });
                });

        } else {
            
            var arr = this.state.input.selectedManager.split(":");
            var manager={"name":arr[0],"cvtid":arr[1]}

            var arr2= this.state.input.oldManager.split(":");
            var oldManager={"name":arr2[0],"cvtid":arr2[1]}


            axios.post(constants.endpoint + constants.editProject,
                {
                    orgName: this.state.input.orgSelected,
                    projName: this.state.input.projName,
                    oldName: this.state.input.oldName,
                    manager: manager,
                    oldManager: oldManager,
                }).then((response) => {
                    this.setState({
                        ...this.state,
                        operation: 'Add',
                        input: {
                            ...this.state.input,
                            projName: "",
                            orgSelected: "",
                            selectedManager:"",
                        }
                    }, () => {
                        handleAlert(response, {
                            201: "Project updated successfully !",
                        });
                        this.getOrganisations();
                    });
                })
                .catch((error) => {
                    handleAlert(error.response, {
                        400: "Please clear the project name from existing user."
                    });
                });
        }
    }



    addOrganisation = () => {
        axios.post(constants.endpoint + constants.organisation, { orgName: this.state.input.orgName, logo: this.state.input.logo })
            .then((response) => {
                handleAlert(response, {
                    201: "Organisation created successfully."
                });
                this.getOrganisations();
            })
            .catch((error) => {
                handleAlert(error.response, {
                    409: "Organisation already exists"
                });
            }).then(() => {
                this.clearInput("orgName");
                this.clearInput("logo");
            });
    }

    getOrganisations = () => {
        axios.get(constants.endpoint + constants.organisation)
            .then((response) => {
                this.setState({
                    ...this.state,
                    organisations: response.data.organisations
                })
            }).catch((error) => {
                handleAlert(error.response, {});
            });
    }

    editProject = (orgId, projectId) => {
        this.setState({
            ...this.state,
            input: {
                orgName: "",
                logo: "",
                oldName: this.state.organisations[orgId].projects[projectId].name,
                projName: this.state.organisations[orgId].projects[projectId].name,
                orgSelected: this.state.organisations[orgId].name,
                selectedManager: this.state.organisations[orgId].projects[projectId].manager[0].name+":"+this.state.organisations[orgId].projects[projectId].manager[0].cvtid,
                oldManager: this.state.organisations[orgId].projects[projectId].manager[0].name+":"+this.state.organisations[orgId].projects[projectId].manager[0].cvtid
            },
            operation: 'Edit'
        });
    }

    deleteProject = (orgId, projectId) => {
        axios.post(constants.endpoint + constants.deleteProject,
            {
                orgName: this.state.organisations[orgId].name,
                projName: this.state.organisations[orgId].projects[projectId].name
            }).then((response) => {
                handleAlert(response, {
                    202: "Project deleted successfully."
                });
                this.clearInput("projName");
                this.getOrganisations();
            })
            .catch((error) => {
                handleAlert(error.response, {
                    400: "Please clear the project name from existing user."
                });
            });
    }

    resetEdit = () => {
        this.setState({
            ...this.state,
            operation: "Add",
            input: {
                ...this.state.input,
                projName: "",
                orgSelected: "",
                oldName: '',
                selectedManager: '',
            }
        })
    }

    componentDidMount() {
        this.getOrganisations();
        this.getUsers();
    }

    getUsers = () => {
        axios
          .get(
            constants.endpoint +
              constants.user +
              "?organisation=" +
              "all"
          )
          .then((response) => {
            
            this.setState({
              ...this.state,
              allUsers : response.data,
            });
          })
          .catch((error) => {
            this.showError("danger", "Something unexpected happened !");
          });
      };

      deleteOrganisation = (orgId, orgName) => {
        axios.post(constants.endpoint + constants.deleteOrganisation,
            {
                orgName: orgName,
            }).then((response) => {
                handleAlert(response, {
                    202: "Organisation deleted successfully."
                });
                this.clearInput("projName");
                this.getOrganisations();
            })
            .catch((error) => {
                handleAlert(error.response, {
                    400: "Please clear the project name from existing user."
                });
            });
      }

    render() {
        return (
            <React.Fragment>
                <div className="content content-fixed">
                    <div className="container col-12">
                        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                        <li className="breadcrumb-item">Dashboard</li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage organisations & projects</li>
                                    </ol>
                                </nav>
                                <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Organisation Management</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-8 pl-0">
                                <div className="col-12 ">
                                    <div className="card card-body shadow-none">
                                        <div className="marker marker-ribbon pos-absolute t-10 l-0">Add Organisation</div>

                                        <div className="row" style={{ marginTop: '32px' }}>
                                            <div className="col-4">
                                                <input value={this.state.input.orgName} onChange={(e) => this.handleInput(e, "orgName")} type="text" className="form-control" placeholder="Enter organisation name" />
                                            </div>
                                            <div className="col-4">
                                                <input value={this.state.input.logo} onChange={(e) => this.handleInput(e, "logo")} type="text" className="form-control" placeholder="Logo name" />
                                            </div>
                                            <div className="col-4">
                                                <button onClick={this.addOrganisation} type="button" className="btn btn-sm btn-primary">{this.state.organisation.buttonText}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" style={{ marginTop: '20px' }}>
                                    <div className="card card-body shadow-none" style={{ minHeight: '110px' }}>
                                        <div className="marker marker-ribbon pos-absolute t-10 l-0">Add Projects</div>

                                        <div className="row" style={{ marginTop: '32px' }}>
                                            <div className="col-3">
                                                <input value={this.state.input.projName} onChange={(e) => this.handleInput(e, "projName")} type="text" className="form-control" placeholder="Enter project name" />
                                            </div>
                                            <div className="col-3">
                                                <select value={this.state.input.selectedManager} onChange={(e) => this.handleInput(e, "selectedManager")} className="custom-select">
                                                    <option >Select Manager</option>
                                                    {
                                                        this.state.allUsers.map((manager, index) => (
                                                        <option key={index} value={manager.name + ":"+manager.cvtid}>{manager.name} : {manager.cvtid}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <select value={this.state.input.orgSelected} onChange={(e) => this.handleInput(e, "orgSelected")} className="custom-select">
                                                    <option >Select Category</option>
                                                    {
                                                        this.state.organisations.map((org, index) => (
                                                            <option key={index} >{org.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <button type="button" onClick={this.addProject} className="btn btn-sm btn-primary">{this.state.operation + ' Project'}</button>
                                                <button type="button" onClick={this.resetEdit} className="btn btn-sm btn-primary ml-2">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <OrganisationLadder
                                organisations={this.state.organisations}
                                editProject = {this.editProject}
                                deleteProject = {this.deleteProject}
                                deleteOrganisation = {this.deleteOrganisation}
                               
                            />
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default organisation;