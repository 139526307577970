import React, { Component } from 'react';
import Header from './../header/header';
import axios from 'axios';
import * as constants from './../../constants';

class previousReviews extends Component {

    state = {
        submissions : [],
        preview : false,
        currentReviewAnswers : [], 
        currentQuestionSet : [],
        submissionByYear : [],
        cumulativeScore:0,
        cumulativeScoreYear:"",
    }

    getQuestionSetBySetId = (setId) => {

        axios.post(constants.endpoint+constants.questionSetById,{
            questionSetId : setId
        })
        .then((response) => {
            this.setState({
                ...this.state,
                currentQuestionSet : response.data.questionSet.area,
                preview : true
            });

            console.log(this.state);
        })
        .catch((error) => {
            this.showError("danger","Unable to connect with backend !");
        });
    }

    onSelectReview = (reviewId) => {
        let current = {};
        let setId  = "";
        this.state.submissions.map((sub, index) => {
            if(sub.reviewId === reviewId){
                current = sub;
                setId = sub.questionSetId;
            }
        });

        this.setState({
            ...this.state,
            currentReviewAnswers : current
        });
        this.getQuestionSetBySetId(setId);
    }

    getReviews = () => {
        axios.get(constants.endpoint+constants.userPreviousReviews)
        .then((response) => {
            if(response.data.submission.length !== 0){
                this.setState({
                    ...this.state,
                    submissions : response.data.submission
                });
                this.reviewSubmissionByYear(response.data.submission[response.data.submission.length-1].year);
            }else{
                // console.log("else");
                // this.showError("warning","No previous review exists !");
            }

        })
        .catch((error) => {
            this.showError("danger","Unable to connect with backend !");
        });
    }

    reviewSubmissionByYear=(year)=>{
        axios.get(constants.endpoint+constants.userPreviousReviewsByYear+"?year="+year)
        .then((response) => {
                this.setState({
                    ...this.state,
                    submissionByYear : response.data
                });
                this.getCummulative(year);
           
        })
        .catch((error) => {
            this.showError("danger","Unable to connect with backend !");
        });
    }

    getCummulative=(year)=>{
        var rev = this.state.submissionByYear.userSubmission;
        var sum = 0;
        for(var i=0;i <rev.length;i++){
            sum = sum + rev[i].finalRating
        }
        var avg = sum/rev.length;
        this.setState({
            cumulativeScoreYear : year,
            cumulativeScore : avg
        }) 
    }

    componentDidMount(){
        this.getReviews();

    }

    resetAll = () => {
        this.setState({
            ...this.state,
            preview : false
        });
    }
    showError = (type, message) => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML  = (`
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `);
      }

    render() {
        return (
            <React.Fragment>
            <Header />
            <div className="content content-fixed">
                <div className="container">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item">Dashboard</li>
                            <li className="breadcrumb-item active" aria-current="page">My Previous Reviews</li>
                        </ol>
                        <h4 className="mg-b-0 tx-spacing--1">Welcome {localStorage.name}</h4>
                        </nav>
                    </div>
                    <div className="d-none d-md-block">
                          <a href="/selfReview"  className="nav-link" target="_self"><i class="fas fa-arrow-left"></i> &nbsp;Back to self review</a>
                    </div>
                    </div>

                    <div className="row">
                        <div id="errorPlaceholder" className="col-12">
                            
                        </div>
                    </div>

                    <div className="row" style={{display:  this.state.preview ? "none" : ""}}>
                        <div className="col-lg-12 col-xl-12 mg-t-10" style={{display : this.state.submissions.length != 0 ? "none" : ""}}>
                            <div className="card card-body shadow-none text-center">
                                <i style={{fontSize:'64px'}} class="mt-5 fas fa-chalkboard-teacher fa-lg"></i>
                                <span className="mt-5 tx-uppercase tx-semibold mg-b-0">Sorry ! No previous reviews found.</span>
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{display:  this.state.preview ? "none" : ""}}>
                        <div className="col-lg-12 col-xl-12 mg-t-10" style={{display : this.state.submissions.length === 0 ? "none" : ""}}>
                            <div className="card card-body shadow-none">
                                    <div className="marker marker-ribbon pos-absolute t-10 l-0">Existing Reviews</div>
                                    <div className="row" style={{marginTop:'32px'}}>
                                        {
                                            this.state.submissions.map((sub, sIndex) => (
                                                <div key={sIndex} className="col-4">
                                                    <div className="card card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Review Year</h6>
                                                                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                                                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{sub.year} | {sub.quarter}</h3>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2" style={{margin:'Auto 0px', position :'relative', top:'10px'}}>
                                                                        <button onClick={(e) => this.onSelectReview(sub.reviewId)} type="button" className="btn btn-xs btn-primary">View</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12" style={{marginTop:'20px'}}>
                                                            <div className="row">
                                                                    <div className="col-4">
                                                                        <h5 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Rating</h5>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <code>{sub.finalRating}</code>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Comments</h5>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <code>{sub.finalComments}</code>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Status</h5>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <code>Completed</code>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '20px', display : this.state.submissions.length === 0 ? "none" : ""}}>
                        <div className="col-sm-6 col-lg-12">
                            <div className="card card-body">
                                <div className="row">
                                    <div className="col-3">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Current Year</h6>
                                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.cumulativeScoreYear}</h3>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Cummulative Score</h6>
                                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.cumulativeScore}</h3>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                    </div>
                    
                    
                    <div className="row" style={{position : 'relative', display : this.state.preview ? "" : "none"}}>
                       <span onClick={this.resetAll} style={{position : 'absolute', top: '-10px', right:'20px', cursor : 'pointer'}} className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"> Show Reviews </span>
                    </div>
                        
                    <div className="row" style={{padding:'15px', display : this.state.preview ? "" : "none"}}>

                          <div className="col-lg-12 col-xl-12 mg-t-10" style={{padding:'0px'}}>
                              <div className="card" style={{padding:'25px'}}>

                                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Review Areas</h6>

                                  <ul className="steps">
                                    {
                                      this.state.preview && this.state.currentQuestionSet.map((area,index) => (
                                        <li key={index} className="step-item">
                                          <a  className="step-link">
                                          <span className="step-number">{index+1}</span>
                                          <span className="step-title">{area.name}</span>
                                          </a>
                                      </li>
                                      ))
                                    }
                                  
                                  </ul>
                              </div>
                          </div>
                          
                        
                        {
                          this.state.preview && this.state.currentQuestionSet.map((area,aIndex) => (
                            <div key={aIndex} className="col-lg-12 col-xl-12 mg-t-10" style={{padding:'0px'}}>
                              <div className="card">
    
                                <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                                <h6 className="tx-uppercase tx-15 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">{area.name}</h6>
                                </div>
    
                                <div className="card-body pd-10">
                                    {
                                      area.questions.map((question, qIndex) => (
                                        <React.Fragment key={qIndex}>
                                          <div className="row pd-20">
                                            <div className="col-12">
                                                <p>
                                                  <code>{question.questionId.split("-")[2]}</code> &nbsp;
                                                  {question.text}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Self</span>
                                                <select
                                                 disabled={this.state.currentReviewAnswers.isPublished ==="true" ? "disabled" : null}
                                                  value={this.state.currentReviewAnswers.area[aIndex].questions[qIndex].selfSelection} 
                                                  onChange={(e) => this.handleInput(e,aIndex,qIndex,"selfSelection")} className="custom-select">
                                                    <option >Select self rating</option>
                                                    {
                                                      question.possibleAnswer.map((pA, pIndex) => (
                                                        <option key={pIndex} value={pA.value}>{pA.value}</option>
                                                      ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-6">
                                            <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Examples/Justifications of your answer </span>
                                                <textarea 
                                                  disabled={this.state.currentReviewAnswers.isPublished ==="true" ? "disabled" : null}
                                                  value={this.state.currentReviewAnswers.area[aIndex].questions[qIndex].selfExplain} 
                                                  onChange={(e) => this.handleInput(e,aIndex,qIndex,"selfExplain")} className="form-control" rows="1" placeholder="Textarea"></textarea>
                                            </div>

                                            <div className="col-6" style={{marginTop:"10px", display : this.state.currentReviewAnswers.isPublished ==="true" ? "block" : "none"}}>
                                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Selection</span>
                                                <select
                                                  value={this.state.currentReviewAnswers.area[aIndex].questions[qIndex].managerSelection} 
                                                  disabled className="custom-select">
                                                    <option >Select rating</option>
                                                    {
                                                      question.possibleAnswer.map((pA, pIndex) => (
                                                        <option key={pIndex} value={pA.value}>{pA.value}</option>
                                                      ))
                                                    }
                                                </select>
                                            </div>
                                            
                                            <div className="col-6" style={{marginTop:"10px",display : this.state.currentReviewAnswers.isPublished ==="true" ? "block" : "none"}}>
                                            <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Comments </span>
                                                <textarea 
                                                  value={this.state.currentReviewAnswers.area[aIndex].questions[qIndex].managerExplain} 
                                                  disabled className="form-control" rows="1" placeholder="Manager comments"></textarea>
                                            </div>
                                            
                                            <div className="col-12" style={{marginTop:'15px', display : this.state.currentReviewAnswers.isPublished ==="true" ? "block" : "none"}}>
                                                <div className="row">
                                                    <div className="offset-6 col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Self Rating : </span>
                                                      <code> {this.state.currentReviewAnswers.area[aIndex].questions[qIndex].selfRating} </code>
                                                    </div>
                                                    <div className="col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Rating : </span>
                                                      <code> {this.state.currentReviewAnswers.area[aIndex].questions[qIndex].managerRating} </code>
                                                    </div>

                                                    <div className="col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Final Rating : </span>
                                                      <code> {this.state.currentReviewAnswers.area[aIndex].questions[qIndex].finalRating} </code>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="mg-y-10" />
                                            <div className="col-12" style={{marginBottom : '5px',marginTop:'15px', display: area.questions.length-1 === qIndex ? "block" : "none"}}>
                                                <div className="row" style={{display : this.state.currentReviewAnswers.isPublished ==="true" ? "" : "none"}}>
                                                    <div className="offset-6 col-4">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Comments : </span>
                                                      <code> {this.state.currentReviewAnswers.area[aIndex].areaComment || "NA"} </code>
                                                    </div>
                                                    <div className="col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Rating : </span>
                                                      <code> {this.state.currentReviewAnswers.area[aIndex].areatotal} </code>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                      ))
                                    }
                                </div>
                              </div>
                            </div>
                          ))
                        }

                      </div>


                      <div className="row" style={{position:'relative', marginBottom:'20px', display : this.state.preview ? "" : "none"}}>
                        <div className="offset-4 col-3">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Perofrmance Review : </span>
                          <code> {this.state.currentReviewAnswers.reviewId || "NA"} </code>
                        </div>
                        <div className="col-3">
                        <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Comments : </span>
                          <code> {this.state.currentReviewAnswers.finalComments || "NA"} </code>
                        </div>
                        <div className="col-2">
                          <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Rating : </span>
                          <code> {this.state.currentReviewAnswers.finalRating} </code>
                        </div>
                      </div>
            
                </div>
            </div>

           
        </React.Fragment>
        );
    }
}

export default previousReviews;