import React, { Component, useRef } from 'react';
import Header from './../header/header';
import Footer from './../footer/footer';
import axios from 'axios';
import * as constants from './../../constants';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';
import DashboardTeamRatingChart from './../managerdashFirstChart/dashboardTeamRatingChart'
import TeamRatingByAreaChart from './../managerDashboard/teamRatingByAreaChart'
import TeamRatingByRoleChart from './../managerDashboard/teamRatingByRoleChart'
import DashboardYearAndQuarterBind from './../managerDashboard/dashboardYearAndQuarterBind'
import TeamRatingByAreaLineChart from './../managerDashboard/teamRatingByAreaLineChart'

class managerDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.teamRating = React.createRef();
        this.ratingByArea = React.createRef();
        this.ratingByRole = React.createRef();
    };


    state = {
        user: {
            role: '',
            manager: {
                name: ''
            }
        },
        finalRating: 0,
        finalComment: '',
        reviewId: '',
        temaRatingNotFound: "false",
        teamRatingByAreaNotFound: false,
        teamRatingByRoleNotFound: false,
        activityRole: ''
    }


    componentWillMount() {
        if (typeof localStorage.token === "undefined") {
            window.location.href = "/?message=Session+Expired";
        }

        if (localStorage.activityRole === "User") {
            window.location.href = "/?message=Unauthorized+Access+Or+Session+Expired";
        }
        this.getUser();
        this.getRoles();
        this.getTeamReatingDistribution("", "");
    }


    getCompleteRatings = () => {


        let year = this.state.selectedYear;
        let quarter = this.state.selectedQuarter;

        this.getTeamReatingDistribution(year, quarter);
        this.getTeamReatingDistributionByArea(year, quarter);
        this.getTeamReatingDistributionByRole(year, quarter);
    }

    getTeamReatingDistribution = (year, quarter) => {

        let url = constants.teamRatingDistribution;
        if (year != "" && quarter != "") {
            url = url + "?year=" + year + "&quarter=" + quarter
        }
        axios.get(constants.endpoint + url)
            .then((response) => {

                
                const maxRating = 5;
                if (response.data.teamRatingDistribution.ratings.length > 0) {

                    this.setState({
                        ...this.state,
                        temaRatingNotFound: "false",
                        finalRating: response.data.teamRatingDistribution.finalRating,
                        finalComment: response.data.teamRatingDistribution.finalComment,
                        reviewId: response.data.teamRatingDistribution.reviewId,
                        activityRole: localStorage.activityRole,
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        temaRatingNotFound: "true",
                        activityRole: localStorage.activityRole,
                    })
                }

            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    temaRatingNotFound: "true",
                })
                console.log(error);
            });
    }


    getUser = () => {
        axios.get(constants.endpoint + constants.userDetails)
            .then((response) => {
                this.setState({
                    ...this.state,
                    user: response.data.user
                })
            }).catch((error) => {
                this.showError("danger", "Something unexpected happened !");
            }).then(() => {
                this.getTaggedAssociates();
                this.getCurrentReview();
            });
    }

    getRoles = () => {
        axios.get(constants.endpoint + constants.role + "?organisation=" + localStorage.organisation)
            .then((response) => {
                if (response.data.roles.length !== 0) {
                    this.setState({
                        ...this.state,
                        roles: response.data.roles
                    })
                }

            })
            .catch((error) => {
                //this.showError("danger", "Unable to fetch roles !")
            });
    }

    getTaggedAssociates = () => {
        axios.get(constants.endpoint + constants.managerTagged)
            .then((response) => {
                this.setState({
                    ...this.state,
                    managerFor: response.data.users
                })
            }).catch((error) => {
                //this.showError("danger", "Something unexpected happened !");
            }).then(() => {
                this.getCurrentReview();
            });
    }

    getCurrentReview = () => {
        axios.get(constants.endpoint + constants.getCurrentReview + "?organisation=" + localStorage.organisation)
            .then((response) => {
                if (response.data.review !== null) {
                    this.setState({
                        ...this.state,
                        review: response.data.review
                    });
                } else {
                    // this.showError("warning", "No active review scheduled at the moment!");
                }
            }).catch((error) => {
                //this.showError("danger", "Something unexpected happened !");
            });
    }


    bindAllGraphsByYearAndQuarter = (year, quarter) => {
        

        let url = constants.teamRatingDistribution;
        if (year != "" && quarter != "") {
            url = url + "?year=" + year + "&quarter=" + quarter
        }
        axios.get(constants.endpoint + url)
            .then((response) => {
                
                if (response.data.teamRatingDistribution.ratings.length > 0) {
                    this.setState({
                        ...this.state,
                        temaRatingNotFound: "false",
                        finalRating: response.data.teamRatingDistribution.finalRating,
                        finalComment: response.data.teamRatingDistribution.finalComment,
                        reviewId: response.data.teamRatingDistribution.reviewId,
                    })
                    this.teamRating.current.getTeamReatingDistributionOtherPage(year, quarter);
                    this.ratingByArea.current.getTeamReatingDistributionByArea(year, quarter, "true");
                    this.ratingByRole.current.getTeamReatingDistributionByRole(year, quarter, "true");
                }
                else {
                    this.setState({
                        ...this.state,
                        temaRatingNotFound: "true"
                    })
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (

            <React.Fragment>
        
                <div className="content content-fixed">
                    <div className="container col-12">

                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active">Dashboard</li>
                                
                            </ol>
                            </nav>
                        </div>
                        </div>

                        <div className="row" style={{marginTop: "-30px"}}>
                            <div className="col-sm-6 col-lg-12">
                                <div className="card card-body">
                                    <div className="content content-fixed" style={{ marginTop: 'auto' }}>
                                        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0" >

                                        <div className="text-center">
                                            <i style={{fontSize:'64px'}} class="mt-5 fas fa-chalkboard-teacher fa-lg"></i>
                                            <span className="mt-5 tx-uppercase tx-semibold mg-b-0 d-block">Sorry ! No completed reviews in system.</span>
                                        </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:this.state.activityRole != "Admin" || this.state.activityRole != "Owner"? "none" : "inline-block"}}>
                    <Footer />
                </div>
                
            </React.Fragment>

        );
    }
}




export default managerDashboard;

