import React, { Component, useRef } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import axios from 'axios';
import * as constants from '../../constants';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';
import DashboardTeamRatingChart from '../managerdashFirstChart/dashboardTeamRatingChart'


class teamRatingByAreaLineChart extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        user: {
            role: '',
            manager: {
                name: ''
            }
        },
        backgroundColor: ['#2196F3', '#AB47BC', '#EC407A', '#EF5350', '#0288D1', '#43A047', '#F57C00', '#E64A19', '#8D6E63', '#607D8B'],
        hoverBackgroundColor: ['#0D47A1', '#7B1FA2', '#C2185B', '#E53935', '#01579B', '#1B5E20', '#E65100', '#BF360C', '#6D4C41', '#37474F'],
        roles: [],
        levels: [],

        current: {
            active: 0
        },
        areaList: [],
        ratingByAreaArray: [],
        fetchLength:0,
        data: {
            labels: ["Q1", "Q2", "Q3", "Q4"],
            datasets: [
                {
                    label: "Ownnership Bios",
                    data: [1, 3, 2, 5],
                    fill: false,
                    borderColor: "#2196F3"
                },
                {
                    label: "Insists standards",
                    data: [3, 4, 1, 2],
                    fill: false,
                    borderColor: "#742774"
                },
                {
                    label: "Independence",
                    data: [2, 3, 5, 4],
                    fill: false,
                    borderColor: "#AB47BC"
                },
                {
                    label: "Test",
                    data: [3, 1, 4, 3],
                    fill: false,
                    borderColor: "#AB47BC"
                }
            ]
        },
        // data: {
        //     labels: [],
        //     datasets: [
        //         {
        //             label: "",
        //             data: [],
        //             fill: false,
        //             borderColor: ""
        //         }
        //     ]
        // },
        lastClicableButtonId: '',
        temaRatingNotFound: "false",
        teamRatingByAreaNotFound: false,
        teamRatingByRoleNotFound: false,

    }


    componentWillMount() {
        if (typeof localStorage.token === "undefined") {
            window.location.href = "/?message=Session+Expired";
        }

        if (localStorage.activityRole === "User") {
            window.location.href = "/?message=Unauthorized+Access+Or+Session+Expired";
        }
        this.getTeamReatingDistributionByArea("", "");
    }

    getCompleteRatings = () => {


        let year = this.state.selectedYear;
        let quarter = this.state.selectedQuarter;

        this.getTeamReatingDistributionByArea(year, quarter);
    }



    areaRating = (area, byClick) => {
        
        console.log(area);
        let id = "btnId" + area.index;
        document.getElementById(id).style.backgroundColor = "#0168fa";
        if (byClick != "true") {
            if (this.state.lastClicableButtonId != "")
                document.getElementById(this.state.lastClicableButtonId).style.backgroundColor = "#7987a1";
            else
                document.getElementById("btnId" + area.index).style.backgroundColor = "#0168fa";
        }
        
        // let rating = this.state.ratingByAreaArray[area.index];
        // let labelsList = [];
        // let dataList = [];
        // let bgColor = [];
        // let howerColor = [];
        // for (var j = 0; j < rating.length; j++) {
        //     labelsList[j] = rating[j].name;
        //     dataList[j] = rating[j].value;
        //     bgColor[j] = this.state.backgroundColor[j];
        //     howerColor[j] = this.state.hoverBackgroundColor[j];
        // }

        
        let rating = this.state.ratingByAreaArray;
        let quartesLabel=[];
        let dataLabel=[];
        let data=[];

        quartesLabel[0]="Q1";
        quartesLabel[1]="Q2"; 
        quartesLabel[2]="Q3";
        quartesLabel[3]="Q4";

        let len=rating.length;
        let count=0;
        while(len>0){
            let ratingData=[];
            let label;
            let bgColor;
            for (var i = 0; i < rating.length; i++){
                ratingData[i]=rating[i][count].ratings[0].value;
            }
            bgColor = this.state.backgroundColor[count];
            label = "area"+count;

            // this.setState({
            //     ...this.state,
            //     lastClicableButtonId: id,
            //     data: {
            //         labels: quartesLabel,
            //         datasets: [{
            //             label: label,
            //             data: ratingData,
            //             borderColor: bgColor,
            //             fill:false
            //         }]
            //     }
            // })
            count++;
            len--;
        }
    }

    getTeamReatingDistributionByArea = (year, quarter, byClick) => {
        
        let url = constants.teamRatingDistributionByAreaAllQuarter+ "?year=" + "2021-2022";
        if (year != "" && quarter != "") {
            url = url + "?year=" + year + "&quarter=" + quarter
        }
        axios.get(constants.endpoint + url)
            .then((response) => {
                let areaLst = [];
                let ratingList = [];
                
                if (response.data != null || response.data != '') {
                    for (var i = 0; i < response.data[0].length; i++) {
                        areaLst[i]=response.data[0][i].area;
                    }
                    for(var i = 0; i < response.data.length; i++) {
                        // for(var j=0;j<response.data[i].length;j++){
                        //     ratingList[i] = response.data[i][j].ratings;
                        // }
                        ratingList[i] = response.data[i];
                    }
                    this.setState({
                        ...this.state,
                        areaList: areaLst,
                        ratingByAreaArray: ratingList,
                        fetchLength: response.data[0].length
                    })
                    let area = { index: 0 };
                    this.areaRating(area, byClick);
                }
                else {
                    this.setState({
                        ...this.state,
                        teamRatingByAreaNotFound: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (

            <React.Fragment>



                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0" style={{ marginTop: '-56px' }}>

                    <div className="row" >
                        <div className="col-sm-6 col-lg-12">
                            <div className="card card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>Team Rating Distribution By Area</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            this.state.areaList.map((area, index) => (
                                                <button id={"btnId" + index} onClick={() => this.areaRating({ index })} type="button" class="btn btn-secondary btn-xs" style={{ margin: '3px' }}>{area}</button>
                                            ))
                                        }


                                    </div>
                                </div>

                                <div className="row">


                                    <div id="divTeamRating" className="col-12">

                                        <Line data={this.state.data} />
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>


                    <div>

                    </div>
                </div>








            </React.Fragment>

        );
    }
}




export default teamRatingByAreaLineChart;

