import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import axios from "axios";
import * as constants from "../../constants";
import '../../assests/css/privacypolicy.css';

class privacypolicy extends Component {

    render() {
        return (
            <React.Fragment>
                {/* <Header /> */}

                <header className="navbar navbar-header navbar-header-fixed ">
                <a href="#" id="mainMenuOpen" className="burger-menu"><i data-feather="menu"></i></a>
                <div className="navbar-brand">
                    <a href="/" title="Home" target="_self"> GrowthMonitor
                        <img width="175px" src="" alt="" />
                    </a>
                </div>

                <div className="navbar-right">
                {/* <a href="/" title="Home" target="_self"> Home / Privacy Policy
                        <img width="175px" src="" alt="" />
                    </a> */}
                </div>
                
            </header>
            <div role="main" className="mainDivBg">
	            <div class="container">
		            <div class="row">
			            <div class="col-12">
				            <nav class="navbar navbar-expand-lg navbar-light justify-content-start">
					        <div class="navbar navbar-expand-lg">
						    <div>
							<ul class="navbar-nav d-block">
								<li class="nav-item d-inline-block"> <a class="nav-link text-capitalize" href="./">Home</a> </li>
								<li class="nav-item d-inline-block"> / </li>
								<li class="nav-item d-inline-block"> <a class="nav-link bcrumb text-capitalize" href="./privacy-policy">Privacy Policy</a> </li>
							</ul>
						</div>
					    </div>
				        </nav>
			        </div>
		        </div>
	        </div>
            </div>

            <div className="content  content-auth" className="background">
	<div className="container">
		<div className="media align-items-stretch justify-content-center ht-100p pos-relative">
			<div className="media-body align-items-center d-none d-lg-flex">
				<main role="main" class="container mt-5">
					<div class="row m-auto">
						<h4 class="mb-3 ml-2 voo-underline heading">Privacy Policy</h4>
						<div class="col-12 mt-3">
							<div class="col-12"> <span>
                                Growth Monitor ("GM," "we," "our," or "us") is committed to respecting and protecting your privacy. This Privacy Policy applies to the Growth Monitor website, https://growthmonitor.app/ (hereinafter referred to as “Growth Monitor”) and describes how we collect, protect, use and share information gathered about you on our websites or mobile service. By using our Service, you agree to the terms of this Privacy Policy.
                                </span></div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">TYPE OF INFORMATION COLLECTED</h5>
								<ul>
                                    <li>When you visit the Website, we may collect certain non-personal information such as your Internet Protocol ("IP") address, operating system, browser type, and Internet service provider. This type of information does not identify you personally.</li>
                                    <li>When you register with the Website or respond to advertisements or posts on the Website or post contents on the Website without registering, we may also collect personal information that you provide such as your name, mailing address, email address, phone/mobile number, home country, and zip code ("Registration Information").</li>
                                    <li>You have the option of also providing to us your fax numbers, gender, age, photograph, additional phone numbers / email IDs and other details if you choose to register with Growth Monitor.</li>
                                    <li>We may also collect additional information such appliances searched for or items browsed frequently or new or deleted comments, keyword searches, scam reports and new contact sellers</li>
                                    <li>We may use a third-party credit card processing company, Wallet Company or other online payment processing service providers for purchases, and other third party companies to monitor site traffic, which may, in some instances, store your information.</li>
                                    <li>By using this Website or the Service, you consent to collection, storage, and use of the personal information you provide for any of the services that we offer, and you consent to our collection of any changes or updates that you may provide to any information you provide that is collected by Growth Monitor.</li>
                                    <li>We may combine your Personal Information, other information and information collected from tracking technologies and aggregate it with information collected from other Users using our Website to attempt to provide you with a better experience on our Website.</li>
                                    <li>You understand, agree and acknowledge that our collection, storage and processing of your Personal Information is for a lawful purpose connected with a function or activity of Growth Monitor.</li>
                                    <li>You further understand, agree and acknowledge that your Personal Information which is classified as sensitive personal information as per applicable laws is considered necessary for Growth Monitor to provide various services on its Website to you and for your usage of the Website and other services provided by Growth Monitor in relation to the Website.</li>
                                </ul>
							</div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">COOKIES</h5>
								<ul>
                                    <li>We may use cookies to manage our users’ sessions and to store preferences, tracking information, and language selection. Cookies may be used whether you register with us or not. "Cookies" are small text files transferred by a web server to your hard drive and thereafter stored on your computer.</li>
                                    <li>The types of information a cookie collects include the date and time you visited, your browsing history, your preferences, and your username /password. In some instances, our third-party service providers may use cookies on the Website. We cannot control or access cookies used by third- party service providers.</li>
                                    <li>This Privacy Policy covers only Cookies used by us, and not any cookies used by third parties. You have the ability to either accept or decline the use of cookies on your computer, whether you are registered with us or not.</li>
                                    <li>Typically, you can configure your browser to not accept cookies. However, declining the use of cookies may limit your access to certain features of the Website.</li>
								</ul>
							</div>
                            <div class="entry-content mt-3">
								<h5 class="font-weight-bold">THIRD PARTIES</h5>
								<ul>
                                    <li>We may use third-party service providers to assist us in measuring purchases and sales on our Website and generally improving our Website and to monitor our users’ interests and activities. You hereby authorize Growth Monitor and/or third party service providers engaged by Growth Monitor to collect, use, store, analyse, reproduce, publish, and adapt (either on its own or through third party service provider) the information in relation to your use of the Website for the purpose of data analysis and for improving your experience on the Website. In addition, the Website may occasionally contain links to Third-Party Sites or provide you information in relation to services that you may avail from any third parties. Information about services that you may choose to avail from these third party service providers may be actively provided to you by Growth Monitor in any manner including through its various marketing and communication channels.</li>
                                    <li>You acknowledge that this is solely undertaken by Growth Monitor to improve your experience in relation to the use of the Website and the provision of such services shall be subject to such additional terms and conditions of Growth Monitor and/or third party service providers. Growth Monitor shall not be responsible for any service availed by you from third parties or any payment made by you to such third parties in connection with the services provided by Growth Monitor. Any concern or claims in relation to such services should be directed by you to such third parties.</li>
                                    <li>If you click on the links to Third-Party Websites, you leave the Website. We are not responsible for the content of these Third-Party Websites or for the security of your personal information when you use the Third Party Websites. These third-party service providers and Third-Party Sites may have their own privacy policies governing the storage and retention of your personal information that you may be subject to. They may collect information such as your IP address, browser specification, or operating system.</li>
                                    <li>This Privacy Policy does not govern personal information provided to, stored on, or used by these third- party providers and Third-Party Sites. We recommend that when you enter a Third-Party Site, you review the Third Party Site’s privacy policy as it relates to safeguarding your personal information. We may use third-party advertising companies to serve ads when you visit the Website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to the Website and Third-Party Websites in order to provide advertisements about goods and services of interest to you. By publishing an ad on the Website, you acknowledge and agree that the Content is public and accessible by any third party and that they may appear in search engine results (such as Yahoo!, MSN, Google, and other search engines) and in the cache of those search engines, in feeds and Third-Party Websites pursuant to co-branding agreements, and that it is the sole responsibility of each of those search engines, Third-Party Websites or RSS web feed resources to update and/or to remove Content from their indexes and their cache. You agree and acknowledge that Growth Monitor is not liable for the information published in search results or by any Third-Party Website that carries Growth Monitor’s postings.</li>
                                </ul>
							</div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">HOW YOUR INFORMATION IS USED?</h5>
								<ul>
                                    <li>We may use information provided by you to enforce our Terms of Use, monitor user activity such as keyword searches or new postings, and more effectively manage traffic on the Website</li>
                                    <li>Provide customer services, create and manage user accounts assist you with technical difficulties. Also, we may share with third-party service providers certain information, such as your browser capabilities or operating system, that we have collected in order to better understand which advertisements and services may interest you. We may block users located in certain countries from using the Website.</li>
                                    <li>We may retain such information for as long as is required to fulfil our business objective, even after your account is terminated.</li>
								</ul>	
                                    {/* <span>We may retain such information for as long as is required to fulfil our business objective, even after your account is terminated.</span> */}
                            </div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">PROTECTING YOUR PERSONAL INFORMATION</h5>
								<ul>
                                    <li>You are a valued customer, and we recognize that protecting your privacy is important to you. For this reason, we are committed to protecting the personal information you provide in a variety of ways. We do not directly process any payments and do not store your credit card or eWallet information. Secured Socket Layer ("SSL") technology may also be used for processing payment transactions with third-party service provider partners, information regarding this shall be updated when necessary. Your Registration Information may be protected by a unique customer password and user ID.</li>
                                    <li>You should not disclose your password information to anyone and you should always remember to log off if using a shared computer. Lastly, you may utilize the Website as an anonymous user by not registering. We have taken certain security protections in safeguarding your personal information. However, as with most electronic transactions, no method is 100% safe. While we strive to use a commercially acceptable means to protect the personal information you provide, we cannot guarantee its security.</li>
                                    <li>Therefore, you acknowledge and agree that we assume no liability regarding the theft, loss, alteration or misuse of personal or other information or content, including, without limitation, such information that has been provided to third parties or other users, or with regards to the failure of a third party to abide by the agreement between us and such third party.</li>
                                    <li>You agree that you are responsible for maintaining the confidentiality of your username and password, and all uses of your account, whether or not you have authorized such use.</li>
								</ul>
							</div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">ACCESSING AND MODIFYING PERSONAL INFORMATION</h5>
								<ul>
                                    <li>You may access, remove, review, and/or make changes to personal information that you have provided to us. It is advisable to register with Website in order to better avail its services.</li>
                                    <li>If you register we may send you certain notifications, advertisements, promotions, surveys, text messages, specials and phone calls in relation to the Services. You hereby unconditionally allow us and our service providers to contact you via text messages or by phone calls or emails and agree that you shall have no claim against Growth Monitor or its service providers under any applicable law.</li>
                                    <li>We may also send you any legally required notifications and certain notifications, including but not limited to, service related notices or notices regarding a change to any of our policies.</li>
                                    <li>For example, we may send you a notice regarding server problems or scheduled maintenance to the Website. In order to opt-out of receiving these notices, you may need to deactivate your account. We will not change your preferences without your consent. You cannot opt out of receiving transactional communications from Growth Monitor related to your account.</li>
								</ul>
							</div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">DISCLOSURES</h5>
								<ul>
									<li>On rare occasions, we may be required to disclose your personal information due to legal or regulatory requirements. In such instances, we reserve the right to disclose your personal information as required in order to comply with our legal obligations, including but not limited to complying with court orders, warrants, subpoenas, service of process requirements, or discovery requests.</li>
									<li>We may also disclose information about our users to law enforcement officers or others, in the good faith belief that such disclosure is reasonably necessary to enforce these Terms and/or Privacy Policy respond to claims that any Content violates the rights of third-parties or protect the rights, property, or personal safety of GrowthMonitor, our users or the general public.</li>
									<li>You agree and acknowledge that we may not inform you prior to or after disclosures made according to this section. If substantially all of our assets are sold or merged into another company, the acquiring company shall be given access to your personal information without your consent. If we sell only a part of our business, the acquiring entity shall have access to your personal information without your consent and we may send you a notice regarding this sale.</li>
								</ul>
							</div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">NOTIFICATION OF CHANGES</h5>
								<ul>
                                    <li>We reserve the right to change these Terms from time to time, and at our sole discretion. We may send you a notice regarding material changes to these Terms, including the Privacy Policy or the Website.</li>
                                    <li>You should make note of the "last updated date" at the end of the Terms. With this information, you should be able to identify if the Terms have been updated recently.</li>
                                    <li>Any changes brought about or introduced to the Terms shall be effective within 5 days of introduction or shall be effective from the date it has been notified to you via email or shall be effective from the date it has been updated with a specific date on the website, whichever is earlier.</li>    
                                </ul>
							</div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">DISPUTES</h5><span>Any disputes regarding your privacy are subject to the Terms, including but not limited to any provisions related to indemnification, limitations on damages, and choice of law and forum.</span></div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">HOW TO CONTACT US</h5>
								<ul>
                                    <li>If you have questions about this Privacy Policy, please contact Growth Monitor via an email to support@growthmonitor.com "Privacy Policy" in the subject line.</li>
                                    <li>This Privacy policy is subject to the clauses of the Terms of Use (Terms) of Growth Monitor. This policy is effective from the date and time a user visits Growth Monitor website, irrespective of whether the user registers with Growth Monitor by filling up the Registration form and accepting the terms and conditions laid out in the Site Policy. In order to provide a personalised browsing experience, Growth Monitor may collect personal information from you. Additionally some of our website’s pages may require you to complete a registration form or seek some information from you. When you let us have your preferences, we will be able to deliver or allow you to access the most relevant information that meets your end.</li>
                                    <li>To extend this personalized experience Growth Monitor may track the IP address of a user's computer and save certain information on your system in the form of cookies. A user has the option of accepting or declining the cookies of this website by changing the settings of your browser. The personal information provided by the users to Growth Monitor will not be provided to third parties without previous consent of the user concerned. Information of a general nature may however be revealed to external parties</li>
                                    <li>Every effort will be made to keep the information provided by users in a safe manner, the information will be displayed on the website will be done so only after obtaining consent from the users. Any user browsing the site generally is not required to disclose his identity or provide any information about him/her, it is only at the time of registration or filling up any of the forms to contact Growth Monitor you will be required to furnish the details in the form. Growth Monitor fully complies with all Indian Laws applicable. Growth Monitor has always cooperated with all law enforcement inquires. Growth Monitor may disclose all or part of your personal details in response to a request from the law enforcement authorities or in a case of bonafide requirement to prevent an imminent breach of the law.</li>
								</ul>
							</div>
							<div class="entry-content mt-3">
								<h5 class="font-weight-bold">CHANGES TO PRIVACY POLICY</h5>
								<ul>
                                    <li>By using our Service, you consent to the collection, use and storage of your information by us in the manner described in this Privacy Policy. We reserve the right to make changes to this Privacy Policy from time to time.</li>
                                    <li>If this Privacy Policy changes in any way, we will place an updated version on this page. Regularly reviewing this page ensures that you are always aware of what information.</li>
								</ul>
							</div>
							<div class="entry-content mt-3"><span class="d-block">Your Comments are well appreciated. If you have any questions or comments regarding our privacy policy or our website, you can send us an email at support@GrowthMonitor.com</span><span class="d-block mt-2">This Privacy Policy was last updated on 18-05-2021.</span></div>
						</div>
					</div>
				</main>
			</div>
		</div>
	</div>
	<meta charset="utf-8" />
	<link rel="icon" href="/favicon.ico" />
	<meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
	<meta name="theme-color" content="#000000" />
	<meta name="description" content="GrowthMonitor" />
	<meta name="mobile-web-app-capable" content="yes" />
	<meta name="apple-mobile-web-app-capable" content="yes" />
	<meta name="application-name" content="GrowthMonitor Pvt Ltd." />
	<meta name="apple-mobile-web-app-title" content="GrowthMonitor Pvt Ltd." />
	<meta name="theme-color" content="#01497f" />
	<meta name="msapplication-navbutton-color" content="#01497f" />
	<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
	<meta name="msapplication-starturl" content="https://GrowthMonitor.com" />
	<meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
	<link rel="icon" type="image/x-icon" href="http://GrowthMonitor.com/favicon.ico" />
	<link rel="apple-touch-icon" type="image/x-icon" href="http://GrowthMonitor.com/favicon.ico" />
	<link rel="apple-touch-icon" href="/logo192.png" />
	<meta base="/" />
	<link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300&amp;display=swap" rel="stylesheet" />
	<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />
	<link rel="manifest" href="/manifest.json" />
	<script type="text/javascript" async="" src="https://www.google-analytics.com/analytics.js"></script>
	<script type="text/javascript" async="" src="https://www.googletagmanager.com/gtag/js?id=UA-125546749-1&amp;l=dataLayer&amp;cx=c"></script>
	<script type="text/javascript" async="" src="https://www.googleadservices.com/pagead/conversion_async.js"></script>
	<script src="https://connect.facebook.net/signals/config/1073755649722189?v=2.9.39&amp;r=stable" async=""></script>
</div>
                <Footer />
            </React.Fragment>
        );
    }
};

export default privacypolicy;
