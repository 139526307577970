import React, { Component } from 'react';
import './../../assests/css/dashforge.css';
import './../../assests/css/dashforge.auth.css';
import Header from './../header/header';
import Footer from './../footer/footer';
import axios from 'axios';
import * as constants from './../../constants';

import { handleAlert } from './../alertHandler/alertHandler';

class login extends Component {

  state = {
    email: '',
    password: '',
    organisation: ''
  }

  inputHandler = (e, field) => {
    let tmpState = this.state;
    tmpState[field] = e.target.value;
    this.setState(tmpState);
  }

  login = () => {
    axios.post(constants.endpoint + constants.signIn, {
      ...this.state
    })
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("cvtid", response.data.user.cvtid);
          localStorage.setItem("role", response.data.user.role);
          localStorage.setItem("activityRole", response.data.user.activityRole);
          localStorage.setItem("name", response.data.user.name);
          localStorage.setItem("organisation", response.data.user.organisation);  

          if (response.data.user.activityRole == "User") {
            window.location.href = "/selfReview";
          }
          else if(response.data.user.activityRole == "Manager"){
            window.location.href = "/selfReview";
          } else {
            window.location.href = "/admin";
            //window.location.href = "/manager/dashboard";
          }
        }
      }).catch((error) => {
         handleAlert(error.response,{});
      });
  }

  componentWillMount() {
    if (typeof localStorage.token != "undefined") {
      if (localStorage.activityRole == "User" || localStorage.activityRole == "Manager") {
        window.location.href = "/selfReview";
      } else {
        window.location.href = "/admin";
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <div className="content content-fixed content-auth">
          <div className="container">
            <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
              <div className="media-body align-items-center d-none d-lg-flex">
                <div className="mx-wd-600">
                  <img src="./assests/img/img15.png" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="sign-wrapper mg-lg-l-50 mg-xl-l-60">
                <div className="wd-100p">
                  <h3 className="tx-color-01 mg-b-5"><i class="fas fa-sign-in-alt"></i> &nbsp;Sign In</h3>
                  <p className="tx-color-03 tx-16 mg-b-40">Welcome back! Please signin to continue.</p>

                  <div className="row">
                    <div id="errorPlaceholder" className="col-12">

                    </div>
                  </div>

                  <div className="form-group">
                    <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="fas fa-envelope-open-text"></i>&nbsp;Email</span>
                    <input onChange={(e) => this.inputHandler(e, "email")} type="email" className="form-control" placeholder="yourname@yourmail.com" />
                  </div>
                  <div className="form-group">
                    <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="fas fa-unlock"></i>&nbsp;Password</span>
                    <input onChange={(e) => this.inputHandler(e, "password")} type="password" className="form-control" placeholder="Enter your password" />
                  </div>
                  <div className="form-group">
                    <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="fas fa-building"></i>&nbsp;Organisation</span>
                    <input onChange={(e) => this.inputHandler(e, "organisation")} type="text" className="form-control" placeholder="Organisation" />
                  </div>
                  <button onClick={this.login} className="btn btn-brand-02 btn-block">Sign In</button>
                    <button onClick={() => {window.location.href=constants.ssoInitiate}} type="button" class="btn btn-outline-light  btn-block mt-2">
                      <img src="https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png" alt=""/>
                      <span className="ml-3 font-weight-bold">Sign In with Microsoft</span>
                    </button>
                  <div className="divider-text">or</div>
                  <div className="tx-13 mg-t-20 tx-center">
                    <div className="alert alert-primary" role="alert">
                      <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Connect with HR to get your account created.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default login;