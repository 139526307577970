import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { endpoint } from '../../../constants';

class areaChart extends Component {

    colors =  [{
      background : 'rgba(255, 99, 132, 0.2)',
      border : 'rgba(255, 99, 132, 1)'
    }, {
      background : 'rgba(54, 162, 235, 0.2)',
      border : 'rgba(54, 162, 235, 1)'
    },{
      background : 'rgba(255, 206, 86, 0.2)',
      border :  'rgba(255, 206, 86, 1)'
    },{
      background : 'rgba(75, 192, 192, 0.2)',
      border : 'rgba(75, 192, 192, 1)'
    },{
      background : 'rgba(153, 102, 255, 0.2)',
      border :  'rgba(153, 102, 255, 1)'
    },{
      background : 'rgba(255, 159, 64, 0.2)',
      border : 'rgba(255, 159, 64, 1)'
    },{
      background : 'rgba(255, 159, 64, 0.2)',
      border : 'rgba(255, 159, 64, 1)'
    }];
      
     options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
    };

    state  = {
      data : {}
    }

    getCompanyPerformance = () => {
      axios.get(`${endpoint}/manager/stats/area`).then((response) => {
      
        let dataItems = [];
        response.data.map((area,index) => {
            dataItems.push({
              label: area.name,
              data: area.report,
              fill: false,
              backgroundColor: this.colors[index].background,
              borderColor:  this.colors[index].border,
            });
        });

        let data = {
          labels: ['Ownership Bias for Action','Insist on Highest standards','Independence', 'Deliver Results','Learn and Be Curious'],
          datasets: dataItems,
        };
        console.log(data);

        this.setState({
          data
        }, ()  => {
          console.log("State");
          console.log(this.state);
        })

      }).catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      });
  }

  componentDidMount() {
    this.getCompanyPerformance();
  }

    render() {
        return (
            <React.Fragment>
              <div className="card pb-3">
                    <div className="card-header d-flex align-items-start justify-content-between mb-3">
                        <h6 className=" mg-b-0 tx-semibold"><i className="fas fa-users fa-lg"></i>  Area wise performance</h6>
                    </div>
                { this.state.data && <Line data={this.state.data} options={this.options} />  }
              </div>
            </React.Fragment>
        );
    }
}

export default areaChart;