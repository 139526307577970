import React, { Component } from 'react';
import Header from './../header/header';
import Footer from  './../footer/footer';
import axios from 'axios';
import * as constants from './../../constants';

class forgot extends Component {

    state = {
        currentPassword : '',
        newPassword : '',
        confirmNewPass : ''
    }

    handleInput = (e, field) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState[field] = e.target.value;
        this.setState(tmpState)
    }
    componentWillMount(){
        if(typeof localStorage.token === "undefined"){
            window.location.href= "/?message=Session+Expired";
        }
    }
    clear =  () => {
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmNewPass: ''
        });
    }

    changePass = () => {
        if(!this.state.currentPassword.length) {
            this.showError("warning", "Please provide your current password.");
        } else  if(this.state.currentPassword === this.state.newPassword) {
            this.showError("warning", "New password should not be equal to current password.");
        } else if(this.state.newPassword === this.state.confirmNewPass){
            axios.post(constants.endpoint+constants.updatePassword, {
                currentPassword : this.state.currentPassword,
                password : this.state.newPassword
              })
              .then((response) => {
                if(response.status === 202){
                    this.showError("success", "Password updated successfully ! Redirecting back in 3 sec");
                    window.setTimeout(() => {
                        if(localStorage.activityRole == "User"){
                            window.location.href = "/selfReview"
                        }else{
                            window.location.href = "/admin"
                        }
                        
                    }, 3000);
                }
              }).catch((error) => {
                  if(error.response.status === 400 ){
                    this.showError("danger", "Password should be at least 6 in length");
                  }else if(error.response.status === 401 ){
                    this.showError("danger", "Unusual activity detected.");
                  }else if(error.response.status === 402 ){
                    this.showError("danger", "Current password is not correct.");
                  }else{
                    this.showError("danger", "Something unexpected happened.");
                  }
              });
        }else{
            this.showError("warning","Both passwords do not match.");
        }
    }

    showError = (type, message) => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML  = (`
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `);
      }

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="content content-fixed content-auth-alt">
                    <div className="container d-flex justify-content-center ht-100p">
                        <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="wd-80p wd-sm-300 mg-b-15"><img src="./assests/img/img18.png" className="img-fluid" alt="" /></div>
                                <h4 className="tx-20 tx-sm-24">Change your password</h4>

                                <div className="row" style={{width:'100%',marginTop:'20px'}}>
                                    <div id="errorPlaceholder" className="col-12">
                                        
                                    </div>
                                </div>
                            <div className="col-12" style={{marginTop:'15px'}}>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Current password</h6>
                                        <input value={this.state.currentPassword} onChange={(e)=> this.handleInput(e,"currentPassword")} type="password" className="form-control" id="currentPassword" placeholder="Current Password" required />
                                    </div>
                                    <div className="form-group col-12">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">New password</h6>
                                        <input value={this.state.newPassword} onChange={(e)=> this.handleInput(e,"newPassword")} type="password" className="form-control" id="newPassword" placeholder="New Password" required  />
                                    </div>
                                    <div className="form-group col-12">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">COnfirm New password</h6>
                                         <input value={this.state.confirmNewPass} onChange={(e)=> this.handleInput(e,"confirmNewPass")} type="password" className="form-control" id="confirmNewPass" placeholder="Confirm Password" required  />
                                    </div>
                                </div>
                                <button onClick={this.clear} className="btn btn-brand-03">Clear</button>
                                <button onClick={this.changePass} className="btn btn-brand-02 float-right">Change Password</button>
                            </div>
                        </div>
                        </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default forgot;