import React, { Component } from 'react';

class rolesladder extends Component {
    render() {
        return (
            <div className="row">

            <div className="col-sm-12 col-lg-12">
                <div className="card card-body shadow-none">
                  <div className="marker marker-ribbon pos-absolute t-10 l-0">
                    Roles & levels
                  </div>
                  <div className="row">

                  <div className="col-12" style={{ marginTop: "20px" }}>
             
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <div className="row" style={{ width: "100%" }}>

                        { this.props.roles.length !== 0 && this.props.roles.map((role, oIndex) => (
                            
                            <div
                              key={oIndex}
                              className="col-3"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="card card-body shadow-none">
                                <div className="row">
                                  <div className="col-12">
                                    <h6 className="tx-uppercase tx-semibold mg-b-15">
                                    <i class="fas fa-code"></i> &nbsp;{role.name}
                                    </h6>
                                  </div>
                                </div>

                                <hr className="mg-y-8" />
                                {role.levels.map((role, index) => (
                                  <React.Fragment key={index}>
                                    <div className="row">
                                      <div className="col-6">
                                        <span className=" tx-13 tx-color-03 tx-uppercase tx-semibold"
                                          style={{ whiteSpace: "nowrap" }}>
                                          <i class="fas fa-code-branch"></i> &nbsp;{role.name}
                                          &nbsp;&nbsp;</span>
                                          
                                      </div>
                                      <div className="col-6 text-right">
                                        <code onClick={() => this.props.editRoles(oIndex, index)} className="cursor-pointer">Edit</code> <span>&nbsp;|&nbsp;</span>
                                          <code onClick={() => this.props.deleteRoles(oIndex, index)} className="cursor-pointer">Delete</code>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ))}
                                
                              </div>
                            </div>
                          )
                          
                        )}

                        </div>
                      </div>
              
                  </div>

                    {/* <div className="col-6">
                      <div className="steps steps-vertical">
                        {this.props.roles.length !== 0 &&
                          this.props.roles.map((role, oIndex) => (
                            <li key={oIndex} className="step-item">
                              <a style={{display:'flex'}}>
                                <span className="step-number">{oIndex+1}</span>
                                <span className="step-title">
                                  <span>{role.name} &nbsp;&nbsp;<code onClick={() => this.props.roleDelete(oIndex, role.name)} style={{display:
                                            role.levels.length>0 
                                                    ? "none" : "inline-block",
                                        }}
                                  className="cursor-pointer">Delete</code></span>
                                </span>
                              </a>
                              <ul>
                                {role.levels.map((level, pIndex) => (
                                  <li key={pIndex} className="complete">
                                    <a>{level.name}</a>
                                    <code onClick={() => this.props.editRoles(oIndex, pIndex)} className="cursor-pointer">Edit</code> <span>&nbsp;|&nbsp;</span>
                                    <code onClick={() => this.props.deleteRoles(oIndex, pIndex)} className="cursor-pointer">Delete</code>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
                    

        );
    }
}

export default rolesladder;