import React, { Component } from 'react';
import axios from 'axios';
import * as constants from './../../constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class header extends Component {

    state = {
        logo: ''
    }

    logout = () => {
        localStorage.removeItem("activityRole");
        localStorage.removeItem("cvtid");
        localStorage.removeItem("role");
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("organisation");
        localStorage.removeItem("logo");
        window.location.href = "/";
    }

    getLogo = () => {
        axios.get(constants.endpoint + constants.logo + "?name=" + localStorage.organisation)
            .then((response) => {
                this.setState({
                    logo: "./assests/img/" + response.data.logo.logo
                })
            }).catch((error) => {
                console.log("Error");
            });
    }

    componentDidMount() {
        this.getLogo();
    }

    render() {
        return (
            <header className="navbar navbar-header navbar-header-fixed">
                <a href="#" id="mainMenuOpen" className="burger-menu"><i data-feather="menu"></i></a>
                <div className="navbar-brand">
                <a href="/" title="Home" target="_self">
                        <img width="175px" src={this.state.logo} alt="" />
                    </a>
                </div>

                <div className="navbar-right">
                
                    <a href="/change" target="_self" style={{ cursor: 'pointer', display: (typeof localStorage.token != "undefined") ? "block" : "none" }} className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Change Password</a>&nbsp;&nbsp;
                    <h6 style={{ cursor: 'pointer', display: (typeof localStorage.token != "undefined") ? "block" : "none" }} onClick={this.logout} className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Logout</h6>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
            </header>
        );
    }
}

export default header;