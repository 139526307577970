import React, { Component } from 'react';
import Header from './../header/header';
import Footer from './../footer/footer';
import axios from 'axios';
import * as constants from './../../constants';
import UserList from '../manager/userList'
import { handleAlert } from './../alertHandler/alertHandler';

class managerReview extends Component {

  state = {
    managerFor: [],
    user: {
      role: '',
      manager: {
        name: ''
      }
    },
    questionSet: [],
    review: {},
    submission: {},
    isRenderCompleted: false,
    startReview: false,
    reviewing: '',
    userReviewed: {},
    isRatingGenerated: false,
    showPromoteReason: false,
    promoteReason: "",
    roles: [],
    levels: [],
    selectedRole: '',
    selectedLevel: '',
    promotionStatus: '',
    selectedFile: '',
    sets: {},
    current: {
      active: 0
    },
    sendNotificationStatus: false
  }

  getAnswerSet = () => {
    axios.get(constants.endpoint + constants.userAnswerSet)
      .then((response) => {
        this.setState({
          ...this.state,
          sets: response.data.sets.sets || {}
        });
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      });
  }

  showError = (type, message, icon="fa-chalkboard-teacher") => {
    document.querySelectorAll("#errorPlaceholder")[0].innerHTML = (`
        <div class="card" style="padding:25px">  
          <div class="text-center">
            <i style="font-size:64px" class="mt-5 fas ${icon} fa-lg"></i>
            <span class="mt-5 tx-uppercase tx-semibold mg-b-0 d-block">${message}</span>
          </div>
        </div>
        `);
    //this.state.submission.isPublished='true';
    this.setState({
      ...this.state,
      sendNotificationStatus: true,
      questionSet: [],
      submission: {},
      isRenderCompleted: false,
      startReview: false,
    })

  }

  jumpto = (item) => {
    this.setState({
      ...this.state,
      current: {
        active: item
      }
    }, () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
  }

  getRoles = () => {
    axios.get(constants.endpoint + constants.role + "?organisation=" + localStorage.organisation)
      .then((response) => {
        if (response.data.roles.length !== 0) {
          this.setState({
            ...this.state,
            roles: response.data.roles
          })
        }

      })
      .catch((error) => {
        this.showError("danger", "Unable to fetch roles !")
      });
  }

  onSelectPosition = (e, value) => {

    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState[value] = e.target.value;

    if (value === "selectedRole") {
      this.state.roles.map((role) => {
        if (role.name === e.target.value) {
          tmpState.levels = role.levels;
        }
      });
    }
    this.setState(tmpState);
  }


  handlePage = (type) => {
    let current = this.state.current.active;
    if (type === "Next") {
      if (current < this.state.questionSet.length - 1) {
        current++;
      }
    } else {
      if (current !== 0) {
        current--;
      }
    }
    this.setState({
      ...this.state,
      current: {
        active: current
      }
    }, () => {
      // this.state.submission.isPublished == "false" && this.submitSelfReview();
      (this.state.submission.isPublished == false || this.state.submission.isPublished == "false" ) && this.submitSelfReview();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });

  }

  startReview = () => {
    document.querySelectorAll("#errorPlaceholder")[0].innerHTML = '';
    let userReviewed = {}
    this.state.managerFor.map((usr, index) => {
      if (usr.cvtid == this.state.reviewing) {
        userReviewed = usr;
      }
    });
    this.setState({
      ...this.state,
      startReview: true,
      userReviewed: userReviewed,
      showPromoteReason: false
    });

    let role = userReviewed.role.split(" ").join("-") + "-" + userReviewed.level.split(" ").join("-");
    this.getQuestionSet(role);
  }


  resetUser = () => {
    this.setState({
      ...this.state,
      questionSet: [],
      submission: {},
      isRenderCompleted: false,
      startReview: false,
      reviewing: '',
      isRatingGenerated: false,
      showPromoteReason: false,
      userReviewed: {},
      promotionStatus: "",
      sendNotificationStatus: false,
      current: {
        active: 0
      }
    })
  }

  selectReviewEmployee = (e) => {
    if (e.target.value === "Select Employee") {
      this.setState({
        ...this.state,
        questionSet: [],
        submission: {},
        isRenderCompleted: false,
        startReview: false,
        reviewing: '',
        isRatingGenerated: false,
        showPromoteReason: false,
        userReviewed: {},
        promotionStatus: "",
        sendNotificationStatus: false,
        current : {
          active : 0
        }
      })
    } else {
      this.setState({
        ...this.state,
        reviewing: e.target.value,
        current : {
          active : 0
        }
      })
    }
  }

  getQuestionSet = (role) => {
    axios.get(constants.endpoint + constants.managerQuestionSet + "?role=" + role)
      .then((response) => {
        if (response.data.questionSet.length !== 0) {
          this.setState({
            ...this.state,
            questionSet: response.data.questionSet[0].area
          })
        }
      }).catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      }).then(() => {
        this.generateSubmission();
      }).then(() => {
        this.getSubmission();
      });
  }

  getTaggedAssociates = () => {
    axios.get(constants.endpoint + constants.managerTagged)
      .then((response) => {
        this.setState({
          ...this.state,
          managerFor: response.data.users
        })
      }).catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      }).then(() => {
        this.getCurrentReview();
      });
  }

  getUser = () => {
    axios.get(constants.endpoint + constants.userDetails)
      .then((response) => {
        this.setState({
          ...this.state,
          user: response.data.user
        })
      }).catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      }).then(() => {
        this.getTaggedAssociates();
        this.getCurrentReview();
      });
  }

  getCurrentReview = () => {
    axios.get(constants.endpoint + constants.getCurrentReview + "?organisation=" + localStorage.organisation)
      .then((response) => {
        if (response.data.review !== null) {
          this.setState({
            ...this.state,
            review: response.data.review
          });
        } else {
          this.showError("warning", "No active review scheduled at the moment!");
        }
      }).catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      });
  }

  componentWillMount() {
    if (typeof localStorage.token === "undefined") {
      window.location.href = "/?message=Session+Expired";
    }

    if (localStorage.activityRole === "User") {
      window.location.href = "/?message=Unauthorized+Access+Or+Session+Expired";
    }
    this.getUser();
    this.getAnswerSet();
    this.getRoles();
  }

  handleInput = (e, aIndex, qIndex, field) => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState.submission.area[aIndex].questions[qIndex][field] = e.target.value;
    if (field === "managerSelection") {
      tmpState.questionSet[aIndex].questions[qIndex].possibleAnswer.map((pA) => {
        if (e.target.value === pA.value) {
          tmpState.submission.area[aIndex].questions[qIndex].managerRating = pA.weightage;
          tmpState.submission.area[aIndex].questions[qIndex].finalRating = pA.weightage
        }
      });
    }

    this.setState(tmpState);
  }

  previewReview = () => {
    axios.post(constants.endpoint + constants.managerSubmission + "?preview=true", {
      ...this.state.submission
    })
      .then((response) => {

        this.setState({
          ...this.state,
          submission: {
            ...this.state.submission,
            area : response.data.area,
            finalComments : response.data.finalComments,
            finalRating : response.data.finalRating,
          }
        }, () => {
          handleAlert({status:202},{202:'Rating preview generated'});
        })
      }).catch((error) => {
        console.log(error);
        this.showError("danger", "Something unexpected happened !");
      }).then(() => {
        window.scrollTo(0, 0);
      });
  }

  saveReview = () => {

    let tempArea = this.state.submission.area;
    for (var i = 0; i < tempArea.length; i++) {
      let questionSets = tempArea[i].questions;
      for (var j = 0; j < questionSets.length; j++) {
        if (questionSets[j].managerSelection == 'Select rating' || questionSets[j].managerSelection == "") {
          alert("You have not answered all the questions. Please select your answer.");
          return false;
        }
      }
    }
    let tmpSubmission = this.state.submission;
    tmpSubmission.isPublished = false;
    axios.post(constants.endpoint + constants.managerSubmission + "?finalSubmit=true", {
      ...tmpSubmission
    })
      .then((response) => {

        this.setState({
          ...this.state,
          submission: {
            ...this.state.submission,
            area : response.data.area,
            finalComments : response.data.finalComments,
            finalRating : response.data.finalRating,
            isPublished: "true"
          }
        }, () => {
          handleAlert({status:201},{201:'User review for current quarter finalized successfully !'});
        })

      }).catch((error) => {
        console.log(error);
        this.showError("danger", "Something unexpected happened !");
      }).then(() => {
        window.scrollTo(0, 0);
      });
  };

  submitSelfReview = () => {
    let tmpSubmission = this.state.submission;
    tmpSubmission.isPublished = false;
    axios.post(constants.endpoint + constants.managerSubmission, {
      ...tmpSubmission
    })
      .then((response) => {
        
        handleAlert({status:201},{201:'Current review details updated successfully !'});

      }).catch((error) => {
        this.showError("danger", "Something that we don't expect happened ! Please report.", "fa-bug");
      }).then(() => {
        window.scrollTo(0, 0);
      });
  };

  getSubmission = () => {
    axios.post(constants.endpoint + constants.userSubmission, {
      cvtid: this.state.userReviewed.cvtid,
      reviewId: this.state.review.reviewId
    })
      .then((response) => {

        if ((response.data.submission !== null) && (response.data.submission.pendingWith.indexOf("Manager") !== -1)) {
          response.data.submission.area.map((area, aIndex) => {
            area.questions.map((question, qIndex) => {

              if (question.managerRating == 0) {
                question.managerRating = question.selfRating;
                question.finalRating = question.selfRating;
                question.managerSelection = question.selfSelection;
              }

            });
          });
          this.setState({
            ...this.status,
            submission: response.data.submission
          })

        } else {
          this.showError("warning", "User has not started / completed the self review for the current quarter.", "fa-user-times");
        }

      }).catch((error) => {
        console.log(error);
        this.showError("danger", "Something that we don't expect happened !", "fa-bug");
      });
  }

  getRatingMessage = (num) => {
    if (num <= 2) {
      return "Needs Improvement";
    } else if (num <= 2.5) {
      return "Achieves -";
    } else if (num <= 3) {
      return "Achieves";
    } else if (num <= 3.25) {
      return "Achieves +";
    } else if (num <= 3.5) {
      return "Exceeds -";
    } else if (num <= 4) {
      return "Exceeds";
    } else if (num <= 4.25) {
      return "Exceeds +";
    } else if (num <= 4.5) {
      return "Outstanding -";
    } else {
      return "Outstanding";
    }
  }

  generateRating = () => {
    let tmpState = JSON.parse(JSON.stringify(this.state));

    let finalTotal = 0;
    let finalAreas = tmpState.submission.area.length;
    tmpState.submission.area.map((ar, aIndex) => {
      let arTotal = 0;
      let questions = ar.questions.length;

      ar.questions.map((ques, qIndex) => {
        arTotal += ques.managerRating;
      });

      ar.areatotal = Math.round((arTotal / questions) * 100) / 100;
      ar.areaComment = this.getRatingMessage(ar.areatotal);
      finalTotal += ar.areatotal;
    });

    tmpState.isRatingGenerated = true;
    tmpState.submission.finalRating = Math.round((finalTotal / finalAreas) * 100) / 100;
    tmpState.submission.finalComments = this.getRatingMessage(tmpState.submission.finalRating);
    this.setState(tmpState);
  }

  generateSubmission = () => {

    let subObj = {
      name: this.state.user.name,
      cvtid: this.state.user.cvtid,
      manager: this.state.user.manager,
      reviewId: this.state.review.reviewId,
      year: this.state.review.year,
      quarter: this.state.review.quarter,
      area: [],
      finalRating: 0,
      finalComments: "",
      isPublished: false,
      pendingWith: "User"
    };

    this.state.questionSet.map((ar, aIndex) => {
      let areaObject = {
        areaId: ar.areaId,
        areatotal: 0,
        areaComment: "",
        questions: []
      };
      ar.questions.map((ques, qIndex) => {
        let quesObj = {
          questionId: ques.questionId,
          answerSet: ques.answerSet,
          selfRating: 0,
          selfSelection: '',
          selfExplain: '',
          managerRating: 0,
          managerSelection: '',
          managerExplain: '',
          finalRating: 0
        };
        areaObject.questions.push(quesObj);
      });
      subObj.area.push(areaObject);
    });
    this.setState({
      ...this.state,
      submission: subObj,
      isRenderCompleted: true
    });
  };

  getDisabled = (condition) => {
    return condition ? "disabled" : "";
  }

  raisePromotion = () => {
    let userReviewed = {}
    this.state.managerFor.map((usr, index) => {
      if (usr.cvtid == this.state.reviewing) {
        userReviewed = usr;
      }
    });
    this.setState({
      ...this.state,
      showPromoteReason: true,
      startReview: false,
      isRenderCompleted: false,
      userReviewed: userReviewed,
    });
    this.getPromotionStatus(userReviewed);
  }

  getPromotionStatus = (userReviewed) => {
    axios.get(constants.endpoint + constants.promotionStatus + "?cvtid=" + userReviewed.cvtid)
      .then((response) => {
        if (response.data) {
          this.setState({
            ...this.state,
            promotionStatus: response.data.status
          })
        }

      })
  }

  promoteUser = () => {
    if (this.state.selectedRole == "" || this.state.selectedRole == "Select Role") {
      alert("Please select Requested Role.");
      return false;
    }
    if (this.state.selectedLevel == "" || this.state.selectedLevel == "Select Level") {
      alert("Please select Requested Level.");
      return false;
    }
    if (this.state.promoteReason == "") {
      alert("Please fill Promote reason.");
      return false;
    }
    if (this.state.selectedFile.name == undefined) {
      alert("Please upload document.");
      return false;
    }

    let userPromoted = {}
    this.state.managerFor.map((usr, index) => {
      if (usr.cvtid == this.state.reviewing) {
        userPromoted = usr;
      }
    });
    const data = new FormData()
    var c = this.state.selectedFile.name.split(".");

    var managerView = { "managerId": localStorage.cvtid, "managerName": localStorage.name, "reason": this.state.promoteReason, "approved": true }

    data.append('sheet', this.state.selectedFile);
    axios.post(constants.endpoint + constants.promoteList, {
      cvtid: userPromoted.cvtid,
      name: userPromoted.name,
      currRole: this.state.userReviewed.role,
      currLevel: this.state.userReviewed.level,
      newRole: this.state.selectedRole,
      newLevel: this.state.selectedLevel,
      promoteReason: managerView,
      organisation: localStorage.organisation,
      sheet: c[0] + "-" + new Date().toDateString() + "." + c[1],
    }).then(response => {
      if (response.status === 201) {
        this.setState({
          showPromoteReason: false,
          promoteReason: "",
        })
        this.showError("success", "Promotion Request Submitted !");
      }
      this.uploadFile(data);
    }).catch((error) => {
      if (error.response.status === 409) {
        this.showError("danger", "Promotion of this user is still pending !");
      }
      else {
        this.showError("danger", "Something that we don't expect happened ! Please report.", "fa-bug");
      }
    });
  }

  uploadFile = (data) => {
    axios.post(constants.endpoint + constants.uploadFile, data, {})
      .then(response => {
        if (response.status === 201) {
          this.setState({
            showPromoteReason: false,
            promoteReason: "",
          })
          this.showError("success", "Promotion Request Submitted !");
        }
      })

  }

  onReasonInput = (e) => {

    this.setState({
      ...this.state,
      promoteReason: e.target.value
    });
  }

  onFileChange = e => {

    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      this.setState({
        ...this.state,
        selectedFile: file,
      });

    }
  };

  sendNotification = () =>{
    let receiverId=document.getElementById("ddlEmmployee").value;
    axios.post(constants.endpoint + constants.sendNotification, {
      notificationType:"Personal",
      message:"Please fill your riview details.",
      receiverId:"CVT 01010014"
    })
      .then(response => {
        if (response.status === 201) {
          this.showError("success", "Notification sent to the user.!");
        }
      })
  }


  render() {
    return (

      <React.Fragment>
        <Header />

        <div className="content content-fixed">
          <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0" style={{ minHeight: '80vh' }}>
            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
              <div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active" aria-current="page">Performance Review</li>
                  </ol>
                </nav>
                <h4 className="mg-b-0 tx-spacing--1">Welcome {this.state.user.name}</h4>
              </div>
              <div className="d-none d-md-block">
                <a href="/selfreview" style={{ display: 'inline-block' }} className="nav-link" target="_self"><i class="fas fa-arrow-left"></i> &nbsp;Back to Self Review</a>
              </div>
            </div>

           

            {/* <UserList/> */}

            <div className="row" style={{ marginBottom: '25px', display: Object.keys(this.state.review).length === 0 ? "none" : "" }}>
              <div className="col-lg-12 col-xl-12 mg-t-10">
                <div className="card" style={{ padding: '25px' }}>
                  <div className="row">
                    <div className="col-6">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Select Employee to review</h6>
                      
                      <div className="input-group mg-b-10">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1"><i class="fas fa-users"></i></span>
                        </div>
                          <select id="ddlEmmployee" value={this.state.reviewing} onChange={this.selectReviewEmployee} disabled={this.state.startReview} className="custom-select">
                            <option>Select Employee</option>
                            {
                              this.state.managerFor.map((user, uIndex) => (
                                <option key={uIndex} value={user.cvtid}>{user.name}</option>
                              ))
                            }
                          </select>
                      </div>


                      {/* <select id="ddlEmmployee" value={this.state.reviewing} onChange={this.selectReviewEmployee} disabled={this.state.startReview} className="custom-select">
                        <option>Select Employee</option>
                        {
                          this.state.managerFor.map((user, uIndex) => (
                            <option key={uIndex} value={user.cvtid}>{user.name}</option>
                          ))
                        }
                      </select> */}
                    </div>
                    <div className="col-6 text-right">
                      <button disabled={this.state.reviewing.length === 0} onClick={this.startReview} style={{ position: 'relative', top: '38%' }} type="button" className="btn btn-xs btn-primary"><i class="far fa-play-circle fa-lg"></i>&nbsp;&nbsp;Start Review</button>
                      <button disabled={this.state.reviewing.length === 0} onClick={this.raisePromotion} style={{ position: 'relative', top: '38%' }} type="button" className="btn btn-xs btn-primary ml-3"> <i class="far fa-paper-plane fa-lg"></i>&nbsp;&nbsp;Raise Promotion</button>
                      {/* <button disabled={this.state.sendNotificationStatus === false} onClick={this.sendNotification} style={{ position: 'relative', top: '38%' }} type="button" className="btn btn-sm btn-primary ml-3">Send Notification</button> */}
                      <button onClick={this.resetUser} style={{ position: 'relative', top: '38%', marginLeft: '20px' }} type="button" className="btn btn-xs btn-danger"><i class="fas fa-ban fa-lg"></i>&nbsp;&nbsp;Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div id="errorPlaceholder" className="col-lg-12 col-xl-12 mg-t-10">

              </div>
            </div>

            <div className="row" style={{ marginTop: '20px', display: this.state.showPromoteReason ? 'block' : 'none' }}>
              <div className="col-sm-6 col-lg-12">
                <div className="card card-body">
                  <div className="row">
                    <div className="col-3">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Name</h6>
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.reviewing}</h3>
                      </div>
                    </div>
                    <div className="col-3">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Current Role</h6>
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.userReviewed.role + "-" + this.state.userReviewed.level}</h3>
                      </div>
                    </div>
                    <div className="col-3">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Promotion Status</h6>
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.promotionStatus.length !== 0 ? this.state.promotionStatus : "Promotion not requested"}</h3>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>



            {
              this.state.showPromoteReason && (
                <div className="row" style={{ marginBottom: '25px' }}>
                  <div className="col-lg-12 col-xl-12 mg-t-10">
                    <div className="card" style={{ padding: '25px' }}>

                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputcvtid">Requested Role</label>
                          <select onChange={(e) => this.onSelectPosition(e, 'selectedRole')} className="custom-select">
                            <option>Select Role</option>
                            {
                              this.state.roles.map((role, rIndex) => (
                                <option key={rIndex} value={role.name}>{role.name}</option>
                              ))
                            }
                          </select>
                        </div>

                        <div className="form-group col-md-4">
                          <label htmlFor="inputcvtid">Requested Level</label>
                          <select onChange={(e) => this.onSelectPosition(e, 'selectedLevel')} className="custom-select">
                            <option>Select Level</option>
                            {
                              this.state.levels.map((level, rIndex) => (
                                <option key={rIndex} value={level.name}>{level.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-8">
                          <label htmlFor="inputcvtid">Enter Reason to Promote</label>
                          <textarea value={this.state.promoteReason} onChange={this.onReasonInput} className="form-control" id="description" rows="3" placeholder="Enter reason"></textarea>
                        </div>
                      </div>

                      <div className="form-row">
                        <div>
                          <label htmlFor="inputcvtid">Document Upload</label><br />
                          <input onChange={this.onFileChange} id="file-input-pan" type="file" />
                        </div>
                      </div>
                      <div className="form-row mt-3">
                        <button disabled={this.state.promoteReason.length === 0 || this.state.selectedFile === ""} onClick={this.promoteUser} style={{ position: 'relative', top: '38%' }} type="button" className="btn btn-sm btn-primary">Submit Promotion</button>
                      </div>

                    </div>
                  </div>
                </div>
              )
            }

            {
              this.state.startReview && (<div className="row">

                <div className="col-sm-6 col-lg-3">
                  <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="far fa-calendar-alt"></i> &nbsp;Review Year</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                      <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.review.year}</h3>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="far fa-calendar-minus"></i>&nbsp;Review Quarter</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                      <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.review.quarter}</h3>
                    </div>
                  </div>
                </div>


                <div className="col-sm-6 col-lg-3">
                  <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="fas fa-user"></i>&nbsp;Reviewing</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                      <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.userReviewed.name}</h3>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card card-body">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="fas fa-file-contract"></i>&nbsp;Role</h6>
                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                      <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.userReviewed.role}-{this.state.userReviewed.level}</h3>
                    </div>
                  </div>
                </div>

              </div>)
            }


            <div className="row" style={{ padding: '15px', display: Object.keys(this.state.review).length === 0 ? "none" : "" }}>
              <div className="col-lg-12 col-xl-12 mg-t-10" style={{ padding: '0px', display: this.state.startReview ? "block" : "none" }}>
                <div className="card" style={{ padding: '25px' }}>

                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Review Areas</h6>

                  <ul className="steps">
                    {
                      this.state.questionSet.map((area, index) => (
                        <li className="step-item">
                          <a key={index} className={this.state.current.active == index ? 'step-link selected-area' : 'step-link'}
                            onClick={() => this.jumpto(index)} style={{ cursor: 'pointer' }}>
                            <span className="step-number">{index + 1}</span>
                            <span className="step-title">{area.name}</span>
                          </a>
                        </li>
                      ))
                    }

                  </ul>
                </div>
              </div>


              {
                this.state.isRenderCompleted && this.state.questionSet.map((area, aIndex) => (
                  <div key={aIndex} className="col-lg-12 col-xl-12 mg-t-10" style={{ padding: '0px', display: this.state.current.active == aIndex ? 'block' : 'none' }} area-id={aIndex}>
                    <div className="card">

                      <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                        <h6 className="tx-uppercase tx-15 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">{area.name}</h6>
                      </div>

                      <div className="card-body pd-10">
                        {
                          area.questions.map((question, qIndex) => (
                            <React.Fragment key={qIndex}>
                              <div className="row pd-20">
                                <div className="col-12">
                                  <p>
                                    <code>{question.questionId.split("-")[(question.questionId.split("-").length - 1)]}</code> &nbsp;
                                                  	{question.text}
                                  </p>
                                </div>
                                <div className="col-6">
                                  <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Self</span>
                                  <select
                                    disabled={this.state.submission.pendingWith !== "User"}
                                    value={this.state.submission.area[aIndex].questions[qIndex].selfSelection}
                                    disabled className="custom-select">
                                    <option >Select self rating</option>
                                    {
                                      question.possibleAnswer.map((pA, pIndex) => (
                                        <option key={pIndex} value={pA.value}>{pA.value}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                                <div className="col-6">
                                  <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Examples/Justifications of your answer </span>
                                  <span></span>
                                  <textarea

                                    value={this.state.submission.area[aIndex].questions[qIndex].selfExplain}
                                    disabled className="form-control" rows="1" placeholder="Textarea"></textarea>
                                </div>

                                <div className="col-6" style={{ marginTop: "10px" }}>
                                  <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Selection</span>
                                  <select
                                    disabled={this.state.submission.isPublished === "true" ? "disabled" : null}
                                    value={this.state.submission.area[aIndex].questions[qIndex].managerSelection}
                                    onChange={(e) => this.handleInput(e, aIndex, qIndex, "managerSelection")} className="custom-select">
                                    <option>Select rating</option>
                                    {
                                      question.possibleAnswer.map((pA, pIndex) => (
                                        <option key={pIndex} value={pA.value}>{pA.value}</option>
                                      ))
                                    }
                                  </select>
                                </div>

                                <div className="col-6" style={{ marginTop: "10px" }}>
                                  <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Comments </span>

                                  <textarea
                                    disabled={this.state.submission.isPublished === "true" ? "disabled" : null}
                                    value={this.state.submission.area[aIndex].questions[qIndex].managerExplain}
                                    onChange={(e) => this.handleInput(e, aIndex, qIndex, "managerExplain")} className="form-control" rows="1" placeholder="Manager comments"></textarea>
                                </div>

                                <div className="col-12" style={{ marginTop: '15px' }}>
                                  <div className="row">
                                    <div className="offset-6 col-2">
                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Self Rating : </span>
                                      <code> {this.state.submission.area[aIndex].questions[qIndex].selfRating} </code>
                                    </div>
                                    <div className="col-2">
                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Rating : </span>
                                      <code> {this.state.submission.area[aIndex].questions[qIndex].managerRating} </code>
                                    </div>

                                    <div className="col-2">
                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Final Rating : </span>
                                      <code> {this.state.submission.area[aIndex].questions[qIndex].finalRating} </code>
                                    </div>
                                  </div>
                                </div>

                              </div>

                              {/* style={{display: area.questions.length-1 === qIndex ? "none" : "block"}} */}
                              <hr className="mg-y-10" />
                              <div className="col-12" style={{ marginBottom: '5px', marginTop: '15px', display: area.questions.length - 1 === qIndex ? "block" : "none" }}>
                                <div className="row">
                                  <div className="offset-6 col-4">
                                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Comments : </span>
                                    <code> {this.state.submission.area[aIndex].areaComment || "NA"} </code>
                                  </div>
                                  <div className="col-2">
                                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Rating : </span>
                                    <code> {this.state.submission.area[aIndex].areatotal} </code>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))
                        }
                      </div>
                      <div className="text-right mr-3 mb-3 mt-2">
                        <button disabled={this.state.current.active == 0} onClick={() => this.handlePage('Prev')} className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5">  <i class="fas fa-chevron-circle-left fa-lg"></i> &nbsp; Prev Area </button>
                        <button disabled={((this.state.questionSet.length - 1) - this.state.current.active) == 0} onClick={() => this.handlePage('Next')} className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"> Next Area &nbsp; <i class="fas fa-chevron-circle-right fa-lg"></i></button>
                      </div>
                    </div>

                  </div>
                ))
              }

            </div>

            <div style={{ display: Object.keys(this.state.review).length === 0 ? "none" : "" }}>
              <div className="row" style={{ position: 'relative', marginBottom: '20px', display: this.state.startReview ? "" : "none" }}>

                <div className="col-3" style={{ margin: 'auto 0px' }}>
                  <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Comments : </span>
                  <code> {this.state.submission.finalComments || "NA"} </code>
                </div>
                <div className="col-2" style={{ margin: 'auto 0px' }}>
                  <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Rating : </span>
                  <code> {this.state.submission.finalRating} </code>
                </div>
                <div className="offset-2"></div>
                {
                  ((this.state.questionSet.length - 1) - this.state.current.active) == 0 && (<div className="col-5" style={{ display: this.state.isRenderCompleted ? "" : "none", textAlign: 'right' }}>
                    <div className="btn-group" role="group" aria-label="Basic example">
                      {/* <button disabled={this.state.submission.isPublished == "true" ? "disabled" : null} onClick={this.generateRating} type="button" className="btn btn-primary">Generate Rating</button> */}
                      <button style={{ display : this.state.submission.isPublished == false || this.state.submission.isPublished == "false"? 'block' : 'none'}} onClick={this.previewReview} type="button" className="btn btn-primary btn-sm"><i class="fas fa-spell-check fa-lg"></i> Preview Rating</button>
                      <button style={{ display : this.state.submission.isPublished == false || this.state.submission.isPublished == "false"? 'block' : 'none'}} onClick={this.saveReview} type="button" className="btn btn-primary btn-sm"><i class="far fa-check-circle fa-lg"></i> Finalize Review</button>
                      {/* <button type="button" disabled={!this.state.isRatingGenerated} onClick={this.submitSelfReview} className="btn btn-success">Finalize Review</button> */}
                    </div>
                  </div>)
                }
              </div>
            </div>

          </div>
        </div>

        <Footer />
      </React.Fragment>

    );
  }
}

export default managerReview;