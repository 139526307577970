import React, { Component } from 'react';
import Header from './../header/header';
import Footer from  './../footer/footer';

class forbidden extends Component {
    render() {
        return (
            <React.Fragment>
            <Header />
            <div class="content content-fixed content-auth-alt">
                <div class="container d-flex justify-content-center ht-100p">
                    <div class="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div class="wd-80p wd-sm-600 mg-b-15"><img src="./assests/img/img16.png" class="img-fluid" alt="" /></div>
                            <h4 class="tx-20 tx-sm-24">Access Forbidden</h4>
                    </div>
                    </div>
            </div>
            <Footer />
        </React.Fragment>
        );
    }
}

export default forbidden;