import React, { Component } from 'react';

class organisationLadder extends Component {
    render() {
        return (
            <div className="col-4">
                <ul className="steps steps-vertical">
                    {
                        this.props.organisations.length != 0 && this.props.organisations.map((org, oIndex) => (
                            <li key={oIndex} className="step-item">
                                <a style={{display:'flex'}}>
                                     <span className="step-number"> {oIndex+1}</span>
                                    <span className="step-title">
                                        <span>{org.name}&nbsp;&nbsp;<code onClick={() => this.props.deleteOrganisation(oIndex, org.name)} style={{display:
                                            org.projects.length>0 
                                                    ? "none" : "inline-block",
                                        }}
                                  className="cursor-pointer">Delete</code></span>
                                    </span>
                                </a>
                                <ul>
                                    {
                                        org.projects.map((project, pIndex) => (
                                            <li key={pIndex} className="complete">
                                                <a>{project.name} ({project.manager[0].name})
                                                </a>
                                                <code onClick={() => this.props.editProject(oIndex, pIndex)} className="cursor-pointer">Edit</code> <span>&nbsp;|&nbsp;</span>
                                                <code onClick={() => this.props.deleteProject(oIndex, pIndex)} className="cursor-pointer">Delete</code>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                        ))
                    }

                </ul>
            </div>
        );
    }
}

export default organisationLadder;