
export let ssoInitiate = "https://api.growthmonitor.app/users/authenticate/sso";
export let endpoint = "https://api.growthmonitor.app"
// export let endpoint = "http://localhost:3001"
export let organisation = "";
export let project = "";
export let editProject = "";
export let deleteProject = "";
export let role = "";
export let level = "";
export let user = "";
export let updateUser = "";
export let activateUser = "";
export let deactivateUser = "";
export let updatePrivilage = "";
export let updateManager = "";
export let deleteManager="";
export let updateQuestionSet = "";
export let review = "";
export let reviewUser = "";
export let accResult = "";
export let resetReview = "";
export let resetPass = "";
export let answerSet = "";
export let employeeId = "";
export let promote = "";
export let updatePromotionStatus="";
export let rejectedList="";
export let projects="";
export let migrateUser="";
export let uploadFile="";
export let downloadDocument="/files";
export let editLevel="";
export let deleteLevel="";
export let editReviewDate="";

export const userQuestionSet = "/users/question";
export const getCurrentReview = "/users/review";
export const userDetails = "/users/user";
export const submission = "/users/submission";
export const finalizeReview = "/users/userFinzalizeReview";
export const signIn = "/users/signin";
export const ssoLoginToken = "/users/authenticate/sso/token" 
export const updatePassword = "/users/changePassword";
export const logo = "/users/logo";
export const userAnswerSet = "/users/answerSet";
export const userPreviousReviews = "/users/previousReviews";
export const userPreviousReviewsByYear = "/users/previousReviewsByYear"
export const questionSetById = "/users/questionSetById";

export const managerTagged = "/manager/user";
export const userSubmission = "/manager/submission";
export const managerSubmission = "/manager/managerReview";
export const managerQuestionSet = "/manager/question";
export const promoteList = "/manager/promoteList";
export const roles = "/manager/role";
export const promotionStatus = "/manager/promotionStatus";
export const submitPromoteReason = "/manager/submitPromoteReason"
export const teamRatingDistribution = "/manager/teamRatingDistribution"
export const teamRatingDistributionByArea = "/manager/teamRatingDistributionByArea"
export const teamRatingDistributionByRole = "/manager/teamRatingDistributionByRole"
export const yearAndQuarter ="/manager/yearAndQuarter";
export const deleteRole ="/owner/deleteRole";
export const deleteUser ="/owner/user/delete";
export const deleteOrganisation ="/owner/org/delete";
export const getNotification ="/users/getNotification";
export const sendNotification ="/users/sendNotification";
export const updateReadNotificationStatus="/users/updateReadNotificationStatus";
export const teamRatingDistributionByAreaAllQuarter = "/manager/teamRatingDistributionByAreaAllQuartes"

if (typeof localStorage.token != "undefined") {
  organisation = `/${localStorage.activityRole.toLowerCase()}/organisation`;
  project = `/${localStorage.activityRole.toLowerCase()}/project`;
  editProject = `/${localStorage.activityRole.toLowerCase()}/project/edit`;
  deleteProject = `/${localStorage.activityRole.toLowerCase()}/project/delete`;
  role = `/${localStorage.activityRole.toLowerCase()}/role`;
  level = `/${localStorage.activityRole.toLowerCase()}/level`;
  user = `/${localStorage.activityRole.toLowerCase()}/user`;
  updateUser =  `/${localStorage.activityRole.toLowerCase()}/updateUser`;
  activateUser = `/${localStorage.activityRole.toLowerCase()}/user/activate`;
  deactivateUser = `/${localStorage.activityRole.toLowerCase()}/user/deactivate`;
  updatePrivilage = `/${localStorage.activityRole.toLowerCase()}/updatePrivilage`;
  updateManager = `/${localStorage.activityRole.toLowerCase()}/updateManager`;
  updateQuestionSet = `/${localStorage.activityRole.toLowerCase()}/questionSet`;
  review = `/${localStorage.activityRole.toLowerCase()}/review`;
  reviewUser = `/${localStorage.activityRole.toLowerCase()}/reviewUser`;
  accResult = `/${localStorage.activityRole.toLowerCase()}/reviewData`;
  resetReview = `/${localStorage.activityRole.toLowerCase()}/reviewReset`;
  resetPass = `/${localStorage.activityRole.toLowerCase()}/reset`;
  answerSet = `/${localStorage.activityRole.toLowerCase()}/answerSet`;
  promote = `/${localStorage.activityRole.toLowerCase()}/promote`;
  updatePromotionStatus = `/${localStorage.activityRole.toLowerCase()}/updatePromotionStatus`;
  rejectedList = `/${localStorage.activityRole.toLowerCase()}/rejectList`;
  projects = `/${localStorage.activityRole.toLowerCase()}/getProjects`;
  migrateUser =`/${localStorage.activityRole.toLowerCase()}/migrateUser`;
  uploadFile =`/${localStorage.activityRole.toLowerCase()}/uploadFile`;
  deleteManager=`/${localStorage.activityRole.toLowerCase()}/deleteManager`;
  editLevel =`/${localStorage.activityRole.toLowerCase()}/level/edit`;
  deleteLevel= `/${localStorage.activityRole.toLowerCase()}/level/delete`;
  editReviewDate= `/${localStorage.activityRole.toLowerCase()}/editReviewDate`;
}
