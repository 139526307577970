import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { endpoint } from '../../../constants';

class performanceChart extends Component {

    state = {};

    getCompanyPerformance = () => {
        axios.get(`${endpoint}/manager/company/performance`).then((response) => {
        
        let ratings = [], labels = [];
        response.data.ratings.map((rating) => {
            ratings.push(rating.count);
            labels.push(rating._id);
        });

        this.setState({
            labels: labels,
            datasets: [
                {
                    label: 'Performance',
                    data: ratings,
                    backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },    
                ],  
        })

        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        });
    }

    componentDidMount() {
        this.getCompanyPerformance();
    }

    render() {
        return (
            <React.Fragment>
                <div className="card pb-3">
                    <div className="card-header d-flex align-items-start justify-content-between mb-3">
                        <h6 className=" mg-b-0 tx-semibold"><i className="fas fa-users fa-lg"></i>  Company Performance Chart</h6>
                    </div>
                       <Pie data={this.state} />
                </div>
            </React.Fragment>
        );
    }
}

export default performanceChart;