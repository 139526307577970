import React, { Component } from "react";

import Header from "./../header/header";
import Addusers from "./addusers/addusers";
import ManageQuestions from "./manageQuestions/manageQuestions";
import ManageRoles from "./manageRoles/manageRoles";
import Organisation from "./organisations/organisation";
import Planreview from "./planReview/planreview";
import ManagerAdminReview from "./managerReview/managerAdminReview";
import ReviewList from "./reviewList/reviewList";
import AnswerSets from "./answerSets/answerSets";
import Promotions from "./promotions/promotions";
import * as constants from "./../../constants";
import Dashboard from './dashboard/index';

import axios from "axios"; 

import { handleAlert } from "./../alertHandler/alertHandler";

class aside extends Component {
  state = {
    active: "dashboard",
    activityRole: "",
    organisations: [],
  };

  changePath = (e, route) => {
    this.setState({
      active: route,
    });
  };

  getClassName = (route) => {
    if (route == this.state.active) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  };

  getComponent = (element) => {
    switch (element) {
      case "employee":
        return <Addusers />;
      case "question":
        return <ManageQuestions />;
      case "role":
        return <ManageRoles />;
      case "org":
        return <Organisation />;
      case "planReview":
        return <Planreview />;
      case "currentReview":
        return <ManagerAdminReview />;
      case "allreviews":
        return <ReviewList />;
      case "answerset":
        return <AnswerSets />;
      case "promotions":
        return <Promotions />;
      case "dashboard":
        return <Dashboard />;
      default:
        return null;
    }
  };

  componentWillMount() {
    if (typeof localStorage.token == "undefined") {
      window.location.href = "/?message=Session+Expired";
    }
  }
  componentDidMount() {
    if (
      localStorage.activityRole != "Owner" &&
      localStorage.activityRole != "Admin"
    ) {
      window.location.href = "/forbidden";
    }

    this.setState(
      {
        ...this.state,
        activityRole: localStorage.activityRole,
        selectedOrganisation: localStorage.selectedOrganisation || "",
      },
      () => {
        this.getOrganisations();
      }
    );
  }

  getOrganisations = () => {
    axios
      .get(constants.endpoint + constants.organisation)
      .then((response) => {
        this.setState({
          ...this.state,
          organisations: response.data.organisations,
        });
      })
      .catch((error) => {
        console.log(error);
        handleAlert(error.response, {});
      });
  };

  selectOrganisation = (e) => {
    localStorage.setItem("selectedOrganisation", e.target.value);
    this.setState({
      ...this.state,
      selectedOrganisation: e.target.value,
    });
    window.location.reload();
    handleAlert(
      { status: 200 },
      { 200: "Organisation selected for operations." }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <aside className="aside aside-fixed">
          <div className="aside-body">
            <div className="aside-loggedin">
              <div className="d-flex align-items-center justify-content-center">
                <div className="avatar">
                  <span className="avatar-initial rounded-circle">
                    {localStorage.name[0]}
                  </span>
                </div>
              </div>
              <div className="aside-loggedin-user text-center">
                <a
                  href="#loggedinMenu"
                  className="d-flex align-items-center justify-content-center mg-b-2"
                  data-toggle="collapse"
                >
                  <h6 className="tx-semibold mg-b-0">{localStorage.name}</h6>
                  <i data-feather="chevron-down"></i>
                </a>
                <p className="tx-color-03 tx-12 mg-b-0">
                  {localStorage.activityRole}
                </p>
                <select
                  style={{
                    display:
                      localStorage.activityRole === "Owner" ? "" : "none",
                  }}
                  value={this.state.selectedOrganisation}
                  onChange={this.selectOrganisation}
                  className="mt-2"
                  name=""
                  id=""
                >
                  <option value="NA">Select Organisation</option>
                  {this.state.organisations.map((org, oIndex) => (
                    <option value={org.name} key={oIndex}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <ul className="nav nav-aside">
              <li className="nav-label">View</li>
              <li
                style={{ cursor: "pointer" }}
                className={this.getClassName("dashboard")}
                >
                {" "}
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "dashboard")}>
                  <i className="fas fa-tachometer-alt"></i>&nbsp;Dashboard
                  </span>
                </a>{" "}
              </li>
              </ul>
            <ul className="nav nav-aside">
              <li className="nav-label">Manage</li>
              <li
                style={{ cursor: "pointer" }}
                className={this.getClassName("employee")}
              >
                {" "}
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "employee")}>
                  <i className="fas fa-users-cog"></i>&nbsp;Manage Employee
                  </span>
                </a>{" "}
              </li>
              <li
                style={{ cursor: "pointer" }}
                className={this.getClassName("question")}
              >
                {" "}
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "question")}>
                  <i className="fas fa-tasks"></i>&nbsp;Manage Questions
                  </span>
                </a>
              </li>
              <li
                style={{
                  cursor: "pointer",
                  display:
                    this.state.activityRole === "Admin" ||
                    this.state.activityRole === "Owner"
                      ? ""
                      : "none",
                }}
                className={this.getClassName("role")}
              >
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "role")}>
                  <i className="fas fa-tasks"></i>&nbsp;Manage Role & Levels
                  </span>
                </a>
              </li>
              <li
                style={{ cursor: "pointer" }}
                className={this.getClassName("answerset")}
              >
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "answerset")}>
                  <i className="fas fa-list-ul"></i>&nbsp;Manage Answer Sets
                  </span>
                </a>
              </li>
              <li
                style={{
                  cursor: "pointer",
                  display: this.state.activityRole === "Owner" ? "" : "none",
                }}
                className={this.getClassName("org")}
              >
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "org")}>
                  <i className="far fa-building"></i>&nbsp;Manage Organisations
                  </span>
                </a>
              </li>
              <li className="nav-label mg-t-25">Review</li>
              <li
                style={{
                  cursor: "pointer",
                  display: this.state.activityRole === "Owner" || this.state.activityRole === "Admin" ? "" : "none",
                }}
                className={this.getClassName("promotions")}
              >
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "promotions")}>
                  <i className="fas fa-plane-departure"></i>&nbsp;Promotions
                  </span>
                </a>
              </li>
              <li
                style={{
                  cursor: "pointer",
                  display: this.state.activityRole === "Owner"||this.state.activityRole === "Admin" ? "" : "none",
                }}
                className={this.getClassName("planReview")}
              >
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "planReview")}>
                  <i className="far fa-calendar-check"></i>&nbsp;Plan Review
                  </span>
                </a>
              </li>
              <li
                style={{ cursor: "pointer" }}
                className={this.getClassName("currentReview")}
              >
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "currentReview")}>
                  <i className="far fa-paper-plane"></i>&nbsp;Manage Current Reviews
                  </span>
                </a>
              </li>
              <li
                style={{ cursor: "pointer" }}
                className={this.getClassName("allreviews")}
              >
                <a className="nav-link">
                  <span onClick={(e) => this.changePath(e, "allreviews")}>
                  <i className="far fa-calendar-alt"></i>&nbsp;All Reviews
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </aside>
        {this.getComponent(this.state.active)}
      </React.Fragment>
    );
  }
}

export default aside;
