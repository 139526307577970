import { ToastContainer, toast } from 'react-toastify';


let toastConfig = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

let createAlert = (type, message) => {
    if (type === "error") {
        toast.error(message, toastConfig);
    } else if (type === "success") {
        toast.success(message, toastConfig);
    }
}

export let handleAlert = (response, alertConfig) => {

    switch(response.status){
        case 400 : createAlert('error', alertConfig[400] || 'Please provide valid values for all fields');break;
        case 401 : createAlert('error', alertConfig[401] || 'Unauthorized access. Cannot perform this operation.'); break;
        case 409 : createAlert('error', alertConfig[409] || 'Resource already exists, cannot add new resource'); break;
        case 200 : createAlert('success', alertConfig[200] || 'Operation completed successfully.'); break;
        case 201 :  createAlert('success', alertConfig[201] || 'Resource created successfully.'); break;
        case 202 : createAlert('success', alertConfig[202] || 'Resource updated successfully !'); break;
        case 500 : createAlert('error', alertConfig[500] || 'Something went wrong, please try again later.');
        default : createAlert('error', alertConfig[500] || 'Something went wrong, please try again later.'); break;
    }
}