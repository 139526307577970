import React, { Component } from "react";
import Aside from "./../aside";
import axios from "axios";
import * as constants from "./../../../constants";
import { throws } from "assert";
import RolesLadder from "./rolesLadder"
class manageRoles extends Component {
  state = {
    roles: [],
    input: {
      roleName: "",
      levelName: "",
      roleSelected: "",
      oldName:"",
    },
    operation : 'Add'
  };

  handleInput = (e, field) => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState.input[field] = e.target.value;
    this.setState(tmpState);
  };

  showError = (type, message) => {
    document.querySelectorAll("#errorPlaceholder")[0].innerHTML = `
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `;
  };

  clearInput = (fieldName) => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState.input[fieldName] = "";
    this.setState(tmpState);
  };

  getRoles = () => {
    let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
    axios
      .get(
        constants.endpoint +
          constants.role +
          "?organisation=" +
          organisation
      )
      .then((response) => {
        this.setState({
          ...this.state,
          roles: response.data.roles,
        });

      })

      .catch((error) => {
        this.showError("danger", "Unable to fetch roles !");
      });
  };

  addRole = () => {
    
    if(this.state.input.roleName===""){
      this.showError("danger", "Role name cannot be blank.");
      return false;
    }

    let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
    axios
      .post(constants.endpoint + constants.role, {
        name: this.state.input.roleName,
        order: this.state.roles.length + 1,
        organisation: organisation,
      })
      .then((response) => {
        if (response.status === 201) {
          this.showError("success", "Role added successfully !");
          this.getRoles();
        }
      })
      .catch((error) => {
        if (error && error.response.status === 409) {
          this.showError("danger", "Role already exists !");
        } else if (error.response.status === 400) {
          this.showError("warning", "Please provide proper inputs !");
        } else {
          this.showError("danger", "Something unexpected happened !");
        }
      })
      .then(() => {
        this.clearInput("roleName");
      });
  };

  addLevel = () => {

    if(this.state.operation ==='Add'){
    let count = 1;
    this.state.roles.map((role) => {
      if (role.name === this.state.input.roleSelected) {
        this.count = role.levels.length + 1;
      }
    });

    axios
      .post(constants.endpoint + constants.level, {
        name: this.state.input.roleSelected,
        levelName: this.state.input.levelName,
        levelOrder: this.count,
      })
      .then((response) => {
        if (response.status === 201) {
          this.showError("success", "Level added successfully !");
          this.getRoles();
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          this.showError("danger", "Level already exists in role !");
        } else if (error.response.status === 400) {
          this.showError("warning", "Please provide proper inputs !");
        } else {
          this.showError("danger", "Something unexpected happened !");
        }
      })
      .then(() => {
        this.clearInput("levelName");
      });
    }
    else{
      axios.post(constants.endpoint + constants.editLevel,
          {
              roleName: this.state.input.roleSelected,
              levelName: this.state.input.levelName,
              oldName: this.state.input.oldName,
          }).then((response) => {
              this.setState({
                  ...this.state,
                  operation: 'Add',
                  input: {
                      ...this.state.input,
                      roleName: "",
                      levelName: "",
                      roleSelected:"",          
                  }
              }, () => {
                this.showError("success", "Level updated successfully !");
                this.getRoles();
              });
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.showError("danger", "Delete current level from all users first.");
            }else{
            this.showError("danger", "Something unexpected happened !");}
          });
  }
  };

  componentDidMount() {
    this.getRoles();
  }

  editRoles = (roleId, levelId) => {
    this.setState({
        ...this.state,
        input: {
            roleName : this.state.roles[roleId].name,
            roleSelected: this.state.roles[roleId].name,
            levelName: this.state.roles[roleId].levels[levelId].name,
            oldName: this.state.roles[roleId].levels[levelId].name,
            
        },
        operation: 'Edit'
    });
}

  deleteRoles = (roleId, levelId) => {
    axios.post(constants.endpoint + constants.deleteLevel,
        {
            roleName: this.state.roles[roleId].name,
            levelName: this.state.roles[roleId].levels[levelId].name
        }).then((response) => {
          this.showError("success", "Level deleted successfully !");
            this.clearInput("levelName");
            this.getRoles();
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.showError("danger", "Delete current level from all users first.");
          }
          else{
          this.showError("danger", "Something unexpected happened !");
          }
        });
}

resetEdit = () => {
  this.setState({
      ...this.state,
      operation: "Add",
      input: {
          ...this.state.input,
          roleName: "",
          roleSelected: "",
          oldName: '',
          levelName:""
      }
  })
}


roleDelete = (roleId, roleName) =>{
    axios.post(constants.endpoint + constants.deleteRole,
        {
            roleName: roleName,
        }).then((response) => {
          this.showError("success", "Role deleted successfully !");
            this.getRoles();
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.showError("danger", "Delete current role from all users first.");
          }
          else{
          this.showError("danger", "Something unexpected happened !");
          }
        });
}

  render() {
    return (
      <React.Fragment>
        <div className="content content-fixed">
          <div className="container col-12">
            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
              <div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Manage Roles & Levels
                    </li>
                  </ol>
                </nav>
                <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Roles & Levels Management</h4>
              </div>
            </div>

            <div className="row">
              <div
                id="errorPlaceholder"
                className="col-8"
                style={{ padding: "0px 30px" }}
              ></div>
            </div>

            <div className="row">

                <div className="col-6">
                  <div className="card card-body shadow-none">
                    <div className="marker marker-ribbon pos-absolute t-10 l-0">
                      Add Roles
                    </div>
                    <div className="row" style={{ marginTop: "32px" }}>
                      <div className="col-8">
                        <input
                          value={this.state.input.roleName}
                          onChange={(e) => this.handleInput(e, "roleName")}
                          type="text"
                          className="form-control"
                          placeholder="Enter role name"
                        />
                      </div>
                      <div className="col-4">
                        <button
                          onClick={this.addRole}
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          Add Role
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div
                    className="card card-body shadow-none"
                    style={{ minHeight: "110px" }}
                  >
                    <div className="marker marker-ribbon pos-absolute t-10 l-0">
                      Add Levels
                    </div>

                    <div className="row" style={{ marginTop: "32px" }}>
                      <div className="col-4">
                        <input
                          value={this.state.input.levelName}
                          onChange={(e) => this.handleInput(e, "levelName")}
                          type="text"
                          className="form-control"
                          placeholder="Enter level name"
                        />
                      </div>
                      <div className="col-4">
                        <select
                          value={this.state.input.roleSelected}
                          onChange={(e) => this.handleInput(e, "roleSelected")}
                          className="custom-select"
                        >
                          <option>Select Category</option>
                          {this.state.roles.map((role, index) => (
                            <option key={index} value={role.name}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-4">
                        <button
                          onClick={this.addLevel}
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          {this.state.operation+ ' Level'} 
                        </button>
                        <button type="button" onClick={this.resetEdit} className="btn btn-sm btn-primary ml-2">Reset</button>
                      </div>
                    </div>
                  </div>
    
              </div>

              
              <div className="col-12 mt-4">
              <RolesLadder 
                roles={this.state.roles}
                editRoles = {this.editRoles}
                deleteRoles = {this.deleteRoles}
                roleDelete = {this.roleDelete}
              />

              </div>

              {/* <div className="col-4">
                                
                                
                         </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default manageRoles;
