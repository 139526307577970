import React, { Component } from 'react';
import Aside from '../aside';
import axios from 'axios';
import * as constants from '../../../constants';
import { throws } from 'assert';
import { handleAlert } from './../../alertHandler/alertHandler';

class managerAdminReview extends Component {

    state = {
        managerFor : [],
        user : {
          role : '',
          manager : {
            name : ''
          }
        },
        questionSet : [],
        review : {},
        submission : {},
        isRenderCompleted : false,
        startReview : false,
        reviewing : '',
        userReviewed : {},
        isRatingGenerated : false,
        reviewingOrg : '',
        disableFinalize : false,
        sets: {},
        current: {
          active: 0
        }
  }

  jumpto = (item) => {
    this.setState({
      ...this.state,
      current: {
        active: item
      }
    }, () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
  }

  handlePage = (type) => {
    let current = this.state.current.active;
    if (type === "Next") {
      if (current < this.state.questionSet.length - 1) {
        current++;
      }
    } else {
      if (current != 0) {
        current--;
      }
    }
    this.setState({
      ...this.state,
      current: {
        active: current
      }
    }, () => {
      (this.state.submission.isPublished == false || this.state.submission.isPublished == "false" ) && this.submitSelfReview();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });

  }

  getAnswerSet = () => {
    axios.get(constants.endpoint + constants.userAnswerSet)
      .then((response) => {
        if (response.data.sets !== null) {
          this.setState({
            ...this.state,
            sets: response.data.sets.sets || {}
          });
        }
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      });
  }

      showError = (type, message, icon="fa-chalkboard-teacher") => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML = (`
            <div class="card" style="padding:25px">  
              <div class="text-center">
                <i style="font-size:64px" class="mt-5 fas ${icon} fa-lg"></i>
                <span class="mt-5 tx-uppercase tx-semibold mg-b-0 d-block">${message}</span>
              </div>
            </div>
            `);
        //this.state.submission.isPublished='true';
        this.setState({
          ...this.state,
          sendNotificationStatus: true,
          questionSet: [],
          submission: {},
          isRenderCompleted: false,
          startReview: false,
        })

      }

      startReview = () => {
        let userReviewed = {}
        this.state.managerFor.map((usr, index) => {
            if(usr.cvtid === this.state.reviewing){
              userReviewed = usr;
            }
        });
        this.setState({
          ...this.state,
          startReview : true,
          userReviewed : userReviewed
        });

        let role = userReviewed.role.split(" ").join("-")+"-"+userReviewed.level.split(" ").join("-");;
        this.getQuestionSet(role);
      }


      resetUser = () => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML = "";
        this.setState({
          ...this.state,
          questionSet : [],
          submission : {},
          isRenderCompleted : false,
          startReview : false,
          reviewing : '',
          isRatingGenerated : false,
          current: {
            active: 0
          }
        })
      }

      selectReviewEmployee = (e) => {
        let reviewingOrg = '';
        this.state.managerFor.map((user) => {
          if(user.cvtid === e.target.value){
            reviewingOrg = user.organisation
          }
        });
       // console.log(this.state);
        this.setState({
          ...this.state,
          reviewing : e.target.value,
          reviewingOrg : reviewingOrg
        });
      }
    
      getQuestionSet = (role) => {
        axios.get(constants.endpoint+constants.managerQuestionSet+"?role="+role+"&organisation="+this.state.reviewingOrg)
        .then((response) => {
               if(response.data.questionSet.length !== 0){
                this.setState({
                    ...this.state,
                    questionSet: response.data.questionSet[0].area
                })
               }
        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        }).then(() => {
          this.generateSubmission();
        }).then(() => {
          this.getSubmission();
        });
      }

      getTaggedAssociates = () => {
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.get(constants.endpoint+constants.user + "?organisation=" +organisation)
        .then((response) => {
          var users=[];
          response.data.map((user, index) => {
            if(user.activityRole !== "Owner" && user.activityRole !== "Admin" ){
              users.push(user);
              }
          })
          this.setState({
            ...this.state,
            managerFor: users
          })

        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        }).then(() => {
          this.getCurrentReview();
        });
      }
    
      getUser = () => {
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.get(constants.endpoint+constants.userDetails + "?organisation=" +organisation)
        .then((response) => {
          var users=[];
          
          this.setState({
            ...this.state,
            user: users
          })
        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        }).then(() => {
          this.getTaggedAssociates();
          this.getCurrentReview();
        });
      }
    
      getCurrentReview = () => {
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.get(constants.endpoint+constants.getCurrentReview + "?organisation="+ organisation)
        .then((response) => {
            if(response.data.review !== null){
              this.setState({
                  ...this.state,
                  review: response.data.review
              });
            }else{
              this.showError("warning", "No active review scheduled at the moment!");
            }
        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        });
      }
    
      componentWillMount(){
        if(localStorage.activityRole === "User" || localStorage.activityRole === "Manager"){
            window.location.href = "/?message=Unauthorized+Access+Or+Session+Expired";
        }
        this.getUser();
        this.getAnswerSet();
      }
    
      handleInput = (e,aIndex,qIndex,field) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.submission.area[aIndex].questions[qIndex][field] = e.target.value;

        if(field === "managerSelection"){
          tmpState.questionSet[aIndex].questions[qIndex].possibleAnswer.map((pA) => {
            if(e.target.value === pA.value){
              tmpState.submission.area[aIndex].questions[qIndex].managerRating = pA.weightage;
              tmpState.submission.area[aIndex].questions[qIndex].finalRating = pA.weightage
            }
          });
        }

        this.setState(tmpState);
      }
      previewReview = () => {
        axios.post(constants.endpoint + constants.managerSubmission + "?preview=true", {
          ...this.state.submission
        })
          .then((response) => {
    
            this.setState({
              ...this.state,
              submission: {
                ...this.state.submission,
                area : response.data.area,
                finalComments : response.data.finalComments,
                finalRating : response.data.finalRating,
              }
            }, () => {
              handleAlert({status:202},{202:'Rating preview generated'});
            })
          }).catch((error) => {
            console.log(error);
            this.showError("danger", "Something unexpected happened !");
          }).then(() => {
            window.scrollTo(0, 0);
          });
      }

      saveReview = () => {
        let tempArea = this.state.submission.area;
          for (var i = 0; i < tempArea.length; i++) {
            let questionSets = tempArea[i].questions;
            for (var j = 0; j < questionSets.length; j++) {
              if (questionSets[j].managerSelection == 'Select rating' || questionSets[j].managerSelection == "") {
                alert("You have not answered all the questions. Please select your answer.");
                return false;
              }
            }
          }
          let tmpSubmission = this.state.submission;
          tmpSubmission.isPublished = false;
          axios.post(constants.endpoint + constants.managerSubmission + "?finalSubmit=true", {
            ...tmpSubmission
          })
            .then((response) => {

              this.setState({
                ...this.state,
                submission: {
                  ...this.state.submission,
                  area : response.data.area,
                  finalComments : response.data.finalComments,
                  finalRating : response.data.finalRating,
                  isPublished: "true"
                }
              }, () => {
                handleAlert({status:201},{201:'User review for current quarter finalized successfully !'});
              })

            }).catch((error) => {
              console.log(error);
              this.showError("danger", "Something unexpected happened !");
            }).then(() => {
              window.scrollTo(0, 0);
            });
      };

      submitSelfReview = () => {
        let tmpSubmission = this.state.submission;
          tmpSubmission.isPublished = false;
          axios.post(constants.endpoint + constants.managerSubmission, {
            ...tmpSubmission
          })
            .then((response) => {
              
              handleAlert({status:201},{201:'Current review details updated successfully !'});

            }).catch((error) => {
              this.showError("danger", "Something that we don't expect happened ! Please report.", "fa-bug");
            }).then(() => {
              window.scrollTo(0, 0);
            });
      };
    
      getSubmission = () => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML = "";
        axios.post(constants.endpoint+constants.userSubmission,{
          cvtid : this.state.userReviewed.cvtid,
          reviewId : this.state.review.reviewId
        })
        .then((response) => {
               
          if(response.data.submission !== null && (response.data.submission.pendingWith.indexOf("Manager") !== -1)){
          response.data.submission.area.map((area, aIndex) => {
            area.questions.map((question, qIndex) => {
              if (question.managerRating == 0) {
                question.managerRating = question.selfRating;
                question.finalRating = question.selfRating;
                question.managerSelection = question.selfSelection;
              }
            });
          });
            this.setState({
              ...this.status,
              submission : response.data.submission
            })
          }else{
            this.showError("warning", "User has not started / completed the self review for the current quarter.", "fa-user-times");
          }
             
        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        });
      }

      getRatingMessage = (num) => {
        if(num <= 2){
          return "Needs Improvement";
        }else if(num <= 2.5){
          return "Achieves -";
        }else if(num <= 3){
          return "Achieves";
        }else if(num <= 3.25){
          return "Achieves +";
        }else if(num <= 3.5){
          return "Exceeds -";
        }else if(num <= 4){
          return "Exceeds";
        }else if(num <= 4.25){
          return "Exceeds +";
        }else if(num <= 4.5){
          return "Outstanding -";
        }else{
          return "Outstanding";
        }
      }

      generateRating = () => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        console.log(tmpState);
        let finalTotal = 0;
        let finalAreas = tmpState.submission.area.length;
        tmpState.submission.area.map((ar, aIndex) => {
          let arTotal = 0;
          let questions = ar.questions.length;

          ar.questions.map((ques, qIndex) => {
            arTotal += ques.managerRating;
          });

          ar.areatotal = Math.round((arTotal/questions) * 100) / 100;
          ar.areaComment = this.getRatingMessage(ar.areatotal);
          finalTotal += ar.areatotal;
        });

        tmpState.isRatingGenerated = true;
        tmpState.submission.finalRating = Math.round((finalTotal/finalAreas) * 100) / 100;
        tmpState.submission.finalComments = this.getRatingMessage(tmpState.submission.finalRating);

        this.setState(tmpState);
      }
    
      generateSubmission = () => {
    
        let subObj = {
          name : this.state.user.name,
          cvtid : this.state.user.cvtid,
          manager : this.state.user.manager,
          reviewId : this.state.review.reviewId,
          year : this.state.review.year,
          quarter : this.state.review.quarter,
          area : [],
          finalRating : 0,
          finalComments : "",
          isPublished : false,
          pendingWith : "User"
        };
    
        this.state.questionSet.map((ar, aIndex) => {
            let areaObject = {
                areaId : ar.areaId,
                areatotal : 0,
                areaComment : "",
                questions : []
            }; 
            ar.questions.map((ques, qIndex) => {
                let quesObj = {
                  questionId : ques.questionId,
                  answerSet : ques.answerSet,
                  selfRating : 0,
                  selfSelection : '',
                  selfExplain : '',
                  managerRating : 0,
                  managerSelection : '',
                  managerExplain : '',
                  finalRating : 0
                }; 
                areaObject.questions.push(quesObj);
            });
            subObj.area.push(areaObject);
        });
        this.setState({
          ...this.state,
          submission : subObj,
          isRenderCompleted : true
        });
      };
    
      getDisabled = (condition) => {
        return condition ? "disabled" : "";
      }

    render() {
        return (
            <React.Fragment>

            <div className="content content-fixed">
                    <div className="container col-12" style={{minHeight:'80vh'}}>
                      <div className="d-sm-flex align-items-center justify-content-between mg-b-10 mg-lg-b-10 mg-xl-b-10">
                        <div>
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                              <li className="breadcrumb-item">Dashboard</li>
                              <li className="breadcrumb-item active" aria-current="page">Current Growth Review</li>
                            </ol>
                          </nav>
                          <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">User Reviews</h4>
                        </div>
                      </div>
                      
                      <div className="row" style={{marginBottom:'25px'}}>
                        <div className="col-lg-12 col-xl-12 mg-t-10">
                            <div className="card" style={{padding:'25px'}}>
                                <div className="row">
                                    <div className="col-4">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Select Employee to review</h6>
                                        <div className="input-group mg-b-10">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><i class="fas fa-users"></i></span>
                                          </div>
                                        <select value={this.state.reviewing} onChange={this.selectReviewEmployee} disabled={this.state.startReview} className="custom-select">
                                            <option>Select Employee</option>
                                            {
                                              this.state.managerFor.sort(function(a, b) {
                                                if (a.name.toUpperCase() < b.name.toUpperCase()) {return -1;}
                                                if (a.name.toUpperCase() > b.name.toUpperCase()) {return 1;}return 0;
                                              }).map((user,uIndex) => (
                                                  <option key={uIndex} value={user.cvtid}>{user.name}</option>
                                              ))
                                            }
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button disabled={this.state.reviewing.length === 0} onClick={this.startReview} style={{position:'relative', top:'32%'}} type="button" className="btn btn-sm btn-primary"><i class="far fa-play-circle fa-lg"></i>&nbsp;&nbsp;Start Review</button>
                                        {/* <button disabled={this.state.reviewing.length === 0} onClick={this.startReview} style={{position:'relative', top:'38%',marginLeft:'20px'}} type="button" className="btn btn-sm btn-primary">Send Notification</button> */}
                                        <button onClick={this.resetUser} style={{position:'relative', top:'32%', marginLeft:'20px'}} type="button" className="btn btn-sm btn-danger"><i class="fas fa-ban fa-lg"></i>&nbsp;&nbsp;Reset</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                      </div>

                      <div className="row">
                        <div id="errorPlaceholder" className="col-lg-12 col-xl-12 mg-t-10">

                        </div>
                      </div>

                      {
                        this.state.startReview && (<div className="row" style={{display : Object.keys(this.state.review).length === 0 ? "none" : ""}}>

                        <div className="col-sm-6 col-lg-3">
                          <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="far fa-calendar-alt"></i> &nbsp;Review Year</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                              <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.review.year}</h3>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                          <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="far fa-calendar-minus"></i>&nbsp;Review Quarter</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                              <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.review.quarter}</h3>
                            </div>
                          </div>
                        </div>


                        <div className="col-sm-6 col-lg-3">
                          <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="fas fa-user"></i>&nbsp;Reviewing</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                              <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.userReviewed.name}</h3>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                          <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"><i class="fas fa-file-contract"></i>&nbsp;Role</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                              <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.userReviewed.role}-{this.state.userReviewed.level}</h3>
                            </div>
                          </div>
                        </div>

                        </div>)
                      }
                        

                          <div className="row" style={{padding:'15px',display : Object.keys(this.state.review).length === 0 ? "none" : ""}}>
                          <div className="col-lg-12 col-xl-12 mg-t-10" style={{padding:'0px', display : this.state.startReview ? "block" : "none"}}>
                              <div className="card" style={{padding:'25px'}}>

                                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Review Areas</h6>

                                  <ul className="steps">
                                    {
                                      this.state.questionSet.map((area,index) => (
                                        <li key={index} className="step-item">
		                          <a className={this.state.current.active == index ? 'step-link selected-area' : 'step-link'}
		                            onClick={() => this.jumpto(index)} style={{ cursor: 'pointer' }}>
                                          <span className="step-number">{index+1}</span>
                                          <span className="step-title">{area.name}</span>
                                          </a>
                                      </li>
                                      ))
                                    }
                                  
                                  </ul>
                              </div>
                          </div>
                          
                        
                        {
                          this.state.isRenderCompleted && this.state.questionSet.map((area,aIndex) => (
                  	<div key={aIndex} className="col-lg-12 col-xl-12 mg-t-10" style={{ padding: '0px', display: this.state.current.active == aIndex ? 'block' : 'none' }} area-id={aIndex}>
                              <div className="card">
    
                                <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                                <h6 className="tx-uppercase tx-15 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">{area.name}</h6>
                                </div>
    
                                <div className="card-body pd-10">
                                    {
                                      area.questions.map((question, qIndex) => (
                                        <React.Fragment key={qIndex}>
                                          <div className="row pd-20">
                                            <div className="col-12">
                                                <p>
                                    		<code>{question.questionId.split("-")[(question.questionId.split("-").length-1)]}</code> &nbsp;
                                                {question.text}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Self</span>
                                                <select 
                                                  disabled = {this.state.submission.pendingWith !== "User"}
                                                  value={this.state.submission.area[aIndex].questions[qIndex].selfSelection} 
                                                  disabled className="custom-select">
                                                    <option >Select self rating</option>
                                                    {
                                                      question.possibleAnswer.map((pA, pIndex) => (
                                                        <option key={pIndex} value={pA.value}>{pA.value}</option>
                                                      ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-6">
                                            <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Examples/Justifications of your answer </span>
                                                <span></span>
                                                <textarea 
                                                 
                                                  value={this.state.submission.area[aIndex].questions[qIndex].selfExplain} 
                                                  disabled className="form-control" rows="1" placeholder="Textarea"></textarea>
                                            </div>

                                            <div className="col-6" style={{marginTop:"10px"}}>
                                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Selection</span>
                                                <select 
                                                  disabled={this.state.submission.isPublished ==="true" ? "disabled" : null}
                                                  value={this.state.submission.area[aIndex].questions[qIndex].managerSelection} 
                                                  onChange={(e) => this.handleInput(e,aIndex,qIndex,"managerSelection")} className="custom-select">
                                                    <option>Select rating</option>
                                                    {
                                                      question.possibleAnswer.map((pA, pIndex) => (
                                                        <option key={pIndex} value={pA.value}>{pA.value}</option>
                                                      ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-6" style={{marginTop:"10px"}}>
                                            <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Comments </span>
                                                    
                                              <textarea 
                                                  disabled={this.state.submission.isPublished ==="true" ? "disabled" : null}
                                                  value={this.state.submission.area[aIndex].questions[qIndex].managerExplain} 
                                                  onChange={(e) => this.handleInput(e,aIndex,qIndex,"managerExplain")} className="form-control" rows="1" placeholder="Manager comments"></textarea>
                                            </div>

                                            <div className="col-12" style={{marginTop:'15px'}}>
                                                <div className="row">
                                                    <div className="offset-6 col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Self Rating : </span>
                                                      <code> {this.state.submission.area[aIndex].questions[qIndex].selfRating} </code>
                                                    </div>
                                                    <div className="col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Manager Rating : </span>
                                                      <code> {this.state.submission.area[aIndex].questions[qIndex].managerRating} </code>
                                                    </div>

                                                    <div className="col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Final Rating : </span>
                                                      <code> {this.state.submission.area[aIndex].questions[qIndex].finalRating} </code>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                                    {/* style={{display: area.questions.length-1 === qIndex ? "none" : "block"}} */}
                                        <hr className="mg-y-10" />
                                            <div className="col-12" style={{marginBottom : '5px',marginTop:'15px', display: area.questions.length-1 === qIndex ? "block" : "none"}}>
                                                <div className="row">
                                                    <div className="offset-6 col-4">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Comments : </span>
                                                      <code> {this.state.submission.area[aIndex].areaComment || "NA"} </code>
                                                    </div>
                                                    <div className="col-2">
                                                      <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Rating : </span>
                                                      <code> {this.state.submission.area[aIndex].areatotal} </code>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                      ))
                                    }
		                        <div className="text-right mt-3">
		                          <button disabled={this.state.current.active == 0} onClick={() => this.handlePage('Prev')} className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"><i class="fas fa-chevron-circle-left fa-lg"></i> &nbsp; Prev Area </button>
		                          <button disabled={((this.state.questionSet.length - 1) - this.state.current.active) == 0} onClick={() => this.handlePage('Next')} className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"> Next Area &nbsp; <i class="fas fa-chevron-circle-right fa-lg"></i></button>
		                        </div>

		                      </div>
		                    </div>
		                  </div>
                          ))
                        }

                      </div>

                      <div style={{display : Object.keys(this.state.review).length === 0 ? "none" : ""}}>
                        <div className="row" style={{position:'relative', marginBottom:'20px', display : this.state.startReview ? "" : "none"}}>
                          
                          <div className="col-3" style={{margin: 'auto 0px'}}>
                            <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Comments : </span>
                            <code> {this.state.submission.finalComments || "NA"} </code>
                          </div>
                          <div className="col-2" style={{margin: 'auto 0px'}}>
                            <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Rating : </span>
                            <code> {this.state.submission.finalRating} </code>
                          </div>
                          <div className="offset-2"></div>
	                {
	                  ((this.state.questionSet.length - 1) - this.state.current.active) == 0  && (<div className="col-5" style={{ display: this.state.isRenderCompleted ? "" : "none", textAlign: 'right' }}>
	                    <div className="btn-group" role="group" aria-label="Basic example">
	                      {/* <button disabled={this.state.submission.isPublished == "true" ? "disabled" : null} onClick={this.generateRating} type="button" className="btn btn-primary">Generate Rating</button> */}
                        <button style={{ display : this.state.submission.isPublished == false || this.state.submission.isPublished == "false"? 'block' : 'none'}} onClick={this.previewReview} type="button" className="btn btn-primary btn-sm"><i class="fas fa-spell-check fa-lg"></i> Preview Rating</button>
	                      <button style={{ display : this.state.submission.isPublished == false || this.state.submission.isPublished == "false"? 'block' : 'none'}} onClick={this.saveReview} type="button" className="btn btn-primary btn-sm"><i class="far fa-check-circle fa-lg"></i> Finalize Review</button>
	                      {/* <button type="button" disabled={!this.state.isRatingGenerated} onClick={this.submitSelfReview} className="btn btn-success">Finalize Review</button> */}
	                    </div>
	                  </div>)
	                }
                        </div>
                      </div>

                      
                    </div>
                  </div>

            </React.Fragment>
        );
    }
}

export default managerAdminReview;