import React, { Component } from "react";
import Header from "./../header/header";
import Footer from "./../footer/footer";
import axios from "axios";
import * as constants from "./../../constants";

import { handleAlert } from './../alertHandler/alertHandler';

class selfreview extends Component {
  state = {
    user: {
      role: "",
      manager: {
        name: "",
      },
    },
    questionSet: [],
    questionSetId: "",
    review: {},
    submission: {},
    isRenderCompleted: false,
    sets: {},
    current: {
      active: 0,
    },
  };

  showError = (type, message) => {
    document.querySelectorAll("#errorPlaceholder")[0].innerHTML = `
        <div class="alert alert-${type} alert-dismissible fade show" role="alert">
            <span>${message}</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    `;
  };

  getQuestionSet = () => {
    axios
      .get(constants.endpoint + constants.userQuestionSet)
      .then((response) => {
        if (response.data.questionSet.length !== 0) {
          this.setState({
            ...this.state,
            questionSetId: response.data.questionSet[0].setId,
            questionSet: response.data.questionSet[0].area,
          });
        }
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      })
      .then(() => {
        this.generateSubmission();
      })
      .then(() => {
        this.getSubmission();
      });
  };

  getUser = () => {
    axios
      .get(constants.endpoint + constants.userDetails)
      .then((response) => {
        this.setState({
          ...this.state,
          user: response.data.user,
        });
      })

      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      })
      .then(() => {
        this.getCurrentReview();
      });
  };

  getCurrentReview = () => {
    axios
      .get(constants.endpoint + constants.getCurrentReview + "?organisation=" + localStorage.organisation)
      .then((response) => {
        if (response.data.review !== null) {
          this.setState({
            ...this.state,
            review: response.data.review,
          });

        } else {
          this.showError(
            "warning",
            "No active review scheduled at the moment!"
          );
        }
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      })
      .then(() => {
        this.getQuestionSet();
      });
  };

  componentWillMount() {
    if (typeof localStorage.token === "undefined") {
      window.location.href = "/?message=Session+Expired";
    }
    this.getUser();
    this.getAnswerSet();
  }

  handleInput = (e, aIndex, qIndex, field) => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState.submission.area[aIndex].questions[qIndex][field] = e.target.value;

    if (field === "selfSelection") {
      tmpState.questionSet[aIndex].questions[qIndex].possibleAnswer.map(
        (pA) => {
          if (e.target.value === pA.value) {
            tmpState.submission.area[aIndex].questions[qIndex].selfRating =
              pA.weightage;
          }
        }
      );
    }

    this.setState(tmpState);
  };

  submitSelfReview = () => {
    axios
      .post(constants.endpoint + constants.submission, {
        ...this.state.submission,
        questionSetId: this.state.questionSetId,
        organisation: localStorage.organisation,
      })
      .then((response) => {
        if (response.status === 201) {
          handleAlert({status:201},{201:'Area details saved successfully.'});
        } else if (response.status === 202) {
          handleAlert({status:202},{202:'Area details saved successfully.'});
         // this.showError("success", "Updated Succesfully");
        }
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      })
      .then(() => {
        window.scrollTo(0, 0);
      });
  };

  getSubmission = () => {
    axios
      .get(
        constants.endpoint +
        constants.submission +
        "?reviewId=" +
        this.state.review.reviewId
      )
      .then((response) => {
        if (response.data.submission !== null) {
          this.setState({
            ...this.status,
            submission: response.data.submission,
          });
        }
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      });
  };

  getAnswerSet = () => {
    axios
      .get(constants.endpoint + constants.userAnswerSet)
      .then((response) => {
        this.setState({
          ...this.state,
          sets: response.data.sets.sets || {},
        });
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      });
  };

  jumpto = (item) => {
    this.setState(
      {
        ...this.state,
        current: {
          active: item,
        },
      },
      () => {
        if (!this.state.submission.isUserPublished) {
          this.submitSelfReview();
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    );
  };

  handlePage = (type) => {
    let current = this.state.current.active;
    if (type === "Next") {
      if (current < this.state.questionSet.length - 1) {
        current++;
      }
    } else {
      if (current != 0) {
        current--;
      }
    }
    this.setState(
      {
        ...this.state,
        current: {
          active: current,
        },
      },
      () => {
        if (!this.state.submission.isUserPublished) {
          this.submitSelfReview();
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    );
  };

  finalizeReview = () => {
    
    this.submitSelfReview();

    let tempArea = this.state.submission.area;
    for (var i = 0; i < tempArea.length; i++) {
      let questionSets = tempArea[i].questions;
      for (var j = 0; j < questionSets.length; j++) {
        if (questionSets[j].selfRating == 0 || questionSets[j].selfRating === 'Select rating') {
          handleAlert({status:400},{400: `Area ${i+1} : Question ${j+1} and more not selected. Please select option to finalize review.`});
          return false;
        }
      }
    }
    var confirmFinalize = window.confirm(
      "Are you sure ? Once finalized you will not be able to edit the review again."
    );
    if (confirmFinalize == true) {
      axios
        .post(constants.endpoint + constants.finalizeReview, {
          cvtid: this.state.submission.cvtid,
          reviewId: this.state.submission.reviewId,
        })
        .then((response) => {
          this.setState(
            {
              ...this.state,
              submission: {
                ...this.state.submission,
                isUserPublished: true,
              },
            },
            () => {
              if (response.status == 201) {
                this.showError("success", "Review submitted for manager review.");
              } else if (response.status == 202) {
                this.showError("success", "Review submitted for manager review.");
              }
            }
          );
        })
        .catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        })
        .then(() => {
          window.scrollTo(0, 0);
        });
    } else {
      // no response
    }
  };

  generateSubmission = () => {
    let subObj = {
      name: this.state.user.name,
      cvtid: this.state.user.cvtid,
      manager: this.state.user.manager,
      reviewId: this.state.review.reviewId,
      year: this.state.review.year,
      quarter: this.state.review.quarter,
      questionSetId: this.state.questionSetId,
      area: [],
      finalRating: 0,
      finalComments: "",
      isPublished: false,
      pendingWith: "User",
    };

    this.state.questionSet.map((ar, aIndex) => {
      let areaObject = {
        areaId: ar.areaId,
        areatotal: 0,
        areaComment: "",
        questions: [],
      };
      ar.questions.map((ques, qIndex) => {
        let quesObj = {
          questionId: ques.questionId,
          answerSet: ques.answerSet,
          selfRating: 0,
          selfSelection: "",
          selfExplain: "",
          managerRating: 0,
          managerSelection: "",
          managerExplain: "",
          finalRating: 0,
        };
        areaObject.questions.push(quesObj);
      });
      subObj.area.push(areaObject);
    });
    this.setState({
      ...this.state,
      submission: subObj,
      isRenderCompleted: true,
    });
  };

  previousReview = () => {
    window.location.href = "/previousReviews";
  };

  viewNotifications = () =>{
    window.location.href = "/notifications";
  }
  

  render() {
    return (
      <React.Fragment>
        <Header />

        <div className="content content-fixed" style={{ minHeight: "88vh" }}>
          <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
              <div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Performance Review
                    </li>
                  </ol>
                </nav>
                <h4 className="mg-b-0 tx-spacing--1">
                  Welcome {this.state.user.name}
                </h4>
              </div>
              <div className="d-none d-md-block">

              {/* <a
                  href="/manager/dashboard"
                  style={{
                    display:
                      localStorage.activityRole == "Manager"
                        ? "inline-block"
                        : "none",
                  }}
                  className="btn btn-sm pd-x-15 btn-primary mr-2"
                  target="_self"
                >
                  Back to Dashboard</a>  */}
                <a
                  href="/employee/review"
                  style={{
                    display:
                      localStorage.activityRole == "Manager"
                        ? "inline-block"
                        : "none",
                  }}
                  className="btn btn-sm pd-x-15 btn-primary mr-2"
                  target="_self"
                >
                  <i class="fas fa-users"></i> &nbsp;
                  Team Scores</a>

                <a
                  href="/manager/review"
                  style={{
                    display:
                      localStorage.activityRole == "Manager"
                        ? "inline-block"
                        : "none",
                  }}
                  className="btn btn-sm pd-x-15 btn-primary mr-2"
                  target="_self"
                >
                  <i class="fas fa-users-cog"></i> &nbsp;
                  Team Reviews
                </a>
                {/* <a
                  href="/manager/promotions"
                  style={{
                    display:
                      localStorage.activityRole == "Manager"
                        ? "inline-block"
                        : "none",
                  }}
                  className="btn btn-sm pd-x-15 btn-primary mr-2"
                  target="_self"
                >
                  Promotion List
                </a> */}
                <button
                  onClick={this.previousReview}
                  style={{ display: "inline-block" }}
                  className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 mr-1"
                >
                  <i class="far fa-calendar-alt"></i> &nbsp;
                  Previous Reviews
                </button>
                {/* <button
                  onClick={this.viewNotifications}
                  style={{ display: "inline-block" }}
                  className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 mr-1"
                >

                  View Notification
                </button> */}



              </div>
            </div>

            <div className="row">
              <div id="errorPlaceholder" className="col-12"></div>
            </div>

            <div
              className="row row-xs"
              style={{
                display:
                  Object.keys(this.state.review).length === 0 ? "none" : "",
              }}
            >
              <div className="col-sm-6 col-lg-3">
                <div className="card card-body">
                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                  <i class="far fa-calendar-alt"></i> &nbsp;Review Year
                  </h6>
                  <div className="d-flex d-lg-block d-xl-flex align-items-end">
                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
                      {this.state.review.year}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="card card-body">
                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                  <i class="far fa-calendar-minus"></i>&nbsp;Review Quarter
                  </h6>
                  <div className="d-flex d-lg-block d-xl-flex align-items-end">
                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
                      {this.state.review.quarter}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="card card-body">
                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                  <i class="far fa-calendar-check"></i> &nbsp;Freezes On
                  </h6>
                  <div className="d-flex d-lg-block d-xl-flex align-items-end">
                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
                      {this.state.review.freeze}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="card card-body">
                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                  <i class="fas fa-users"></i> &nbsp;Manager
                  </h6>
                  <div className="d-flex d-lg-block d-xl-flex align-items-end">
                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
                      { this.state.user.manager.length && this.state.user.manager.map((manager,index) => (
                          <span key={index}>{manager.name} , </span>
                      )) }
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                padding: "15px",
                display:
                  Object.keys(this.state.review).length === 0 ? "none" : "",
              }}
            >
              <div
                className="col-lg-12 col-xl-12 mg-t-10"
                style={{ padding: "0px" }}
              >
                <div className="card" style={{ padding: "25px" }}>
                  {this.state.questionSet.length > 0 && (
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Review Areas
                    </h6>
                  )}
                  
                  {
                    this.state.questionSet.length === 0 && (
                      <div className="text-center">
                        <i style={{fontSize:'64px'}} class="mt-5 fas fa-chalkboard-teacher fa-lg"></i>
                        <span className="mt-5 tx-uppercase tx-semibold mg-b-0 d-block">Sorry ! No Active Review set found for your role.</span>
                      </div>
                    )
                  }

                  <ul className="steps">
                    { this.state.questionSet.map((area, index) => (
                      <li key={index} className="step-item">
                        <a
                          className={
                            this.state.current.active == index
                              ? "step-link selected-area"
                              : "step-link"
                          }
                          onClick={() => this.jumpto(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="step-number">{index + 1}</span>
                          <span className="step-title">{area.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {this.state.isRenderCompleted &&
                this.state.questionSet.map((area, aIndex) => (
                  <div
                    key={aIndex}
                    className="col-lg-12 col-xl-12 mg-t-10"
                    style={{
                      padding: "0px",
                      display:
                        this.state.current.active == aIndex ? "block" : "none",
                    }}
                    area-id={aIndex}
                  >
                    <div className="card">
                      <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                        <h6 className="tx-uppercase tx-15 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                          {area.name}
                        </h6>
                      </div>

                      <div className="card-body pd-10">
                        {area.questions.map((question, qIndex) => (
                          <React.Fragment key={qIndex}>
                            <div className="row pd-20">
                              <div className="col-12">
                                <p>
                                  <code>
                                    {
                                      question.questionId.split("-")[
                                      question.questionId.split("-").length -
                                      1
                                      ]
                                    }
                                  </code>{" "}
                                  &nbsp;
                                  {question.text}
                                </p>
                              </div>
                              <div className="col-6">
                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                  Self
                                </span>
                                <select
                                  disabled={
                                    this.state.submission.isUserPublished ==
                                      true
                                      ? "disabled"
                                      : null
                                  }
                                  value={
                                    this.state.submission.area[aIndex]
                                      .questions[qIndex].selfSelection
                                  }
                                  onChange={(e) =>
                                    this.handleInput(
                                      e,
                                      aIndex,
                                      qIndex,
                                      "selfSelection"
                                    )
                                  }
                                  className="custom-select"
                                >
                                  <option>Select self rating</option>
                                  {question.possibleAnswer.map((pA, pIndex) => (
                                    <option key={pIndex} value={pA.value}>
                                      {pA.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-6">
                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                  Examples/Justifications of your answer{" "}
                                </span>
                                <textarea
                                  disabled={
                                    this.state.submission.isUserPublished ==
                                      true
                                      ? "disabled"
                                      : null
                                  }
                                  value={
                                    this.state.submission.area[aIndex]
                                      .questions[qIndex].selfExplain
                                  }
                                  onChange={(e) =>
                                    this.handleInput(
                                      e,
                                      aIndex,
                                      qIndex,
                                      "selfExplain"
                                    )
                                  }
                                  className="form-control"
                                  rows="1"
                                  placeholder="Textarea"
                                ></textarea>
                              </div>

                              <div
                                className="col-6"
                                style={{
                                  marginTop: "10px",
                                  display:
                                    this.state.submission.isPublished === "true"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                  Manager Selection
                                </span>
                                <select
                                  value={
                                    this.state.submission.area[aIndex]
                                      .questions[qIndex].managerSelection
                                  }
                                  disabled
                                  className="custom-select"
                                >
                                  <option>Select rating</option>
                                  {question.possibleAnswer.map((pA, pIndex) => (
                                    <option key={pIndex} value={pA.value}>
                                      {pA.value}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div
                                className="col-6"
                                style={{
                                  marginTop: "10px",
                                  display:
                                    this.state.submission.isPublished === "true"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <span className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                  Manager Comments{" "}
                                </span>
                                <textarea
                                  value={
                                    this.state.submission.area[aIndex]
                                      .questions[qIndex].managerExplain
                                  }
                                  disabled
                                  className="form-control"
                                  rows="1"
                                  placeholder="Manager comments"
                                ></textarea>
                              </div>

                              <div
                                className="col-12"
                                style={{
                                  marginTop: "15px",
                                  display:
                                    this.state.submission.isPublished === "true"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <div className="row">
                                  <div className="offset-6 col-2">
                                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                      Self Rating :{" "}
                                    </span>
                                    <code>
                                      {" "}
                                      {
                                        this.state.submission.area[aIndex]
                                          .questions[qIndex].selfRating
                                      }{" "}
                                    </code>
                                  </div>
                                  <div className="col-2">
                                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                      Manager Rating :{" "}
                                    </span>
                                    <code>
                                      {" "}
                                      {
                                        this.state.submission.area[aIndex]
                                          .questions[qIndex].managerRating
                                      }{" "}
                                    </code>
                                  </div>

                                  <div className="col-2">
                                    <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                      Final Rating :{" "}
                                    </span>
                                    <code>
                                      {" "}
                                      {
                                        this.state.submission.area[aIndex]
                                          .questions[qIndex].finalRating
                                      }{" "}
                                    </code>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <hr className="mg-y-10" />
                            <div
                              className="col-12"
                              style={{
                                marginBottom: "5px",
                                marginTop: "15px",
                                display:
                                  area.questions.length - 1 === qIndex
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <div
                                className="row"
                                style={{
                                  display:
                                    this.state.submission.isPublished === "true"
                                      ? ""
                                      : "none",
                                }}
                              >
                                <div className="offset-6 col-4">
                                  <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                    Comments :{" "}
                                  </span>
                                  <code>
                                    {" "}
                                    {this.state.submission.area[aIndex]
                                      .areaComment || "NA"}{" "}
                                  </code>
                                </div>
                                <div className="col-2">
                                  <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                    Rating :{" "}
                                  </span>
                                  <code>
                                    {" "}
                                    {
                                      this.state.submission.area[aIndex]
                                        .areatotal
                                    }{" "}
                                  </code>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                        <div className="text-right mb-2 mt-2">
                          <button
                            disabled={this.state.current.active == 0}
                            onClick={() => this.handlePage("Prev")}
                            className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"
                          >
                            <i class="fas fa-chevron-circle-left fa-lg"></i> &nbsp;
                            <span> Prev Area</span>
                          </button>
                          <button
                            disabled={
                              this.state.questionSet.length -
                              1 -
                              this.state.current.active ==
                              0
                            }
                            onClick={() => this.handlePage("Next")}
                            className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"
                          >
                            
                            <span>Next Area</span> &nbsp; <i class="fas fa-chevron-circle-right fa-lg"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div
              className="row"
              style={{
                position: "relative",
                marginBottom: "20px",
                display:
                  this.state.submission.isPublished === "true" ? "" : "none",
              }}
            >
              <div className="offset-4 col-3">
                <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                  Perofrmance Review :{" "}
                </span>
                <code> {this.state.review.reviewId || "NA"} </code>
              </div>
              <div className="col-3">
                <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                  Comments :{" "}
                </span>
                <code> {this.state.submission.finalComments || "NA"} </code>
              </div>
              <div className="col-2">
                <span className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                  Rating :{" "}
                </span>
                <code> {this.state.submission.finalRating} </code>
              </div>
            </div>

            <div
              style={{
                display:
                  Object.keys(this.state.review).length === 0 ? "none" : "",
              }}
            >
              {this.state.questionSet.length - 1 - this.state.current.active ==
                0 && (
                  <div
                    className="row"
                    style={{
                      position: "relative",
                      marginBottom: "20px",
                      display:
                        this.state.submission.isUserPublished == true
                          ? "none"
                          : "",
                    }}
                  >
                    <button
                      onClick={this.finalizeReview}
                      style={{
                        position: "absolute",
                        right: "15px",
                        display: this.state.isRenderCompleted ? "" : "none",
                      }}
                      className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
                    >
                      <i class="far fa-check-circle fa-lg"></i> Submit Self Review
                  </button>
                  </div>
                )}
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default selfreview;
