import React, { Component } from 'react';

import Roles from './roles';
import ReviewStats from './reviewstats';
import PerformanceChart from './performanceChart';
import AreaChart from './areaChart';

class index extends Component {

    state = {
        
    }

    render() {
        return (
            <React.Fragment>
        
            <div className="content content-fixed">
                <div className="container col-12">

                <div className="d-sm-flex align-items-center justify-content-between mg-b-10 mg-lg-b-10 mg-xl-b-10">
                    <div>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                        </nav>
                        <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Welcome to Dashboard</h4>
                    </div>
                    </div>
            
                    <div className="row">
                        <div className="col-lg-8 col-md-8 mg-t-10">
                            <Roles/>
                        </div>
                        <div class="col-sm-5 col-md-4 col-lg-4 mg-t-10">
                            <ReviewStats/>
                        </div>
                        <div className="col-lg-8 col-md-8 mg-t-10">
                            <AreaChart/>
                        </div>
                        <div class="col-sm-5 col-md-4 col-lg-4 mg-t-10">
                                <PerformanceChart/>
                        </div>
                    </div>
                </div>
            </div>
            
        </React.Fragment>
        );
    }
}

export default index;