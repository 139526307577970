import React, { Component } from 'react';
import axios from 'axios';
import * as constants from '../../../constants';

import DataTable from "react-data-table-component";
import { error } from 'jquery';
import Modal from 'react-bootstrap4-modal';

class promotions extends Component {

    state = {
        user : [],
        promotionList:[],
        selectedUser : {},
        roles : [],
        levels : [],
        selectedRole : '',
        selectedLevel : '',
        rejectList:[],
        showModal:false,
        description:"",
        rowData:{},
        showPromoted:false,
        showRejected:false,
    }



    promote = (row) => {
        axios.post(constants.endpoint+constants.promote, {
            cvtid : row.cvtid,
            newLevel : row.newLevel,
            newRole : row.newRole,
            organisation : row.organisation,
            managerReason : this.state.description,
            promoteId : row.promoteId,

        }).then((response) => {
            this.setState({showModal:false})
            if (response.status === 201) {
                this.showError("success", "Employee Promoted !");
                this.getPromotionList()
              }
        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        });
    }

    rejectPromotion = (row) => {
        axios.post(constants.endpoint + constants.updatePromotionStatus, {
            cvtid : row.cvtid,
            managerReason : this.state.description, 
            promoteId : row.promoteId,
        }).then((response)=>{
            
            if (response.status === 201) {
                this.setState({showModal:false})
                this.showError("success", "Promotion Rejected Successfully!");
                this.getPromotionList()
              }
        }).catch((error) => {
            this.showError("danger", "Something unexpected happened !");
          });
    }


    getPromotionList =()=>{
        let organisation = localStorage.activityRole==="Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        var promotionList=[];
        var rejectList=[];
        var promotionRequests=[];

        axios.get(constants.endpoint+constants.promote + "?organisation=" + organisation)
        .then((response)=>{
          response.data.map((promotion, index) => {
              if(promotion.status === "Promoted"){
                promotionList.push(promotion);
              }
              else if(promotion.status === "Rejected"){
                rejectList.push(promotion);
              }
              else if(promotion.status === "Pending"){
                promotionRequests.push(promotion);
              }
          }
        )

        this.setState({
          promotionList : promotionList,
          rejectList : rejectList,
          promotionRequests : promotionRequests,
        })
          
        }).catch((error)=>{
            this.showError("danger","Something unexpected happened !");
        })
    }

    downloadDocument=(docName)=>{

      window.open(constants.endpoint+constants.downloadDocument + "/" + docName, '_blank')
    }

    

    componentDidMount(){
        this.getPromotionList();
    }

    openModal = (row)=>{
      this.setState({
        showModal:true,
        rowData: row,
      })
    }
    closeModal=()=>{
      this.setState({
        showModal:false,
      })
    }

    onDescInput = (e) => {
      this.setState({
          description: e.target.value
      });
  }

    showError = (type, message) => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML  = (`
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `);
    };

    showPromoted=()=>{
      this.setState({showPromoted:true,showRejected:false})
    }

    showRejected=()=>{
      this.setState({showPromoted:false,showRejected:true})
    }

    showRequests=()=>{
      this.setState({showPromoted:false,showRejected:false})
    }

    render() {
        const columns = [
            {
                name: "Employee Id",
                selector: "cvtid",
                sortable: true,
                right: false,
              },
              {
                name: "Name",
                selector: "name",
                sortable: true,
                right: false,
              },
              {
                name: "Organisation",
                selector: "organisation",
                sortable: true,
                right: true,
                right: false,
              },
              {
                name: "Previous Role",
                selector: "currentRole",
                sortable: true,
                right: true,
                right: false,
              },
              {
                name: "Previous Level",
                selector: "currentLevel",
                sortable: true,
                right: false,
              },
              {
                name: "New Role",
                selector: "newRole",
                sortable: true,
                right: false,
              },
              
              {
                name: "New Level",
                selector: "newLevel",
                sortable: true,
                right: false,
              },
              {
                name: "Result Date",
                sortable: true,
                right: false,
                cell: (row) => (
                <h6>{row.resultDate!=null?row.resultDate.slice(0,10):''}</h6>
                ),
              },
              {
                name: "Manger Reason",
                selector: "managerReason",
                sortable: true,
                right: false,
              },
              {
                name: "Status",
                selector: "status",
                sortable: true,
                right: false,
              }
           
          ];


    const requestColumns=[
        {
            name: "Employee Id",
            selector: "cvtid",
            sortable: true,
            right: false,
          },
          {
            name: "Name",
            selector: "name",
            sortable: true,
            right: false,
          },
          {
            name: "Organisation",
            selector: "organisation",
            sortable: true,
            right: true,
            right: false,
          },
          {
            name: "New Role",
            selector: "newRole",
            sortable: true,
            right: true,
            right: false,
          },
          {
            name: "New Level",
            selector: "newLevel",
            sortable: true,
            right: false,
          },
          
          {
            name: "Previous Role",
            selector: "currentRole",
            sortable: true,
            right: true,
            right: false,
          },
          {
            name: "Previous Level",
            selector: "currentLevel",
            sortable: true,
            right: false,
          },
          {
            name: "Requested On",
            sortable: true,
            right: false,
            cell: (row) => (
                  <h6>{row.requestDate!=null?row.requestDate.slice(0,10):''}</h6>
                ),
          },
          
          {
            name: "Accept",
            right: false,
            cell: (row) => (
              <button
                    onClick={()=>this.openModal(row)}
                    type="button"
                    className="btn btn-xs btn-success"
                  >
                    Decision
            </button>

            ),
          },
          
          {
            name: "Promotion Document",
            right: false,
            cell: (row) => (
              <button
                onClick={(e) => this.downloadDocument(row.sheet)}
                type="button"
                className="btn btn-xs btn-secondary"
              >
                Download
              </button>
            ),
          },
          
    ];

    const reasonColumns=[
      {
          name: "Manager Id",
          selector: "managerId",
          sortable: true,
          grow : 5,
          right: false,
        },
        {
          name: "Manager",
          selector: "managerName",
          grow : 5,
          sortable: true,
          right: false,
        },
        {
          name: "Response",
          cell: (row) => (
           <a>
             {row.reason}
           </a>

          ),
          grow : 10,
          interactive:true
        },
        {
          name: "Approved",
          selector: "approved",
          sortable: true,
          
        },
  ]

  

      
        return (
            <React.Fragment>
            <div className="content content-fixed">
                <div className="container col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-10 mg-lg-b-10 mg-xl-b-10">
                    <div>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item">Dashboard</li>
                            <li className="breadcrumb-item active" aria-current="page">Manage Promotions</li>
                        </ol>
                        </nav>
                        <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Promotions Management</h4>
                    </div>
                    <div className="row">
                      <button className="btn btn-primary btn-xs mr-2" onClick={this.showPromoted}>Promoted</button>
                      <button className="btn btn-primary btn-xs mr-2" onClick={this.showRejected}>Rejected</button>
                      <button className="btn btn-primary btn-xs" onClick={this.showRequests} style={{display:(this.state.showPromoted || this.state.showRejected)?"block":"none"}}>Back</button>
                    </div>
                    </div>

                    {/* Error Handler */}
                    <div className="row">
                        <div id="errorPlaceholder" className="col-12">
                            
                        </div>
                    </div>

                </div>

                

                <div className="col-lg-12 col-xl-12 mg-t-10" style={{display:(!this.state.showPromoted && !this.state.showRejected)?"block":"none"}}>
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Promote Request
                    </h6>
                  </div>

                  <div className="card-body p-0">
                        
                    <DataTable
                      columns={requestColumns}
                      data={this.state.promotionRequests}
                      fixedHeader
                      pagination
                    />
                  </div>
                </div>
              </div>

                <div className="col-lg-12 col-xl-12 mg-t-10" style={{display:(this.state.showPromoted && !this.state.showRejected)?"block":"none"}}>
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Promoted List
                    </h6>
                  </div>

                  <div className="card-body p-0">
                    <DataTable
                      columns={columns}
                      data={this.state.promotionList}
                      fixedHeader
                      pagination
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-xl-12 mg-t-10" style={{display:(!this.state.showPromoted && this.state.showRejected)?"block":"none"}}>
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Rejected List
                    </h6>
                  </div>


                  <div className="card-body p-0">
                    <DataTable
                      columns={columns}
                      data={this.state.rejectList}
                      fixedHeader
                      pagination
                      allowOverflow
                      responsive 
                    />
                  </div>
                </div>
              </div>

              <Modal visible={this.state.showModal} onClickBackdrop={this.closeModal}>

                    <div className="modal-header">
                        <h5 className="modal-title">Promotion Result</h5>
                    </div>
                    <div  className="modal-body">

                                          <DataTable
                                            columns={reasonColumns}
                                            data={this.state.rowData.promoteReason}
                                            fixedHeader
                                            pagination
                                            responsive
                                            wrap
                                            />

                        <div className="col-12">
                            <form>
                                <div className="mt-2">
                                    <label htmlFor="description" className="font-weight-bold">Enter Reason</label>
                                    <textarea value={this.state.description} onChange={this.onDescInput} className="form-control" rows="3" placeholder="Describe your reason"></textarea>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={()=> this.promote(this.state.rowData)}>
                            Promote
                    </button>
                        <button type="button" className="btn btn-success" onClick={(e) => this.rejectPromotion(this.state.rowData)}>
                            Reject
                    </button>
                </div>
              </Modal>
            </div>
            </React.Fragment>
        );
    }
}

export default promotions;