import React, { Component } from 'react';
import Aside from './../aside';
import axios from 'axios';
import * as constants from './../../../constants';

class manageQuestions extends Component {

    state = {
        selectedRole: '',
        startCreation: false,
        area: [],
        roles: [],
        roleGroup: [],
        selectedLevelGroup: [],
        selectedLevel: '',
        copyToRole: '',
        copyToLevel: '',
        copyToOrg: '',
        copyToLevels: [],
        sets: {},
        organisations: [],
        newRoles: [],
        newRoleGroup: []
    }


    getAnswerSet = () => {
        axios.get(constants.endpoint + constants.userAnswerSet)
            .then((response) => {
                if (response.data.sets !== null) {
                    this.setState({
                        ...this.state,
                        sets: response.data.sets.sets || {}
                    });
                }
            })
            .catch((error) => {
                this.showError("danger", "Something kunexpected happened !");
            });
    }

    reset = () => {
        this.setState({
            ...this.state,
            selectedRole: '',
            startCreation: false,
            selectedLevelGroup: [],
            selectedLevel: '',
            area: []
        });
    }

    copyOrganisation = (e, field) => {
        this.setState({
            ...this.state,
            copyToOrg: e.target.value
        })
        this.getRoles(e.target.value);
    }

    copyRole = (e, field) => {
        var levels = [];
        this.state.newRoleGroup.map((role) => {
            if (role.name === e.target.value) {
                levels = role.levels;
            }
            else if (role.name == e.target.value.split("-").join(" ")) {
                levels = role.levels;
            }
        });

        let tmpState = JSON.parse(JSON.stringify(this.state))
        tmpState.copyToRole = e.target.value;
        tmpState.copyToLevels = levels;

        this.setState(tmpState);
    }

    copyLevel = (e, field) => {
        this.setState({
            ...this.state,
            copyToLevel: e.target.value
        })
    }


    copy = () => {
        
        
        if(this.state.copyToOrg==="" || this.state.copyToOrg==="Select Organisation" || this.state.copyToLevel==="" || this.state.copyToRole==="" || this.state.copyToRole==="Select Role"){
            this.showError("danger", "Please provide valid input.");
            return false;
        }
        
        let organisation = localStorage.activityRole === "Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        axios.post(constants.endpoint + constants.updateQuestionSet, {
            role: this.state.copyToRole,
            level: this.state.copyToLevel,
            area: this.state.area,
            organisation: this.state.copyToOrg === "" ? organisation : this.state.copyToOrg,
            operation: 'copy',
            originalOrg: organisation,
        }).then((response) => {
            if (response.status === 201) {
                this.showError("success", "Question set copied successfully !");
            } else if (response.status === 202) {
                this.showError("success", "Question set updated successfully !");
            }
        })
            .catch((error) => {
                this.showError("danger", "Something unexpected happened !");
            });
    }

    onRoleChange = (e) => {
        
        let levels = [];

        this.state.roleGroup.map((role) => {
            if (role.name === e.target.value) {
                levels = role.levels;
            }
            else if (role.name === e.target.value.split("-").join(" ")) {
                levels = role.levels;
            }
        });

        this.setState({
            ...this.state,
            selectedRole: e.target.value,
            selectedLevelGroup: levels
        });
    }

    onLevelChange = (e) => {
        this.setState({
            ...this.state,
            selectedLevel: e.target.value.split(" ").join("-")
        });
    }

    getRoles = (organisation) => {
        axios.get(constants.endpoint + constants.role + "?organisation=" + organisation)
            .then((response) => {
                let roles = [];
                response.data.roles.map((role) => {
                    var rl = role.name.split(" ").join("-");
                    roles.push(rl);
                })
                this.setState({
                    ...this.state,
                    roles: roles,
                    roleGroup: response.data.roles,
                    newRoles: roles,
                    newRoleGroup: response.data.roles,
                });
            })
            .catch((error) => {
                this.showError("danger", "Unable to fetch roles !")
            });
    }

    onManageClick = (e) => {
        this.setState({
            ...this.state
        });
        let organisation = localStorage.activityRole === "Admin" ? localStorage.organisation : localStorage.selectedOrganisation;

        axios.get(constants.endpoint + constants.updateQuestionSet +
            "?role=" + this.state.selectedRole + "-" + this.state.selectedLevel + "&organisation=" + organisation)
            .then((response) => {
                if (response.data.questionSet.length != 0) {
                    this.setState({
                        ...this.state,
                        area: response.data.questionSet[0].area,
                        startCreation: true
                    })
                } else {
                    this.setState({
                        ...this.state,
                        startCreation: true
                    })
                }
            })
            .catch((error) => {

                this.showError("danger", "Something unexpected happened !");
            });
    }

    onAddAreaClick = (e) => {

        let areaId = this.state.area.length + 1;

        let area = {
            areaId: this.state.selectedRole.split(" ").join("-") + "-" + areaId,
            name: "",
            questions: []
        }

        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.area.push(area);

        this.setState(tmpState);
    }

    addQuestion = (e, index) => {
        let questionId = this.state.area[index].questions.length + 1;
        let question = {
            questionId: this.state.area[index].areaId + "." + questionId,
            text: "",
            possibleAnswer: [],
            answerSet: ""
        };
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.area[index].questions.push(question);
        this.setState(tmpState);
    }

    handleAreaInput = (e, index) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.area[index].name = e.target.value;
        this.setState(tmpState);
    }

    handleQuestionInput = (e, aIndex, qIndex) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.area[aIndex].questions[qIndex].text = e.target.value;
        this.setState(tmpState);
    }

    selectPossibleAnswer = (e, aIndex, qIndex) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.area[aIndex].questions[qIndex].answerSet = e.target.value;
        tmpState.area[aIndex].questions[qIndex].possibleAnswer = this.state.sets[e.target.value];
        this.setState(tmpState);
    }

    updateQuestionSet = () => {
        
        let errorCount = 0;
        for (var i = 0; i < this.state.area.length; i++) {
            if (this.state.area[i].name == "") {
                this.showError("danger", "Area name cannot be empty !");
                errorCount++;
                break;
            }
            if (this.state.area[i].questions.length < 1) {
                this.showError("danger", "Question set cannot be empty !");
                errorCount++;
                break;
            }
        }
        if (errorCount == 0) {
            let organisation = localStorage.activityRole === "Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
            axios.post(constants.endpoint + constants.updateQuestionSet, {
                role: this.state.selectedRole,
                level: this.state.selectedLevel,
                organisation: organisation,
                area: this.state.area,
                operation: 'create'
            }).then((response) => {
                if (response.status === 201) {
                    this.showError("success", "Question set added successfully !");
                } else if (response.status === 202) {
                    this.showError("success", "Question set updated successfully !");
                }
            })
                .catch((error) => {
                    if (error.response.status === 400) {
                        this.showError("danger", "Answer Set cannot be empty !");
                    }
                    else {
                        this.showError("danger", "Something unexpected happened !");
                    }
                });
        }
    }

    showError = (type, message) => {
        document.querySelectorAll("#errorPlaceholder")[0].innerHTML = (`
            <div class="alert alert-${type} alert-dismissible fade show" role="alert">
                <span>${message}</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        `);
    }

    componentDidMount() {
        let organisation = localStorage.activityRole === "Admin" ? localStorage.organisation : localStorage.selectedOrganisation;
        this.getRoles(organisation);
        this.getAnswerSet();
        this.getOrganisations();
    }

    adjustAnswerWeightage = (e, index, qIndex, aIndex) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.area[index].questions[qIndex].possibleAnswer[aIndex].weightage = e.target.value;
        this.setState(tmpState);
    }

    deleteQuestion = (areaIndex, quesIndex) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.area[areaIndex].questions.splice(quesIndex, 1);
        this.setState(tmpState);
    }

    getOrganisations = () => {
        axios.get(constants.endpoint + constants.organisation)
            .then((response) => {
                this.setState({
                    ...this.state,
                    organisations: response.data.organisations
                })
            }).catch((error) => {

                //  handleAlert(error.response, {});
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="content content-fixed">
                    <div className="container col-12">
                        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                        <li className="breadcrumb-item">Dashboard</li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage Questions</li>
                                    </ol>
                                </nav>
                                <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Question Set Management</h4>
                            </div>
                            <div className="d-none d-md-block">
                                <button onClick={this.reset} className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="file" className="wd-10 mg-r-5"></i> Reset </button>
                            </div>
                        </div>

                        <div className="row">
                            <div id="errorPlaceholder" className="col-8">

                            </div>
                        </div>

                        <div className="row">

                            <div className="col-sm-8 col-lg-8 ">
                                <div className="card card-body shadow-none">
                                    <div className="marker marker-ribbon pos-absolute t-10 l-0">Review Category</div>
                                    <div className="row" style={{ marginTop: '32px' }}>
                                        <div className="col-4">
                                            <select disabled={this.state.startCreation} value={this.state.selectedRole} onChange={this.onRoleChange} className="custom-select">
                                                <option>Select Role</option>
                                                {
                                                    this.state.roles.map((role, index) => (
                                                        <option key={index} value={role.split(" ").join("-")}>{role}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                        <div className="col-4">
                                            <select disabled={this.state.startCreation} value={this.state.selectedLevel.split(" ").join("-")} onChange={this.onLevelChange} className="custom-select">
                                                <option>Select Level</option>
                                                {
                                                    this.state.selectedLevelGroup.map((level, index) => (
                                                        <option key={index} value={level.name.split(" ").join("-")}>{level.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>

                                        <div className="col-4">

                                            <div className="btn-group" role="group">
                                                <button disabled={this.state.selectedLevel.length === 0} onClick={this.onManageClick} type="button" className="btn btn-sm btn-primary">Fetch </button>
                                                <button disabled={!this.state.startCreation} onClick={this.updateQuestionSet} type="button" className="btn btn-sm btn-success">Save</button>
                                                <button disabled={!this.state.startCreation} onClick={this.onAddAreaClick} type="button" className="btn btn-sm btn-secondary">New Area</button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Copy to  copyToRole : '',
                                                copyToLevel : '',
                                                copyToLevels : [] */}
                                    <div className="row" style={{ marginTop: '32px', display: this.state.area.length != 0 ? '' : 'none' }}>
                                        <div className="col-3" style={{ display: localStorage.activityRole === "Owner" ? 'block' : "none" }}>
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Org</h6>
                                            <select value={this.state.copyToOrg} onChange={(e) => this.copyOrganisation(e, 'copyToOrg')} className="custom-select">
                                                <option>Select Organisation</option>
                                                {
                                                    this.state.organisations.map((org, oIndex) => (
                                                        <option key={oIndex} value={org.name}>{org.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>

                                        <div className="col-3">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Role</h6>
                                            <select value={this.state.copyToRole.split(" ").join("-")} onChange={(e) => this.copyRole(e, 'copyToRole')} className="custom-select">
                                                <option>Select Role</option>
                                                {
                                                    this.state.newRoles.map((role, index) => (
                                                        <option key={index} value={role.split(" ").join("-")}>{role}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Level</h6>
                                            <select value={this.state.copyToLevel.split(" ").join("-")} onChange={(e) => this.copyLevel(e, 'copyToLevel')} className="custom-select">
                                                <option>Select Level</option>
                                                {
                                                    this.state.copyToLevels.map((level, index) => (
                                                        <option key={index} value={level.name.split(" ").join("-")}>{level.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>

                                        <div className="col-3">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"> &nbsp;  </h6>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" onClick={this.copy} className="btn btn-sm btn-primary">COPY</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 col-lg-4">
                                <div className="card card-body shadow-none" style={{ minHeight: '110px' }}>
                                    <div className="marker marker-ribbon pos-absolute t-10 l-0">Creating For</div>
                                    <div className="row" style={{ marginTop: '32px' }}>
                                        <div className="col-12">
                                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{this.state.selectedRole + " " + this.state.selectedLevel || "NA"}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8 col-xl-8 mg-t-10">

                                {
                                    this.state.area.map((ar, index) => (
                                        <div className="card" key={index} style={{ marginBottom: '10px' }} id={ar.areaId}>
                                            <div className="card-body pd-20">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Area ||  {ar.areaId}</h6>
                                                        <input value={this.state.area[index].name} onChange={(e) => this.handleAreaInput(e, index)} type="text" className="form-control" placeholder="Enter area details" />
                                                    </div>

                                                    <div className="col-4" style={{ margin: 'auto 0px', position: 'relative', top: '6px' }}>
                                                        <button onClick={(e) => this.addQuestion(e, index)} type="button" className="btn btn-xs btn-primary">Add Question</button>
                                                    </div>

                                                    <div className="col-12" style={{ marginTop: '20px' }}>
                                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Questions</h6>
                                                    </div>


                                                    {
                                                        ar.questions.map((question, qindex) => (
                                                            <React.Fragment key={qindex}>
                                                                <div className="col-9 input-group mg-b-10">
                                                                    <div className="input-group-prepend">
                                                                        <code className="input-group-text" id="basic-addon1">{
                                                                            question.questionId.split("-")[(question.questionId.split("-").length - 1)]
                                                                        }</code>
                                                                    </div>
                                                                    <input
                                                                        value={this.state.area[index].questions[qindex].text}
                                                                        onChange={(e) => this.handleQuestionInput(e, index, qindex)}
                                                                        type="text" className="form-control" placeholder="Enter question" aria-describedby="basic-addon1"
                                                                    />
                                                                </div>
                                                                <div className="col-3 input-group mg-b-10">
                                                                    <select value={this.state.area[index].questions[qindex].answerSet} onChange={(e) => this.selectPossibleAnswer(e, index, qindex)} className="custom-select" >
                                                                        <option disabled selected value="">Select Answer Set</option>
                                                                        {
                                                                            Object.keys(this.state.sets).map((item, index) => (
                                                                                <option key={index} value={item}>{item}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>




                                                                <div className="row" style={{ width: '100%', marginBottom: '10px', padding: '0px 20px' }}>
                                                                    {
                                                                        question.possibleAnswer.map((possible, aindex) => (
                                                                            <div key={aindex} className="col-6 " style={{ marginTop: '10px' }}>
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <code>{possible.value}</code>
                                                                                    </div>
                                                                                    <div className="col-6" style={{ padding: '0px' }}>
                                                                                        <input value={this.state.area[index].questions[qindex].possibleAnswer[aindex].weightage} onChange={(e) => this.adjustAnswerWeightage(e, index, qindex, aindex)} className="form-control" type="text" value={possible.weightage} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>

                                                                <div className="col-12 text-right">
                                                                    <span onClick={() => this.deleteQuestion(index, qindex)} className="badge badge-danger cursor-pointer">Delete Question</span>
                                                                </div>

                                                                <hr className="mg-y-20" style={{ width: '100%' }} />
                                                            </React.Fragment>
                                                        ))
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>

                            <div className="col-lg-4 col-xl-4 mg-t-10">
                                <ul className="steps steps-vertical">
                                    {
                                        this.state.area.map((areaElem, index) => (
                                            <li key={index} className="step-item">
                                                <a className="step-link">
                                                    <span className="step-number">{areaElem.areaId.split("-")[2]}</span>
                                                    <span className="step-title">
                                                        <span>{areaElem.name}</span>
                                                    </span>
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default manageQuestions;