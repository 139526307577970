import React, { Component } from "react";
import Aside from "./../admin/aside";
import axios from "axios";
import * as constants from "./../../constants";

import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import { handleAlert } from "./../alertHandler/alertHandler";

class userList extends Component {
  state = {
    data: {
      organisations: [],
      roles: [],
      activeProjects: [],
      activeLevels: [],
      users: [],
      allUsers: [],
    },
    userInfo: {
      activeOrganisation: "",
      activeRole: "",
      activeProject: {},
      activeLevel: "",
      cvtid: "",
      name: "",
      doj: "",
      email: "",
      activityRole:"", 

    },
    updateProject :[],
    showEmployees: true,
    isEditEmployees: false,
    isMigrate:false,
    selectCvtId: "",
    selectedUser: {},
    selectedProject:{},
  };

  getUsers = () => {
    axios
      .get(
        constants.endpoint +
          constants.user +
          "?organisation=" +
          localStorage.selectedOrganisation+"&userListRequire=true"
      )
      .then((response) => {
        var usrs = [];
        for( var i =0;i< response.data.length;i++){
          if(response.data[i].cvtid !== localStorage.cvtid && response.data[i].activityRole !== "Owner"){
            usrs.push(response.data[i]);
          }
        }
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            users: usrs,
            allUsers : response.data,
          },
        });
      })
      .catch((error) => {
        handleAlert(error.response, {});
      });
  };

  componentDidMount() {
    
    this.getUsers();
    
  }

  showEmployeeBar = () => {
    this.setState({
      ...this.state,
      showEmployees: false,
    });
  };
  showEditBar = () => {
    this.setState({
      ...this.state,
      isEditEmployees: true,
      isMigrate:false,
      showEmployees: false,
      selectCvtId: "",
    });
  };

  showMigrateBar=()=>{
    this.setState({
      ...this.state,
      isMigrate:true,
      isEditEmployees: false,
      showEmployees: false,
      selectCvtId: "",
    });
  }

  hideEmployeeBar = () => {
    this.setState({
      ...this.state,
      showEmployees: true,
    });
    this.getUsers();
  };
  hideEditBar = () => {
    this.setState({
      ...this.state,
      showEmployees: true,
      isEditEmployees: false,
    });
    this.getUsers();
  };
  hideMigrateBar=()=>{
    this.setState({
      ...this.state,
      showEmployees: true,
      isMigrate: false,
    });
    this.getUsers();
  }

  render() {
    const data=this.state.data.users
    const columns = [
      {
        name: "Employee Id",
        selector: "cvtid",
        sortable: true,
        right: false,
        
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        right: false,
      },
      {
        name: "Organisation",
        selector: "organisation",
        sortable: true,
        right: true,
        right: false,
      },
      {
        name: "Project",
        cell: (row) => (
          
          <p>{row.project.map((proj, index) => (
              proj +","
          ))}</p>
          ),
      },
      {
        name: "Designation",
        selector: "role",
        sortable: true,
        right: false,
      },
      {
        name: "Level",
        selector: "level",
        sortable: true,
        right: false,
      },
      {
        name: "Manager",
        cell: (row) => (
          
        <p>{row.manager.map((usr, index) => (
            usr.name +","
        ))}</p>
        ),
      },

      {
        name: "Privilage",
        cell: (row) => (
          <div>
           <p>User</p>
          </div>
        ),
      },
    //   {
    //     name: "Reset Password",
    //     sortable: true,
    //     right: false,
    //     cell: (row) => (
    //       <button
    //         onClick={(e) => this.resetPassword(row.cvtid)}
    //         type="button"
    //         className="btn btn-xs btn-danger"
    //       >
    //         Reset
    //       </button>
    //     ),
    //   },
    {
        name: "Start Review",
        sortable: true,
        right: false,
        cell: (row) => (
          <button
            onClick={(e) => this.props.startReview}
            type="button"
            className="btn btn-xs btn-primary"
          >
            Start Review
          </button>
        ),
      },
      {
        name: "Raise Promotion",
        sortable: true,
        right: false,
        cell: (row) => (
          <button
            onClick={(e) => this.props.raisePromotion}
            type="button"
            className="btn btn-xs btn-primary"
          >
            Promote
          </button>
        ),
      },
      {
        name: "Send Notification",
        sortable: true,
        right: false,
        cell: (row) => (
          <button
            onClick={(e) => this.resetPassword(row.cvtid)}
            type="button"
            className="btn btn-xs btn-primary"
          >
            Notify
          </button>
        ),
      },
    ];
    const tableData = {
    columns,
    data,
  };
   

    return (
      <React.Fragment>
        <div className="content content-fixed" style={{marginTop:"-45px"}}>
          <div className="container col-12">
            <div
              className="row row-xs"
              style={{
                display:
                  !this.state.showEmployees && !this.state.isEditEmployees && !this.state.isMigrate
                    ? "block"
                    : "none",
              }}
            >
              <div className="col-12">
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Add Employee
                    </h6>
                  </div>
                  <div className="card-body pd-20">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputcvtid">Employee ID</label>
                        <input onKeyPress={(e) => this.restrictSpecialCharacters(e)}
                          value={this.state.userInfo.cvtid}
                          onChange={(e) => this.handleInput(e, "cvtid")}
                          type="text"
                          className="form-control"
                          id="inputcvtid"
                          placeholder="Employee ID"
                          required
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputcvtid">Date of joining</label>
                        <input
                          value={this.state.userInfo.doj}
                          onChange={(e) => this.handleInput(e, "doj")}
                          type="date"
                          className="form-control"
                          id="inputcvtid"
                          placeholder="Date of joining"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputname">Name</label>
                        <input onKeyPress={(e) => this.validateName(e)}
                          value={this.state.userInfo.name}
                          onChange={(e) => this.handleInput(e, "name")}
                          type="text"
                          className="form-control"
                          id="inputname"
                          placeholder="Name"
                          required
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="inputemail">Email</label>
                        <input
                          pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$"
                          value={this.state.userInfo.email}
                          onChange={(e) => this.handleInput(e, "email")}
                          type="text"
                          className="form-control"
                          id="inputemail"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="inputname">Privilage</label>
                        <select
                          value={this.state.userInfo.activityRole}
                          onChange={(e) => this.handleInput(e, "activityRole")}
                          className="custom-select"
                        >
                          <option value="">Select Privilage</option>
                          <option value="User">User</option>
                          {localStorage.activityRole == "Owner" && (
                            <option value="Owner">Owner</option>
                          )}
                          <option value="Admin">Admin</option>
                        </select>
                      </div>
                    
                      <div className="form-group col-md-4">
                        <label>Organisation</label>
                        <select
                          value={this.state.userInfo.activeOrganisation}
                          onChange={(e) =>
                            this.handleInput(e, "activeOrganisation") 
                          }
                          className="custom-select"
                        >
                          <option>Select Organisation</option>
                          {this.state.data.organisations.map((org, oIndex) => (
                            <option key={oIndex} value={org.name}>
                              {org.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                    </div>
                {
                  (this.state.userInfo.activityRole === "User" || this.state.userInfo.activityRole === "Manager") && (
                    <div>

                    <div className="form-row">

                    <div className="form-group col-md-8">
                        <label>Project</label>
                        <select
                          value={this.state.userInfo.activeProject}
                          onChange={(e) => this.handleInput(e, "activeProject")}
                          className="custom-select"
                          multiple
                        >
                          <option value="" disabled selected>Select Project</option>
                          {this.state.data.activeProjects.map(
                            (project, oIndex) => (
                              <option key={oIndex} value={project.name}>
                                {project.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                    </div>
                    <div className="form-row">
                    
                    
                      <div className="form-group col-md-4">
                        <label>Designation</label>
                        <select
                          value={this.state.userInfo.activeRole}
                          onChange={(e) => this.handleInput(e, "activeRole")}
                          className="custom-select"
                        >
                          <option>Select Designation</option>
                          {this.state.data.roles.map((role, oIndex) => (
                            <option key={oIndex} value={role.name}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label>Level</label>
                        <select
                          value={this.state.userInfo.activeLevel}
                          onChange={(e) => this.handleInput(e, "activeLevel")}
                          className="custom-select"
                        >
                          <option>Select Role</option>
                          {this.state.data.activeLevels.map((level, oIndex) => (
                            <option key={oIndex} value={level.name}>
                              {level.name}
                            </option>
                          ))}
                        </select>
                      </div>

                    </div>

                  
                    </div>
                  )}
                  
                    <button
                      onClick={this.addEmployee}
                      type="button"
                      className="btn btn-primary"
                    >
                      Add Employee
                    </button>
                    <button
                      onClick={this.hideEmployeeBar}
                      type="button"
                      className="btn btn-primary"
                      style={{ marginLeft: "20px" }}
                    >
                      View Employees
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row row-xs"
              style={{
                display:
                  !this.state.showEmployees && this.state.isEditEmployees && !this.state.isMigrate
                    ? "block"
                    : "none",
              }}
            >
              <div className="col-12">
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Edit Employee
                    </h6>
                  </div>
                  <div className="card-body pd-20">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label>Select Employee</label>
                        <select
                          value={this.state.selectCvtId}
                          onChange={this.onEmployeeSelect}
                          className="custom-select"
                        >
                          <option>Select Employee</option>
                          {this.state.data.users.map((org, oIndex) => (
                            <option key={oIndex} value={org.cvtid}>
                              {org.name} : {org.cvtid}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          this.state.selectCvtId.length === 0
                            ? "none"
                            : "block",
                      }}
                    >
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputcvtid">Employee ID</label>
                          <input
                            value={this.state.userInfo.cvtid}
                            onChange={(e) => this.handleInput(e, "cvtid")}
                            type="text"
                            className="form-control"
                            id="inputcvtid"
                            placeholder={this.state.selectedUser.cvtid}
                          />
                        </div>

                        <div className="form-group col-md-4">
                        <label htmlFor="inputname">Name</label>
                        <input
                          value={this.state.userInfo.name}
                          onChange={(e) => this.handleInput(e, "name")}
                          type="text"
                          className="form-control"
                          id="inputname"
                          placeholder={this.state.selectedUser.name}
                          required
                        />
                      </div>

                      </div>

                      <div className="form-row">
                      <div className="form-group col-md-4">
                          <label htmlFor="inputemail">Email</label>
                          <input
                            value={this.state.userInfo.email}
                            onChange={(e) => this.handleInput(e, "email")}
                            type="text"
                            className="form-control"
                            id="inputemail"
                            placeholder={this.state.selectedUser.email}
                          />
                        </div>

                        <div className="form-group col-md-4">
                          
                          <label>Project</label>
                          
                          <select
                            value={this.state.selectedProject}
                            onChange={(e) =>
                              this.updateProjects(e)
                            }
                            className="custom-select"
                            multiple
                          >
                            <option value="" disabled selected>Select Project</option>
                            {this.state.updateProject.map(
                              (project, oIndex) => (
                                <option key={oIndex} value={project.name}>
                                  {project.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                     
                    </div>
                    <div className="card-body pd-20">
                      <div className="form-row">
                        <button
                          onClick={this.updateEmployee}
                          type="button"
                          className="btn btn-primary"
                        >
                          Update Employee
                        </button>
                        <button
                          onClick={this.hideEditBar}
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "20px" }}
                        >
                          View Employees
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{
                display:
                  this.state.showEmployees && !this.state.isEditEmployees && !this.state.isMigrate
                    ? "block"
                    : "none",
              }}
            >
              <div className="col-lg-12 col-xl-12 mg-t-10">
                <div className="card">
                  <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                      Employee
                    </h6>
                  </div>

                  <div className="card-body p-0 ml-3 mt-2 mr-3">
                  <DataTableExtensions
                    {...tableData}
                    print={false}
                    export={false}
                    filterPlaceholder="Employee ID/Name"
                  >
                    <DataTable
                       noHeader
                       defaultSortField="id"
                       defaultSortAsc={false}
                       pagination
                       highlightOnHover
                       
                    />
                  </DataTableExtensions>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default userList;
