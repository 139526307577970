import React, { Component } from 'react';
import axios from 'axios';
import { endpoint } from '../../../constants';

class reviewstats extends Component {

    state = {
      performance : {},
      colors : {
        outstanding : "primary",
        exceeds : "success",
        achieves : "warning",
        improvement : "pink"
      }
    }
    roundOff = (x) => {
      console.log(x);
      if (x % 5 == 0) {
          return parseInt(Math.floor(x / 5)) * 5;
      } else {
          return (parseInt(Math.floor(x / 5)) * 5) + 5;
      }
    }
    getCategoryClass = (type) => {
      let classType = null;
       Object.keys(this.state.colors).map((color) => {
          if(type.toLowerCase().indexOf(color) != -1){
            classType = this.state.colors[color];
          }
       });
       return classType;
    }

    getCompanyPerformance = () => {
        axios.get(`${endpoint}/manager/company/performance`).then((response) => {
            this.setState({performance : response.data },() => {
              console.log(this.state);
            })
        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        });
    }

    componentDidMount() {
      this.getCompanyPerformance();
    }

    render() {
        return (
              Object.keys(this.state.performance) && (<div className="card">
                  <div className="card-header d-flex align-items-start justify-content-between">
                    <h6 className="mg-b-0 tx-semibold"><i className="fas fa-chart-line fa-lg"></i> Company Performance</h6>
                  </div>
                  <div className="card-body pd-y-10">
                    <div className="d-flex align-items-baseline tx-rubik">
                      <h1 className="tx-40 lh-1 tx-normal tx-spacing--2 mg-b-5 mg-r-5">{this.state.performance.finalRating}</h1>
                      <p className="tx-14 tx-color-03 mg-b-0"><span className="tx-medium tx-success">{this.state.performance.finalComment}</span></p>
                    </div>
                    <h6 className="tx-uppercase tx-spacing-1 tx-semibold tx-10 tx-color-02 mg-b-15">Company Performance Score</h6>

                    <div className="progress bg-transparent op-7 ht-10 mg-b-15">
                      {
                        this.state.performance.ratings &&  this.state.performance.ratings.map((rating) => (
                          <div className={"progress-bar bg-"+this.getCategoryClass(rating._id)+" wd-"+this.roundOff((rating.count/this.state.performance.totalEmps)*100)+"p"} role="progressbar"></div>
                        ))
                      }
                    </div>

                    <table className="table-dashboard-two">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-left"><span>Performance</span></th>
                          <th className="text-right"><span>Avg.</span></th>
                          <th className="text-right"><span>Count</span></th>
                          <th className="text-right"><span>%</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.performance.ratings && this.state.performance.ratings.map((rating) => (
                            <tr>
                              <td><div className={ "wd-12 ht-12 rounded-circle bd bd-3 bd-"+ this.getCategoryClass(rating._id) }></div></td>
                              <td className="tx-medium">{rating._id}</td>
                              <td className="text-right">{parseFloat(rating.avgRating).toFixed(2)}</td>
                              <td className="text-right">{rating.count}</td>
                              <td className="text-right">{((rating.count/this.state.performance.totalEmps)*100).toFixed(2)}%</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>)
        );
    }
}

export default reviewstats;