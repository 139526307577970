import React, { Component } from "react";
import { throws } from "assert";
import axios from "axios";
import * as constants from "./../../../constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorMonitor } from "stream";

class answerSets extends Component {
  state = {
    sets: {},
    setName: "",
    isSetCreated: false,
    setSelected: {},
    setNameSelected: "",
    orgSelected: "",
    setName1: "",
  };

  handleSetName = (e) => {
    this.setState({
      ...this.state,
      setName: e.target.value,
      setName1: e.target.value,
    });
  };

  saveSet = () => {
    //saves set locally.
    

    let newSetName = this.state.setName1;
    let count = 0;
    for (var i = 0; i < this.state.sets[newSetName].length; i++) {
      if (
        this.state.sets[newSetName][i].value === "" ||
        this.state.sets[newSetName][i].weightage === ""
      ) {
        toast.error("Please provide all inputs.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        count++;
        break;
      }
    }

    if (count === 0) {
      this.setState(
        {
          ...this.state,
          setName: "",
          isSetCreated: false,
        },
        () => {
          this.updateSet();
        }
      );
    }
  };

  updateSet = (setId) => {
    axios
      .post(
        constants.endpoint +
          constants.answerSet +
          "?organisation=" +
          localStorage.selectedOrganisation,
        {
          sets: this.state.sets,
          setName: setId ? setId : this.state.setName1,
        }
      )
      .then((response) => {
        if (response.status == 201) {
          this.getSet();
          toast.success("Answer set created successfully.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (response.status == 202) {
          this.getSet();
          toast.success("Answer set updated successfully.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.getSet();
          toast.error("This answer set is already in use.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          this.getSet();
          toast.error("Something went wrong ! Please try again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  getSet = () => {
    axios
      .get(
        constants.endpoint +
          constants.answerSet +
          "?organisation=" +
          localStorage.selectedOrganisation
      )
      .then((response) => {
        if (response.data.sets != null) {
          this.setState({
            ...this.state,
            sets: response.data.sets.sets || {},
          });
        }
      })
      .catch((error) => {
        this.showError("danger", "Something unexpected happened !");
      });
  };

  setValueChange = (e, index, field) => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    if (field == "weightage") {
      tmpState.sets[this.state.setName][index][field] = parseFloat(
        e.target.value
      );
    } else {
      tmpState.sets[this.state.setName][index][field] = e.target.value;
    }
    this.setState(tmpState);
  };

  onSetAdd = () => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState.sets[this.state.setName] = [];

    let tmpValues = {
      value: "",
      weightage: "",
    };
    Array(5)
      .join()
      .split(",")
      .map(() => {
        tmpState.sets[this.state.setName].push(tmpValues);
      });
    tmpState.isSetCreated = true;
    this.setState(tmpState);
  };

  editSet = (e, setName) => {
    let tmpState = JSON.parse(JSON.stringify(this.state));
    tmpState.setName = setName;
    tmpState.isSetCreated = true;
    this.setState(tmpState);
    this.setState({ setName1: setName });
  };

  deleteSet = (e, setId) => {
    this.setState({ setName1: setId });
    let tmpState = JSON.parse(JSON.stringify(this.state));
    delete tmpState.sets[setId];
    this.setState(tmpState, () => {
      this.updateSet(setId);
    });
  };

  componentDidMount() {
    this.getSet();
  }

  render() {
    return (
      <React.Fragment>
        <div className="content content-fixed">
          <div className="container col-12">
            <div className="d-sm-flex align-items-center justify-content-between">
              <div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Manage answer sets
                    </li>
                  </ol>
                </nav>
                <h4 class="mg-b-0 tx-uppercase tx-13 tx-spacing-2 tx-color-02">Answer Set Management</h4>
              </div>
            </div>

            <div className="row">
              <div
                className="col-12"
                style={{ display: this.state.isSetCreated ? "" : "none" }}
              >
                <div className="card card-body">
                  <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                    Add New Answer Set
                  </h6>
                  <div className="d-flex d-lg-block d-xl-flex align-items-end">
                    <div className="row">
                      {this.state.isSetCreated &&
                        this.state.sets[this.state.setName].map(
                          (option, index) => (
                            <React.Fragment key={index}>
                              <div
                                className="col-8"
                                style={{ marginTop: "10px" }}
                              >
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                  Option {index + 1}
                                </h6>
                                <input
                                  value={
                                    this.state.sets[this.state.setName][index]
                                      .value
                                  }
                                  onChange={(e) =>
                                    this.setValueChange(e, index, "value")
                                  }
                                  type="text"
                                  className="form-control"
                                  placeholder="Value"
                                />
                              </div>
                              <div
                                className="col-4"
                                style={{ marginTop: "12px" }}
                              >
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                  Weightage
                                </h6>
                                <input
                                  value={
                                    this.state.sets[this.state.setName][index]
                                      .weightage
                                  }
                                  onChange={(e) =>
                                    this.setValueChange(e, index, "weightage")
                                  }
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  placeholder="Weightage"
                                />
                              </div>
                            </React.Fragment>
                          )
                        )}
                      <div className="col-4" style={{ marginTop: "10px" }}>
                        <button
                          onClick={this.saveSet}
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          Save Set
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="card card-body shadow-none">
                      <div className="marker marker-ribbon pos-absolute t-10 l-0">
                        Add Answer Sets
                      </div>
                      <div className="row" style={{ marginTop: "32px" }}>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <input
                                disabled={this.state.isSetCreated}
                                value={this.state.setName}
                                onChange={this.handleSetName}
                                type="text"
                                className="form-control"
                                placeholder="Enter set name"
                              />
                            </div>
                            <div className="col-6">
                              <button
                                disabled={
                                  this.state.isSetCreated ||
                                  this.state.setName.length == 0
                                }
                                onClick={this.onSetAdd}
                                type="button"
                                className="btn btn-sm btn-primary"
                              >
                                Add Set
                              </button>
                              {/* <button style={{ marginLeft: '20px' }} disabled={this.state.isSetCreated} onClick={this.updateSet} type="button" className="btn btn-sm btn-primary">Save Set</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div id="errorPlaceholder" className="col-12"></div>
                  </div>

                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <div className="card card-body">
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <div className="row" style={{ width: "100%" }}>
                          {Object.keys(this.state.sets).map((set, index) => (
                            <div
                              key={index}
                              className="col-3"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="card card-body shadow-none">
                                <div className="row">
                                  <div className="col-8">
                                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                      {set}
                                    </h6>
                                  </div>
                                </div>

                                <hr className="mg-y-10" />
                                {this.state.sets[set].map((option, index) => (
                                  <React.Fragment key={index}>
                                    <div className="row">
                                      <div className="col-8">
                                        <h6
                                          style={{ whiteSpace: "nowrap" }}
                                          className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8"
                                        >
                                          {option.value}
                                        </h6>
                                      </div>
                                      <div className="col-4">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                          {option.weightage}
                                        </h6>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ))}
                                <hr className="mg-y-10" />
                                <div className="row">
                                  <div className="offset-4 col-4">
                                    <span
                                      onClick={(e) => this.editSet(e, set)}
                                      className="badge badge-success cursor-pointer"
                                    >
                                      Edit Set
                                    </span>
                                  </div>
                                  <div className="col-4">
                                    <span
                                      onClick={(e) => this.deleteSet(e, set)}
                                      style={{ cursor: "pointer" }}
                                      className="badge badge-danger"
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }
}

export default answerSets;
