import React, { Component } from 'react';
import axios from 'axios';
import { endpoint } from '../../../constants';

class roles extends Component {

    state = {
        roles : [],
        data : [],
        totalEmployees : 0,
        display : [],
        selected : 'ALL ROLES'
    }

    roundOf = (number) => {
        return (Math.floor(number / 5)) * 5;
    }

    changeRole = (e) => {
        let display = [];
        let totalEmployees = 0;

        if(e === "ALL ROLES"){
            this.state.data.map((role) => {
                display.push({
                    name : role.name,
                    count : role.count
                })
                totalEmployees += role.count;
            })
        }else{
            this.state.data.map((role) => {
                

                if(role.name === e){
                    role.levels.map((levs) => {
                        display.push({
                            name : e+" "+levs.name,
                            count : levs.count
                        });
                        totalEmployees += levs.count;
                    });
                }
            })
        }
        this.setState({
            ...this.state,
            display : display,
            selected : e,
            totalEmployees : totalEmployees
        })
    }
    
    getRolesAndLevels = () => {
        axios.get(`${endpoint}/manager/stats/employess/roles`).then((response) => {
            let roles = ["ALL ROLES"];
            let totalEmployees = 0;
            let items = [];
            response.data.map((data) => {
                roles.push(data.name);
                totalEmployees += data.count;
                items.push({
                    name : data.name,
                    count : data.count
                })
            });

            this.setState({
                ...this.state,
                roles : roles,
                totalEmployees : totalEmployees,
                data : response.data,
                display : items
            });
        }).catch((error) => {
          this.showError("danger", "Something unexpected happened !");
        });
    }

    componentDidMount() {
        this.getRolesAndLevels();
      }

    render() {
        return (
            
                            <div className="card">
                            <div className="card-header d-flex align-items-start justify-content-between">
                                <h6 className=" mg-b-0 tx-semibold"><i className="fas fa-users fa-lg"></i>  Employee Statistics</h6>
                                <div className="dropdown">
                                    <span className=" tx-10 tx-uppercase tx-medium" type="button" id="role-level-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-user-cog"></i> &nbsp; {this.state.selected} &nbsp; <i className="fas fa-chevron-down"></i>
                                    </span>
                                    <div className="dropdown-menu" aria-labelledby="role-level-dropdown">
                                        {
                                            this.state.roles.map((role) => (
                                                <span style={{cursor:'pointer'}} onClick={() => this.changeRole(role)} className="dropdown-item  tx-10 tx-uppercase tx-medium" >{role}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pd-y-15 pd-x-10" style={{transition: 'height 0.25s linear'}}>
                                <div className="table-responsive">
                                <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                                    <thead>
                                    <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                        <th></th>
                                        <th className="text-right">Count</th>
                                        <th className="text-right">Percentage (%)</th>
                                        <th className="text-right"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.display.map((role) => (
                                                <tr key={role.name}>
                                                    <td className="tx-12 tx-uppercase tx-medium">{role.name}</td>
                                                    <td className="align-middle text-right tx-12 tx-uppercase tx-medium"><span className="tx-medium">{role.count}</span></td>
                                                    <td className="align-middle text-right ">
                                                    <div className="wd-150 d-inline-block">
                                                        <div className="progress ht-4 mg-b-0">
                                                            <div className={"progress-bar bg-teal wd-"+(this.roundOf((role.count/this.state.totalEmployees).toFixed(2)*100))+"p"} role="progressbar" aria-valuenow={(role.count/this.state.totalEmployees).toFixed(2)*100} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    </td>
                                                    <td className="align-middle text-right tx-12 tx-uppercase tx-medium"><span className="tx-medium">{((role.count/this.state.totalEmployees).toFixed(2)*100).toFixed(2)} %</span></td>
                                                </tr>
                                            ))
                                        }
                                    
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                 
        );
    }
}

export default roles;