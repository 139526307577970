import React, { Component } from 'react';

import axios from 'axios';
import * as constants from './../../constants';

class redirect extends Component {
    state = {
      loginFailed: false
    }
    
    componentWillMount() {
       if(navigator.userAgent.indexOf("flutter") == -1){
          this.login();
       }
    }

    login = () => {
        var url = new URL(window.location.href);
        let code = url.searchParams.get("code");

        axios.post(constants.endpoint + constants.ssoLoginToken, {
          code : code
        })
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("cvtid", response.data.user.cvtid);
              localStorage.setItem("role", response.data.user.role);
              localStorage.setItem("activityRole", response.data.user.activityRole);
              localStorage.setItem("name", response.data.user.name);
              localStorage.setItem("organisation", response.data.user.organisation);
    
              if (response.data.user.activityRole == "User" || response.data.user.activityRole == "Manager") {
                window.location.href = "/selfReview";
              } else {
                window.location.href = "/admin";
              }
            }else{
              this.setState({
                loginFailed : true
              });
            }
          }).catch((error) => {
              this.setState({
                loginFailed : true
              });
          });
      }
      
    render() {
        return (
            <div>
                
                {
                  !this.state.loginFailed && (
                    <React.Fragment>
                      <div className="loader">
                        <span>Signing In...</span>
                      </div>
                      <div className="text-center">
                          <span className="font-weight-bold">Singing In ..</span>
                      </div>
                    </React.Fragment>
                  )
                }

                {
                  this.state.loginFailed && (
                   
                            <div className="text-center mt-5">
                                <span className="font-weight-bold">Login Failed. Either account dosen't exists or not correctly mapped.</span>
                            </div>
                         
                  )
                }
            </div>
        );
    }
}

export default redirect;