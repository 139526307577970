import React, { Component } from "react";
import Header from "./../header/header";
import axios from "axios";
import * as constants from "./../../constants";
import "../../assests/css/notification.css";
class viewNotifications extends Component {
  state = {
    user: {
      role: "",
      manager: {
        name: "",
      },
    },
    clickedButton: "",
    notification: [
      {
        _id: "",
        message: "",
        senderName: "",
      },
    ],
    activeUnred: "",
    activeRead: "",
  };

  componentWillMount() {
    if (typeof localStorage.token === "undefined") {
      window.location.href = "/?message=Session+Expired";
    }
    this.getNotification("unread");
  }

  getNotification = (status) => {
    
    let url = constants.endpoint + constants.getNotification;
    if (status === "") url = constants.endpoint + constants.getNotification;
    else
      url =
        constants.endpoint + constants.getNotification + "?status=" + status;
    axios
      .get(url)
      .then((response) => {
        
        this.setState({
          ...this.state,
          clickedButton: status,
          notification: response.data.notification,
        });
      })

      .catch((error) => {
        //this.showError("danger", "Something unexpected happened !");
      })
      .then(() => {
        //this.getCurrentReview();
      });
  };

  updateNotificationStatus = (id) => {
    
    if (this.state.clickedButton === "unread") {
      let url =
        constants.endpoint +
        constants.updateReadNotificationStatus +
        "?id=" +
        id;
      axios
        .post(url)
        .then((response) => {
          
          console.log("Notification status updated");
        })

        .catch((error) => {
          //this.showError("danger", "Something unexpected happened !");
        })
        .then(() => {
          //this.getCurrentReview();
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="content content-fixed">
          <div className="container">
            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
              <div>
                <nav>
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item"> Dashboard </li>
                    <li className="breadcrumb-item active">
                      
                      My Previous Reviews
                    </li>
                  </ol>
                  <h4 className="mg-b-0 tx-spacing--1">
                    
                    Welcome {localStorage.name}
                  </h4>
                </nav>
              </div>
              <div className="d-none d-md-block">
                <a href="/selfReview" className="nav-link" target="_self">
                  
                  Back to self review
                </a>
              </div>
            </div>
            <div className="row">
              <div id="errorPlaceholder" className="col-12"></div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 mg-t-10">
                <div className="card card-body shadow-none">
                  <div className="marker marker-ribbon pos-absolute t-10 l-0">
                    
                    Notifications
                  </div>
                  <div className="row" style={{ marginTop: "40px" }}>
                    <div className="col-md-12">
                      <button
                        type="button"
                        onClick={() => this.getNotification("unread")}
                        className={
                          this.state.clickedButton === "unread"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-secondary btn-sm"
                        }
                      >
                        
                        Unread Notification
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => this.getNotification("read")}
                        className={
                          this.state.clickedButton === "read"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-secondary btn-sm"
                        }
                      >
                        
                        Read Notification
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "40px",
                      display:
                        this.state.notification.length > 0 ? "none" : "block",
                    }}
                  >
                    <h4> No notification found. </h4>
                  </div>
                  <div style={{ marginTop: "40px" }}>
                    
                    {this.state.notification.map((data, index) => (
                      <div className="row" style={{marginTop: "-15px", display: this.state.notification.length > 0 ? "block": "none"}}>
                        <div className="col-md-12">
                          {/* {data.senderName} sends a message.<br/>
                                                                                                                                                                                    {data.message} */}
                          <div className={"panel-group"}>
                            <div className="panel panel-default">
                              <div className="panel-heading">
                                <h4 className="panel-title">
                                  <a
                                  data-toggle="collapse"
                                    onClick={() =>
                                      this.updateNotificationStatus(data._id)
                                    }
                                    href={`#collapse${index}`}
                                  >
                                    
                                    {data.senderName} sends a message.
                                  </a>
                                </h4>
                              </div>
                              <div
                                id={`collapse${index}`}
                                className="panel-collapse collapse"
                              >
                                <div className="panel-body">
                                  
                                  {data.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default viewNotifications;
