import React, { Component, useRef } from 'react';
import Header from './../header/header';
import Footer from './../footer/footer';
import axios from 'axios';
import * as constants from './../../constants';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';


class dashboardYearAndQuarterBind extends React.Component {


    constructor(props) {
        super(props);
        };

    state = {
        yearQuarter: [],
        quarterList: [],
        quarterBindList: [],
        selectedYear: '',
        selectedQuarter: '',
        viewRatinngValue:false
    }


    componentWillMount() {
        if (typeof localStorage.token === "undefined") {
            window.location.href = "/?message=Session+Expired";
        }

        if (localStorage.activityRole === "User") {
            window.location.href = "/?message=Unauthorized+Access+Or+Session+Expired";
        }
        this.bindYearAndQuarter();
    }

    bindYearAndQuarter = () => {
        axios.get(constants.endpoint + constants.yearAndQuarter)
            .then((response) => {


                console.log(response.data);
                let quarters = [];
                let yearQuarterData = [];
                let yearAndQuarterData = response.data.yearAndQuarter;
                for (var i = 0; i < yearAndQuarterData.length; i++) {
                    yearQuarterData[i] = yearAndQuarterData[i];
                    quarters[i] = yearAndQuarterData[i].quarter;
                }

                this.setState({
                    ...this.state,
                    yearQuarter: yearQuarterData,
                    quarterList: quarters
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    bindQuarter = (e) => {
        
        document.getElementById("ddlQuarter").selectedIndex = "0";
        var index = e.target.selectedIndex;
        let quarter = [];
        if (index > 0) {
            index = index - 1;
            let quartersList = this.state.quarterList[index];
            var i;
            for (i = 0; i < quartersList.length; i++) {
                quarter[i] = quartersList[i];
            }
            //quarter[i]="All Quarters";
        }
        this.setState({
            ...this.state,
            quarterBindList: quarter,
            selectedYear: e.target.value,
            viewRatinngValue:false
        })
    }

    saveQuarter = (e) => {

        let value = "";
        if (e.target.value != "NA")
        {
            value = e.target.value;
            this.setState({
                ...this.state,
                viewRatinngValue:true,
                selectedQuarter: value
            })
        }
        else{
            this.setState({
                ...this.state,
                viewRatinngValue:false,
            })
        }
    }

    getCompleteRatings = () => {
        

        let year = this.state.selectedYear;
        let quarter = this.state.selectedQuarter;

        this.props.parentMethod(year,quarter);

        // this.getTeamReatingDistribution(year, quarter);
        // this.getTeamReatingDistributionByArea(year, quarter);
        // this.getTeamReatingDistributionByRole(year, quarter);
    }
    


    render() {
        return (

            <React.Fragment>
               


                <div className="row">
                    <div className="col-md-12" style={{ display: 'flex', padding: '15px 30px', margin: 'inherit', marginTop: '30px' }}>
                        <select style={{ width: '400px' }} onChange={this.bindQuarter}>
                            <option value="NA" selected>Select Year</option>
                            {
                                this.state.yearQuarter.map((data, index) => (
                                    <option key={data._id} value={data._id}>{data._id}</option>
                                ))
                            }
                        </select>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                            <select style={{ width: '400px' }} id="ddlQuarter" onChange={this.saveQuarter}>
                            <option value="NA" selected>Select Quarter</option>
                            {
                                
                                this.state.quarterBindList.map((quarter, index) => (
                                    <option key={index} value={quarter}>{quarter}</option>
                                ))
                            }
                        </select>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button type="button" disabled={!this.state.viewRatinngValue} className="btn btn-primary btn-xs" onClick={this.getCompleteRatings}>View Rating Details</button>
                    </div>

                </div>



            </React.Fragment>

        );
    }
}




export default dashboardYearAndQuarterBind;

